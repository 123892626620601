import { auth, db } from "../firebase"
import { getAuth, signInWithEmailAndPassword, signInWithPopup, signInWithCustomToken, signInWithCredential, GoogleAuthProvider, getIdToken, OAuthProvider } from "firebase/auth";
import { getFirestore, collection, getDocs, doc, setDoc, updateDoc, deleteDoc, Firestore, Timestamp } from 'firebase/firestore/lite';
import request from "../request";
import { toast } from "react-toastify";
import { CouponInput } from "src/pages/mypage/ApplyCoupon";


// adapty check ???

// coupon 적용
const applyCoupon = async (coupon_data: CouponInput) => {

  try {
    const result = await request.patch(`/coupons/${coupon_data?.coupon}`, {});
    // toast.success("쿠폰 적용이 완료되었습니다. 앱에서 확인하기");
    // TODO : 쿠폰 적용 후 앱으로 바로 가는 페이지로 이동 필요
    return { result: true };
  } catch (error: any) {
    const code = error.code;

    if (code === 401) {
      toast.error("로그인이 필요합니다.");
      return { result: false, url: '/login' };
    }


    toast.error("쿠폰을 사용할 수 없어요! 놀잇 카카오톡 채널로 문의해주세요.");
    return { result: false, url: '/mypage/default' };
  }
  
}



export {
  applyCoupon
};
