import { motion }  from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";

import styled from "styled-components";

import useAnswerStore, { mbti_types } from "../../shared/zustand/mbti";

const Image = styled.img`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: auto;
  object-fit: contain;
`;

const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

const upVariants = {
  hidden: { opacity: 0, y: 10 },
  visible,
};

const downVariants = {
  hidden: { opacity: 0, y: -10 },
  visible,
};

const cardVariants = {
  hidden: { opacity: 0, y: 100, rotateY: 300 },
  visible: {
    opacity: 1,
    y: 0,
    rotateY: 0,
    transition: {
      rotateY: {
        duration: 0.3,
      },
      y: {
        type: "spring",
        damping: 3,
        stiffness: 50,
        restDelta: 0.01,
        duration: 0.3,
      },
    },
  },
};
const AllTypes = () => {
  const navigate = useNavigate();
  const is_mobile = window.innerWidth < 400;

  return (
    <motion.article
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      style={{
        maxWidth: "400px",
        width: "100%",
        backgroundColor: "#FFDD35",
        minHeight: "100vh",
        margin: "0 auto",
        overflowY: "auto",
        boxShadow: is_mobile ? "none" : "0 0 10px rgba(0,0,0,0.2)",
      }}
    >
      <div>
        <motion.div style={{}}>
          <motion.p
            className=""
            variants={upVariants}
            style={{
              fontSize: "0.9rem",
              fontWeight: "bold",
              width: "100%",
              color: "#2D2D2D",
              marginTop: "2.4rem",
            }}
          >
            BEHAVIOR TYPE INDICATOR
          </motion.p>
          <motion.h1 style={{ color: "#08287D" }}>성향분류표</motion.h1>

          <motion.div
            variants={cardVariants}
            initial="hidden"
            whileInView={"visible"}
            style={{
              width: "90%",
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "1rem",
              margin: "1rem auto",
            }}
          >
            {mbti_types.map((item, idx) => {
              if (!item.visible) {
                return null;
              }
              return (
                <motion.div
                  key={`type_idx_${idx}`}
                  style={{
                    borderRadius: "1rem",
                    backgroundColor: "#FFF",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.4rem",
                    alignItems: "center",
                    padding: "1rem 0.5rem",
                    justifyContent: "space-between",
                  }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  onClick={() => {
                    navigate(`/mbti/${item.type}`);
                  }}
                >
                  <motion.img
                    src={`/imgs/mbti/${item.type}.png`}
                    variants={downVariants}
                    style={{
                      width: "70%",
                      maxHeight: "130px",
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <motion.p
                      style={{
                        fontSize: "0.9rem",
                      }}
                    >
                      {item.sub_title}
                    </motion.p>
                    <motion.p
                      style={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      {item.title}
                    </motion.p>
                  </div>
                </motion.div>
              );
            })}
          </motion.div>
        </motion.div>
      </div>
    </motion.article>
  );
};

export default AllTypes;
