import React, { ReactElement, useEffect } from "react";
import styled from "styled-components";
import img_02_04 from "@assets/image/img_02_04@2x.png";
import ReviewCard from "@components/ReviewCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MReviewCardBottomFixed from "@components/MReviewCardBottomFixed";
import MReviewCard from "@components/MReviewCard";

import { motion } from "framer-motion";
import { Colors } from "../../enums/Colors";
import img_05_01 from "@assets/images/img_05_01.png";
import img_05_02 from "@assets/images/img_05_02.png";
import img_05_03 from "@assets/images/img_05_03.png";
import btn_05_open from "@assets/images/btn_05_open.svg";
import btn_05_close from "@assets/images/btn_05_close.svg";

const Styled = {
  wrap: styled.div`
    box-sizing: border-box;
    padding: 3rem 4rem;
    background-color: #050505;
    /* height: calc(100vh - 8rem); */
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: hidden;
    position: relative;
  `,
  title: styled.div`
    color: #fff;
    font-size: 2.6rem;
    line-height: 3.8rem;
    font-weight: 800;
    padding: 2.6rem 0rem;
    text-align: center;
    position: relative;
  `,
  sub: styled.div`
    color: #fff;
    font-size: 1.6rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 2.6rem;
    text-align: center;
    padding: 0 2rem;
  `,
  screen: styled.div`
    background: url(${img_02_04}) no-repeat center center/contain;
    width: 65%;
    height: 65%;
    z-index: 1;
    position: absolute;
    bottom: -4.5rem;
  `,
  reviewWrapper: styled.div`
    position: absolute;
    bottom: 2.4rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 91;
    width: 31.2rem;
    background-color: white;
    box-shadow: 0 2px 6px #0000001a;
    border-radius: 20px;

    .slick-dots {
      bottom: 4.4rem;
      & li {
        margin: 0;
      }
    }
  `,
  Contents: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 60%;
    height: 100%;
    margin: 2rem auto;
  `,
  Content: styled.div`
    background-size: contain;
    width: calc(30vw);
    padding-top: calc(30vw * 1.0435);
    position: relative;
  `,
  ContentInner: styled.div`
    position: absolute;
    top: 2rem;
    left: 2rem;
    right: 2rem;
    bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  `,
  Heading: styled.h3`
    font-size: 2.4rem;
    font-weight: bold;
  `,
};

function MFifthContainer(): ReactElement {
  const [flippedIndex, setFlippedIndex] = React.useState(-1); // 초기값으로 -1을 설정하여 플립된 요소가 없음을 나타냄

  const handleClick = (index: number) => {
    setFlippedIndex(index === flippedIndex ? -1 : index); // 클릭한 요소의 인덱스와 현재 플립된 요소의 인덱스를 비교하여 같으면 -1, 다르면 클릭한 인덱스를 설정하여 플립 효과를 토글합니다.
  };

  // const slickSettings = {
  //   dots: true,
  //   infinite: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   speed: 200,
  //   swipeToSlide: true,
  //   lazyLoad: "progressive" as any,
  //   initialSlide: 1,
  //   arrows: false,
  // };
  return (
    <Styled.wrap>
      <Styled.title
        data-aos="fade-down"
        data-aos-duration="700"
        data-aos-delay="0"
        data-aos-easing="ease-in-out-cubic"
      >
        놀잇은 아이가 소통하는 <br />
        모든 순간을 지킵니다
      </Styled.title>

      <div className="contents" style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center", gap: "2rem", width: "100%", height: "auto", margin: "2rem auto"
      }}>
        <motion.div
          whileHover={{ scale: 1.1 }}
          onClick={() => handleClick(0)}
          style={{
            backgroundImage: `url(${img_05_01})`,
            backgroundSize: "cover", backgroundRepeat: "no-repeat",
            width: "80vw", height: "calc(80vw*1.0045)", 
            textAlign: "left", 
            padding: "2rem 2rem", 
            boxSizing: "border-box", 
            maxWidth: "460px", 
            maxHeight: "480px",
            borderRadius: "2rem",

          }}>
          {flippedIndex === 0 ? (
            <div style={{
              width: "100%", height: "100%",
              paddingBottom: "2rem",
              display: "flex", flexDirection: "column",
              justifyContent: "space-between", alignItems: "flex-start",
            }}>
              <p style={{
                fontSize: "2.0rem",
                fontWeight: "400",
                lineHeight: "3.0rem",
                padding: '1rem',
              }}>최초 아이 프로필 등록 시 <br />
                진행되는 튜토리얼 영상을 <br />
                통해 놀잇팀에서 엄격하게 <br />
                아이 인증을 진행합니다.</p>
              <img style={{
                width: "5.6rem",
                height: "5.6rem",
              }} src={btn_05_close} alt="" />
            </div>
          ) : (<>
            <h3 style={{
              fontSize: "2.4rem", fontWeight: "bold",
              lineHeight: "3.2rem",
              padding: '1rem',
            }}>
              실시간<br />아이 인증</h3>
            <img style={{
              width: "5.6rem",
              height: "5.6rem",
            }} src={btn_05_open} alt="" />
          </>)}

        </motion.div>
        <motion.div
          whileTap={{ scale: 1.1 }}
          onClick={() => handleClick(1)}

          style={{
            backgroundImage: `url(${img_05_02})`,
            backgroundSize: "cover", backgroundRepeat: "no-repeat",
            width: "80vw", height: "calc(80vw*1.0045)", 
            textAlign: "left", 
            padding: "2rem 2rem", 
            boxSizing: "border-box", 
            maxWidth: "460px", 
            maxHeight: "480px",
            borderRadius: "2rem",
          }}>
          {flippedIndex === 1 ? (
            <div style={{
              width: "100%", height: "100%",
              paddingBottom: "2rem",
              display: "flex", flexDirection: "column",
              justifyContent: "space-between", alignItems: "flex-start",
            }}>
              <p style={{
                fontSize: "2.0rem",
                fontWeight: "400",
                lineHeight: "2.4rem",
                padding: '1rem',
              }}>아이들의 안전한 놀이 환경을 위해 도움선생님들이 항상 대기하고 있습니다. 아이들이 해결할 수 없는 어른의 개입이 필요한 순간 언제나 아이들을 도와줄 준비를 하고 있어요.</p>
              <img style={{
                width: "5.6rem",
                height: "5.6rem",
              }} src={btn_05_close} alt="" />
            </div>
          ) : (<>
            <h3 style={{
              fontSize: "2.4rem", fontWeight: "bold",
              lineHeight: "3.2rem",
              padding: '1rem',
            }}>도움선생님<br />상시 대기</h3>
            <img style={{
              width: "5.6rem",
              height: "5.6rem",
            }} src={btn_05_open} alt="" />
          </>)}
        </motion.div>
        <motion.div
          whileHover={{ scale: 1.1 }}
          onClick={() => handleClick(2)}
          style={{
            backgroundImage: `url(${img_05_03})`, 
            backgroundSize: "cover", backgroundRepeat: "no-repeat",
            width: "80vw", height: "calc(80vw*1.0045)", 
            textAlign: "left", 
            padding: "2rem 2rem", 
            boxSizing: "border-box", 
            maxWidth: "460px", 
            maxHeight: "480px",
            borderRadius: "2rem",
          }}>
          {flippedIndex === 2 ? (
            <div style={{
              width: "100%", height: "100%",
              paddingBottom: "2rem",
              display: "flex", flexDirection: "column",
              justifyContent: "space-between", alignItems: "flex-start",
            }}>
              <p style={{
                fontSize: "2.0rem",
                fontWeight: "400",
                lineHeight: "3.0rem",
                padding: '1rem',
              }}>놀잇은 아동・청소년 개인정보보호 가이드라인을 준수하며, 아이들이 자신의 개인정보를 잘 지킬 수 있도록 다양한 교육활동을 진행하고 있습니다.</p>
              <img style={{
                width: "5.6rem",
                height: "5.6rem",
              }} src={btn_05_close} alt="" />
            </div>
          ) : (
            <> <h3 style={{
              fontSize: "2.4rem", fontWeight: "bold",
              lineHeight: "3.2rem",
              padding: '1rem',
            }}>아이 정보<br />보호</h3>
              <img style={{
                width: "5.6rem",
                height: "5.6rem",
              }} src={btn_05_open} alt="" /></>
          )}

        </motion.div>
      </div >

      {/* <Styled.screen
        data-aos="zoom-up"
        data-aos-duration="700"
        data-aos-delay="1000"
        data-aos-easing="ease-in-out-cubic"
      /> */}
      {/* <Styled.reviewWrapper
        data-aos="zoom-in-up"
        data-aos-duration="700"
        data-aos-delay="1700"
        data-aos-easing="ease-in-out-cubic"
      >
        <Slider {...slickSettings}>
          <MReviewCardBottomFixed
            text={
              <>
                엄마 아빠에게도
                <br />
                자유시간이 생겨서 좋아요
                <br />
              </>
            }
            width={"30rem"}
            height={"31.8rem"}
            nameTag={"박*호 학부모"}
            bottom={"10rem"}
          />
          <MReviewCardBottomFixed
            text={
              <>
                아이의 새로운 모습을
                <br />
                많이 볼 수 있어 좋았어요
                <br />
                부모인 저도 많은걸
                <br />
                느끼는 시간이었습니다
              </>
            }
            width={"30rem"}
            height={"31.8rem"}
            nameTag={"정*솔 학부모"}
            bottom={"10rem"}
          />
          <MReviewCardBottomFixed
            text={
              <>
                평가의 시선없이
                <br />
                우리 아이가 자유롭게
                <br />
                이야기 할 수 있어 좋았어요
              </>
            }
            width={"30rem"}
            height={"31.8rem"}
            nameTag={"김*헌 학부모"}
            bottom={"10rem"}
          />
        </Slider>
      </Styled.reviewWrapper> */}
    </Styled.wrap>
  );
}

export default MFifthContainer;
