import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";

interface IUseResize {
  windowWidth: number;
}
function useResize(): IUseResize {
  const [resize, setResize] = useState({ windowWidth: window.innerWidth });

  const onResize = useCallback(
    debounce((): void => {
      setResize({ windowWidth: window.innerWidth });
    }, 300),
    []
  );

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);
  return resize;
}

export default useResize;
