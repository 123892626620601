import React, { useState } from "react";
import styled from "styled-components";
import img_header_norit_logo from "@assets/image/img_header_norit_logo.png";
import MFirstContainer from "@containers/mobile/MFirstContainer";
import MSecoundContainer from "@containers/mobile/MSecoundContainer";
import AOS from "aos";
import MThirdContainer from "@containers/mobile/MThirdContainer";
import MFourthContainer from "@containers/mobile/MFourthContainer";
import MFifthContainer from "@containers/mobile/MFifthContainer";
import MReviewsContainer from "@containers/mobile/MReviewsContainer";
import MPartnersContainer from "@containers/mobile/MPartnersContainer";
import MContactContainer from "@containers/mobile/MContactContainer";
import Footer from "@components/Footer";
import img_download from "@assets/image/img_download@2x.png";
import ico_menu from "@assets/image/ico_menu@2x.png";
import ico_close from "@assets/image/ico_close@2x.png";
import { isiOS } from "../utils/agentHelper";
import { useNavigate } from "react-router-dom";
import { LoginChecker } from "../shared/provider/AuthProvider";
import img_home_00 from "@assets/images/img_home_00.png";
import { toast } from "react-toastify";

const Styled = {
  wrap: styled.div`
    width: 100vw;
    overflow-x: hidden;
  `,

  headerWrapper: styled.div`
    background: white;
    position: fixed;
    z-index: 99;
    width: 100vw;
    box-shadow: rgba(0, 0, 0, 0.08) 0 4px 24px;
  `,
  header: styled.div`
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1.8rem 0 3rem;
  `,
  menuWrapper: styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 2rem;
  `,
  menu: styled.a`
    text-align: center;
    font-size: 2rem;
    padding: 2rem 0;
  `,
  menuP: styled.p`
  text-align: center;
  font-size: 2rem;
  padding: 2rem 0;
`,
  logo: styled.div`
    /* background: url(${img_header_norit_logo}) no-repeat center center/contain; */
    justify-items: flex-start;
    min-width: 4.2rem;
    min-height: 3.6rem;
    width: 6rem;
  `,
  download: styled.a`
    box-sizing: border-box;
    padding: 1rem 1.4rem;
    border-radius: 0.6rem;
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #FFA800;
    display: flex;
    align-items: center;
  `,
  right: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
  downloadIcon: styled.div`
    margin-right: 0.6rem;
    min-width: 2.4rem;
    min-height: 2.4rem;
    background: url(${img_download}) no-repeat center center/contain;
  `,
  menuIcon: styled.div`
    margin-left: 1.4rem;
    min-width: 3rem;
    min-height: 3rem;
    background: url(${ico_menu}) no-repeat center center/contain;
  `,
  closeIcon: styled.div`
    margin-left: 1.4rem;
    min-width: 3rem;
    min-height: 3rem;
    background: url(${ico_close}) no-repeat center center/contain;
  `,
};

function MHeader() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  AOS.init({
    once: false,
    mirror: true,
  });
  return (
    <Styled.wrap>
      <Styled.headerWrapper>
        <Styled.header>
          <Styled.logo onClick={() => navigate('/')}>
            <img src={img_header_norit_logo} style={{
              // maxWidth: "100%",
              filter: "drop-shadow(4px 3px 3px #c3c3c3)",
              width: "100%",
            }} />
          </Styled.logo>
          <Styled.right>

            <Styled.download
              onClick={() => {
                toast.info("준비중입니다.");
              }}
            // target="_blank"
            // href={
            //   "https://norit.page.link/w8m1"
            // }
            >
              우리아이 짝꿍 배정 받기
            </Styled.download>
            {/* <Styled.download
              target="_blank"
              href={
                "https://norit.page.link/w8m1"
              }
            >
              <Styled.downloadIcon />
              APP DOWNLOAD
            </Styled.download> */}
            {!open ? (
              <Styled.menuIcon onClick={() => setOpen(!open)} />
            ) : (
              <Styled.closeIcon onClick={() => setOpen(!open)} />
            )}
          </Styled.right>
        </Styled.header>
        {open && (
          <Styled.menuWrapper>
            <LoginChecker showNotLogin={true}>
              <Styled.menu href={"/login"}>
                로그인
              </Styled.menu>
            </LoginChecker>
            <LoginChecker showNotLogin={false}>
              <Styled.menuP onClick={() => {
                setOpen(!open);
                navigate('/mypage/default');
              }}>
                마이페이지
              </Styled.menuP>
            </LoginChecker>
            <Styled.menu href={"https://company.norit.io/"} target={"_blank"}>
              회사소개
            </Styled.menu>
            <Styled.menu
              href={
                "https://company.norit.io/faq"
              }
              target={"_blank"}
            >
              자주묻는질문
            </Styled.menu>
            {/* <Styled.menu
              href={
                "/mbti"
              }
            >
              우리아이 짝꿍 배정 받기
            </Styled.menu> */}


            {/* <LoginChecker showNotLogin={false}>
              <Styled.menu href={"/"}>
                로그아웃 
              </Styled.menu>
            </LoginChecker> */}
          </Styled.menuWrapper>
        )}
      </Styled.headerWrapper>

    </Styled.wrap>
  );
}
export default MHeader;
