import React, { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { MAX_WIDTH_PC } from "../styles/preset";

import img_norit_logo from "@assets/image/img_norit_logo@2x.png";
import img_illust_01 from "@assets/image/img_illust_01@2x.png";
import bg_02_line from "@assets/images/bg_02_line.svg";
import bg_02_board from "@assets/images/bg_02_board.svg";
import star_0 from "@assets/images/star_0.svg";
import star_1 from "@assets/images/star_1.svg";
import union from "@assets/images/union.svg";
import percent_blue from "@assets/images/percent_blue.svg";
import percent_green from "@assets/images/percent_green.svg";
import CountUp from "../shared/components/Countup";

const Styled = {
  outter: styled.div<{ height: number }>`
  position: relative;
  height: calc(${({ height }) => (height - 54) / 10}rem);
  `,
  wrap: styled.div<{ height: number }>`
    ${MAX_WIDTH_PC};
    box-sizing: border-box;
    padding: 0 4rem;
    margin: 0 auto;
    height: calc(${({ height }) => (height - 54) / 10}rem);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    
  `,
  left: styled.div`
    flex-basis: 70rem;
    margin-top: 11rem;
  `,
  right: styled.div`
    margin-left: 8.6rem;
  `,
  logoImg: styled.img`
    position: absolute;
    top: -20px;
    left: -20px;
  `,
  logo: styled.div`
    position: relative;
    font-size: 5rem;
    color: transparent;
    display: inline-block;
  `,
  title: styled.div`
    color: black;
    font-size: 4.8rem;
    line-height: 6.2rem;
    font-weight: 800;
    margin-bottom: 4rem;
  `,
  sub: styled.div`
    color: black;
    font-size: 2.4rem;
    margin-top: 18px;
    margin-bottom: 38px;
    line-height: 3.6rem;
  `,
  screenWrapper: styled.div`
    position: relative;
    width: 100%;
  `,
};
function SecondContainer({ height }: { height: number }): ReactElement {
  return (
    <Styled.outter height={height}>
      <Styled.wrap height={height}>
        <Styled.left>
          <Styled.title
            data-aos="fade-down"
            data-aos-duration="700"
            data-aos-delay="0"
            data-aos-easing="ease-in-out-cubic"
          >
            {/* <Styled.logo>
            놀잇 <Styled.logoImg src={img_norit_logo} width={100} />
          </Styled.logo>
           */}
            이미 많은 부모님과 아이들이 <br />
            만족하고 있습니다
          </Styled.title>
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}>
            <motion.div>
              <p style={{
                color: 'black',
                fontSize: '2.4rem',
                marginTop: '2rem',
                marginBottom: '1rem',
                lineHeight: '3.6rem',
              }}>아이들이 만나 <b>‘놀잇’</b> 한 수</p>

              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <CountUp count={10000} duration={600} decimalPlaces={0} />
                <motion.img
                  initial={{ opacity: 0, y: -120 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 1.2, type: 'spring', stiffness: 120 }}
                  src={union} />
              </div>
            </motion.div>

            <div>
              <p style={{
                color: 'black',
                fontSize: '2.4rem',
                marginTop: '2rem',
                marginBottom: '1rem',
                lineHeight: '3.6rem',
              }}>아이 이용 만족도</p>
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <CountUp count={98} duration={600} decimalPlaces={0} />
                <motion.img
                  initial={{ opacity: 0, y: -120 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.6, type: 'spring', stiffness: 120 }}
                  src={percent_green} />
              </div>
            </div>

            <div>
              <p style={{
                color: 'black',
                fontSize: '2.4rem',
                marginTop: '2rem',
                marginBottom: '1rem',
                lineHeight: '3.6rem',
              }}>부모 이용 만족도</p>
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <CountUp count={97} duration={600} decimalPlaces={0} />
                <motion.img
                  initial={{ opacity: 0, y: -120 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.6, type: 'spring', stiffness: 120 }}
                  src={percent_blue} />
              </div>
            </div>

            <div style={{ position: 'relative' }}>
              <p style={{
                color: 'black',
                fontSize: '2.4rem',
                marginTop: '2rem',
                marginBottom: '1rem',
                lineHeight: '3.6rem',
              }}>순추천 지수</p>
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>

                <motion.img
                  style={{
                    position: 'absolute',
                    zIndex: -1,
                  }}
                  initial={{ opacity: 0, y: -120, x: -30 }}
                  whileInView={{ opacity: 1, y: 10, x: -30 }}
                  transition={{ duration: 0.6, delay: 0.3, type: 'spring', stiffness: 120 }}
                  src={star_0} />
                <CountUp count={9.6} duration={600} decimalPlaces={1} />
                <motion.img
                  style={{
                    position: 'absolute',
                    zIndex: -1,
                  }}
                  initial={{ opacity: 0, y: -120, x: 70 }}
                  whileInView={{ opacity: 1, y: -30, x: 70 }}
                  transition={{ duration: 0.6, delay: 0.5, type: 'spring', stiffness: 120 }}
                  src={star_1} />
              </div>
            </div>
          </div>
          {/* <Styled.sub
          data-aos="fade-down"
          data-aos-duration="700"
          data-aos-delay="300"
          data-aos-easing="ease-in-out-cubic"
        >
          아이들이 만나 ‘놀잇’ 한 수
        </Styled.sub> */}

        </Styled.left>

        <Styled.right>
          <Styled.screenWrapper>

          </Styled.screenWrapper>
        </Styled.right>


      </Styled.wrap>
      <motion.div
        // initial={{ width: 0 }}
        // whileInView={{ width: '100vw' }}
        // transition={{ duration: 1.5 }}
        style={{
          position: 'absolute',
          left: 0,
          zIndex: -1,
          overflow: 'hidden',
          bottom: 0
        }}
      >
        <div
          style={{
            width: '100vw',
            height: '100vh',
            backgroundImage: `url(${bg_02_line}), url(${bg_02_board})`,
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundPosition: '100% 10%, 100% 10%',
            backgroundSize: 'cover, cover',

          }}>

        </div>
      </motion.div>
    </Styled.outter>
  );
}

export default SecondContainer;
