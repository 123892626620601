export enum Colors {
  PRIMARY = "#FF273E",
  white = '#FFFFFF',
  black = '#050505',
  orange = '#FC8E07',
  blue = '#4C9AFF',
  green = '#B3DB2A',
  red = '#FF5A5A',
  yellow = '#FFD402',
}
