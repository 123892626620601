import React, { ReactElement } from "react";
import styled from "styled-components";
import img_header_norit_logo from "@assets/image/img_header_norit_logo.png";
import img_home_00 from "@assets/images/img_home_00.png";

import { DESKTOP_MAX_SIZE } from "../enums";
import { useNavigate } from "react-router-dom";
import { LoginChecker } from "../shared/provider/AuthProvider";
import { toast } from "react-toastify";

const Styled = {
  wrap: styled.div`
    width: 100%;
    background-color: white;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.08) 0 4px 24px;
  `,
  content: styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0rem 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    font-size: 1.8rem;
    max-width: ${DESKTOP_MAX_SIZE};
  `,
  logo: styled.div`
    /* background: url(${img_header_norit_logo}) no-repeat center center/contain; */
    /* background: url(${img_home_00}) no-repeat center center/contain; */
    display: flex;
    justify-items: flex-start;
    min-width: 5rem;
    min-height: 3.4rem;
    width: 5rem;
    padding: 1rem 0;
    cursor: pointer;
  `,
  right: styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `,
  item: styled.a`
    margin-left: 3rem;
    font-size: 1.4rem;
    cursor: pointer;
  `,
  itemP: styled.p`
    margin-left: 2rem;
    font-size: 1.4rem;
    cursor: pointer;
  `,
};
function Header(): ReactElement {
  const navigate = useNavigate();

  return (
    <Styled.wrap>
      <Styled.content>
        <Styled.logo onClick={() => {
          window.location.href = "/";
        }}>
          <img src={img_header_norit_logo} style={{
            maxWidth: "100%",
            filter: "drop-shadow(4px 3px 3px #c3c3c3)",
          }} />
        </Styled.logo>
        <Styled.right>
          {/* <Styled.item href={"/signup"}>
            회원가입
          </Styled.item> */}
          <Styled.item href={"https://company.norit.io/"} target={"_blank"}>
            회사소개
          </Styled.item>
          <Styled.item
            href={"https://company.norit.io/faq"}
            target={"_blank"}
          >
            자주묻는질문
          </Styled.item>
          <LoginChecker showNotLogin={true}>
            <Styled.item href={"/login"} >
              <div style={{
                backgroundColor: "#050505",
                color: "#FFD402",
                fontWeight: "bold",
                padding: '1rem 1.6rem',
                borderRadius: '0.6rem',

              }}
              >로그인</div>
            </Styled.item>
          </LoginChecker>

          <Styled.itemP
            onClick={() => {
              toast.info("준비중입니다.");
              // navigate("/mbti");
            }}
          >
            <div style={{
              backgroundColor: "#FFA800",
              padding: '1rem 1.6rem',
              borderRadius: '0.6rem',
              fontWeight: "bold"
            }}>
              우리아이 짝꿍 배정 받기
            </div>
          </Styled.itemP>

          <LoginChecker showNotLogin={false}>
            <Styled.itemP onClick={() => {
              navigate("/mypage/default");
            }} >

              <div style={{
                backgroundColor: "#050505",
                color: "#FFD402",
                fontWeight: "bold",
                padding: '1rem 1.6rem',
                borderRadius: '0.6rem',

              }}
              >마이페이지</div>

            </Styled.itemP>
          </LoginChecker>
        </Styled.right>
      </Styled.content>
    </Styled.wrap>
  );
}

export default Header;
