import { motion }  from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import styled from "styled-components";

import img_cloud from "../../assets/images/mbti/cloud.png";
import header_text from "../../assets/images/mbti/header_text.png";
import main_children1 from "../../assets/images/mbti/main_children.png";
import main_children2 from "../../assets/images/mbti/ITNP.png";
import main_children3 from "../../assets/images/mbti/ETSJ.png";
import main_text from "../../assets/images/mbti/main_img_03.jpg";
import main_gif from "../../assets/images/mbti/ezgif.com-optimize.gif";
import { Countdown } from "../../shared/components/Countdown";

const Image = styled.img`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: auto;
  object-fit: contain;
`;
const Ballon = styled.div<{ rotate?: number; color: string }>`
  position: relative;
  background: ${(props) => props.color};
  border-radius: 0.4em;
  width: fit-content;
  padding: 0.2rem 0.5rem;
  margin: 0.3rem;
  transform: rotate(${(props) => props.rotate}deg) || 0;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 0.8rem;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: ${(props) => props.color};
    border-bottom: 0;
    border-right: -5px;
    margin-left: -5px;
    margin-bottom: -5px;
  }

  & > p {
    /* text-shadow: 0 -0.05em 0.1em rgba(0, 0, 0, 0.3); */
  }
}`;


const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible,
};

const MBTIMain = () => {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }
  , []);
  
  const navigate = useNavigate();
  const is_mobile = window.innerWidth < 400;

  return (
    <motion.article
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      style={{
        maxWidth: "400px",
        width: "100%",
        backgroundColor: "#C3D3FF",
        minHeight: "100vh",
        margin: "10rem auto",
        overflowY: "auto",
        boxShadow: is_mobile ? "none" : "0 0 10px rgba(0,0,0,0.2)",
      }}
    >
      <div>
        <motion.div style={{}}>
          <motion.div
            style={{
              backgroundImage: `url(${img_cloud})`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: "100%",
              minHeight: is_mobile ? "117vw" : "470px",
              display: "flex",
              flexDirection: "column",
            }}
            animate={{ x: 0 }}
            transition={{ type: "spring", stiffness: 100 }}
          >
            <motion.p
              className=""
              variants={{
                hidden: { opacity: 0, y: -20 },
                visible,
              }}
              style={{
                fontSize: "0.9rem",
                fontWeight: "bold",
                width: "100%",
                color: "#2D2D2D",
                marginTop: "2.4rem",
              }}
            >
              우리아이와 찰떡궁합 친구는?
            </motion.p>
            <motion.img
              src={header_text}
              style={{ width: "80%", margin: "0 auto" }}
              variants={{
                hidden: { opacity: 0, y: -30 },
                visible,
              }}
            />
            <div style={{ position: "relative" }}>
              <motion.div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "38%",
                  position: "absolute",
                  left: "0%",
                  margin: "12vh 0",
                }}
                variants={{
                  hidden: { opacity: 0, y: -20 },
                  visible,
                }}
              >
                <Ballon rotate={-10} color={"#6098FF"}>
                  #관심사
                </Ballon>
                <Image src={main_children2} width={"100%"} />
              </motion.div>
              <motion.div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "40%",
                  position: "absolute",
                  right: 20,
                  margin: "16vh 0",
                }}
                variants={{
                  hidden: { opacity: 0, y: -20 },
                  visible,
                }}
              >
                <Ballon rotate={0} color={"#81AF39"}>
                  #친구관계
                </Ballon>
                <Image src={main_children1} width={"100%"} />
              </motion.div>

              <motion.div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "30%",
                  position: "absolute",
                  right: "40%",
                  margin: "1vh 0",
                }}
                variants={{
                  hidden: { opacity: 0, y: -20 },
                  visible,
                }}
              >
                <Ballon rotate={20} color={"#E13E46"}>
                  #외향적
                </Ballon>
                <Image src={main_children3} width={"100%"} />
              </motion.div>
            </div>
          </motion.div>
          <motion.div
            style={{
              background: "#759AFC",
              minHeight: "50vh",
              padding: "5rem 0rem",
            }}
          >
            <motion.button
              style={{
                background: "#08287D",
                color: "#FFF",
                border: "none",
                padding: "1rem",
                borderRadius: "10rem",
                fontSize: "1.3rem",
                fontWeight: "bold",
                fontFamily: "Noto Sans KR",
                boxShadow: "0 0 15px rgba(0,0,0,0.3)",
                width: "90%",
              }}
              onClick={() => {
                navigate("/mbti/quiz");
              }}
              whileHover={{
                scale: 0.9,
                transition: { duration: 1 },
              }}
              whileTap={{ scale: 0.9 }}

            >
              우리아이 찐친 찾으러가기
            </motion.button>

            <motion.img
              src={main_text}
              style={{
                width: "100%",
                margin: "0 auto",
              }}
            />

            <motion.img
              src={main_gif}
              style={{
                width: "100%",
                margin: "0 auto",
              }}
            />
          </motion.div>
        </motion.div>
      </div>

      <div>
        {/* <Countdown /> */}
      </div>

      <footer
        style={{
          background: "#C3D3FF",
          fontSize: "12px",
          textAlign: "left",
          color: "#08287D",
          padding: "1rem",
        }}
      >
        - 어른과 달리 어린이의 성격은 아직 100% 정립되어 있지 않으므로 테스트의
        결과가 아이의 미래성격을 예측 하는 것은 아닙니다. 아이의 성격이
        긍정적으로 성장하고 발전하는데에 자그마한 참고사항으로 접근해주세요.
        <br />
        <br />
        -위 테스트의 결과를 통해 어린이들을 라벨링하거나 특정 집단에 속하게
        만들지 말아주세요. 아이들의 무한한 상상력과 성장을 위해 모든 어린이의
        개인적인 성격과 특징을 인정하고 응원해주세요 :)
      </footer>
    </motion.article>
  );
};

export default MBTIMain;
