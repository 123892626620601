import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";

import styled from "styled-components";

import useAnswerStore, { mbti_types } from "../../shared/zustand/mbti";
import { shareKakao } from "../../shared/kakaoshare";

const Image = styled.img`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: auto;
  object-fit: contain;
`;

const Ul = styled.ul`
  list-style: none; /* Remove default bullets */
  text-align: left; /* Left-align list items */
  padding: 0rem 0.3rem;
`;

// const Li = styled(motion.li)`
//   padding: 0.2rem 0rem 0.2rem 0.5rem;
//   &::before {
//     content: "•";
//     color: #81af39 !important;
//     font-weight: 800;
//     display: inline-block;
//     width: 0.5em;
//     margin-left: -0.5em;
//   }
// `;

const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

const upVariants = {
  hidden: { opacity: 0, y: 10 },
  visible,
};

const downVariants = {
  hidden: { opacity: 0, y: -10 },
  visible,
};

const cardVariants = {
  hidden: { opacity: 0, y: 100, rotateY: 300 },
  visible: {
    opacity: 1,
    y: 0,
    rotateY: 0,
    transition: {
      rotateY: {
        duration: 0.3,
      },
      y: {
        type: "spring",
        damping: 3,
        stiffness: 50,
        restDelta: 0.01,
        duration: 0.3,
      },
    },
  },
};
const Result = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const scrollRef = React.useRef(null);
  const is_mobile = window.innerWidth < 400;
  const [show_desc, setShowDesc] = React.useState('');
  const { resetAnswer } = useAnswerStore();

  const my_type = mbti_types.find((item) => item.type === type);

  return (
    <motion.article
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      style={{
        maxWidth: "400px",
        width: "100%",
        backgroundColor: "#C3D3FF",
        minHeight: "100vh",
        margin: "10rem auto 0rem auto",
        overflowY: "auto",
        boxShadow: is_mobile ? "none" : "0 0 10px rgba(0,0,0,0.2)",
      }}
    >
      <div ref={scrollRef}>
        <motion.div style={{}}>
          <motion.p
            className=""
            variants={upVariants}
            style={{
              fontSize: "1.4rem",
              lineHeight: "2rem",
              fontWeight: "800",
              width: "100%",
              color: "#2D2D2D",
              marginTop: "2.4rem",
            }}
          >
            {my_type?.sub_title}
          </motion.p>
          <motion.h1 style={{ color: "#08287D" }}>{my_type?.title}</motion.h1>

          <motion.div
            style={{
              width: "90%",
              margin: "6rem auto 2rem auto",
              borderRadius: "1rem",
              backgroundColor: "#FFF",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
              padding: "5rem 0.5rem 2rem 0.5rem",
              minHeight: "50vh",
            }}
          >
            <motion.img
              src={`/imgs/mbti/${type}.png`}
              style={{ width: "30%", margin: "-48% 0 0 0" }}
              variants={downVariants}
            />
            <motion.p style={{ fontWeight: "800" }}>
              특징은 무엇일까요?
            </motion.p>
            <Ul>
              {my_type?.characteristic.map((c, idx) => {
                return (
                  <motion.li
                    key={`characteristic_${idx}`}
                    variants={downVariants}
                    style={{
                      textAlign: "left", fontSize: "1.4rem",
                      lineHeight: "2rem",
                    }}
                  >
                    {c}
                  </motion.li>
                );
              })}
            </Ul>

            <motion.p style={{ fontWeight: "800", margin: "1rem 0" }}>
              조심해야할 점은 무엇일까요?
            </motion.p>
            <Ul>
              {my_type?.careful.map((c, idx) => {
                return (
                  <motion.li
                    key={`careful_${idx}`}
                    variants={downVariants}
                    style={{
                      textAlign: "left", fontSize: "1.4rem",
                      lineHeight: "2rem",
                    }}
                  >
                    {c}
                  </motion.li>
                );
              })}
            </Ul>
          </motion.div>

          <motion.div
            variants={cardVariants}
            initial="hidden"
            whileInView={"visible"}
            style={{
              width: "90%",
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "1rem",
              margin: "0rem auto 2rem auto",
            }}
          >
            <motion.div
              style={{
                borderRadius: "1rem",
                backgroundColor: "#FFF",
                display: "flex",
                flexDirection: "column",
                gap: "0.4rem",
                alignItems: "center",
                padding: "1rem 0.5rem",
                justifyContent: "space-between",
                border: show_desc === "soulmate" ? "2px solid #08287D" : "none",
              }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
              onClick={() => {
                setShowDesc("soulmate");
              }}
            >
              <motion.h3>소울메이트</motion.h3>
              <motion.img
                src={`/imgs/mbti/${my_type?.soulmate}.png`}
                variants={downVariants}
                style={{ width: "70%" }}
              />
              <div>
                <motion.p
                  style={{
                    fontSize: "1.4rem",
                    lineHeight: "2rem",
                  }}
                >
                  {
                    mbti_types.find((item) => item.type === my_type?.soulmate)
                      ?.sub_title
                  }
                </motion.p>
                <motion.p
                  style={{
                    fontSize: "1.6rem",
                    lineHeight: "2.2rem",
                    fontWeight: "800",
                  }}
                >
                  {
                    mbti_types.find((item) => item.type === my_type?.soulmate)
                      ?.title
                  }
                </motion.p>
              </div>
            </motion.div>
            <motion.div
              style={{
                borderRadius: "1rem",
                backgroundColor: "#FFF",
                display: "flex",
                flexDirection: "column",
                gap: "0.4rem",
                alignItems: "center",
                padding: "1rem 0.5rem",
                justifyContent: "space-between",
                border: show_desc === "synergy" ? "2px solid #08287D" : "none",
              }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
              onClick={() => {
                setShowDesc("synergy");
              }}
            >
              <motion.h3>시너지가득</motion.h3>
              <motion.img
                src={`/imgs/mbti/${my_type?.synergy}.png`}
                variants={downVariants}
                style={{ width: "70%" }}
              />
              <div>
                <motion.p
                  style={{
                    fontSize: "1.4rem",
                    lineHeight: "2rem",
                  }}
                >
                  {
                    mbti_types.find((item) => item.type === my_type?.synergy)
                      ?.sub_title
                  }
                </motion.p>
                <motion.p
                  style={{
                    fontSize: "1.6rem",
                    lineHeight: "2.2rem",
                    fontWeight: "800",
                  }}
                >
                  {
                    mbti_types.find((item) => item.type === my_type?.synergy)
                      ?.title
                  }
                </motion.p>
              </div>
            </motion.div>
          </motion.div>

          {show_desc === '' ? null : (
            <motion.div
              style={{
                width: "90%",
                margin: "0rem auto 2rem auto",
                borderRadius: "1rem",
                backgroundColor: "#FFF",
                padding: "1rem 0.5rem",
              }}
            >
              <motion.p style={{ fontSize: "0.9rem" }}>
                {show_desc == null
                  ? null
                  : mbti_types.find(
                    (item) => item.type === my_type?.[show_desc]
                  )?.[`${show_desc}_desc`]}
              </motion.p>
            </motion.div>
          )}
        </motion.div>

        <hr
          style={{
            border: "0.5px solid #08287D55",
            width: "90%",
            margin: "auto",
          }}
        ></hr>
        {/* button area */}
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            margin: "2rem auto",
          }}
        >
          <motion.button
            style={{
              backgroundColor: "#FFF",
              border: "none",
              borderRadius: "5rem",
              padding: "0.7rem 1rem",
              width: "100%",
              fontSize: "1.6rem",
              lineHeight: "2.2rem",
              fontWeight: "800",
            }}
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              resetAnswer();
              navigate("/mbti");
            }}
          >
            테스트 다시 하기
          </motion.button>
          <motion.button
            style={{
              backgroundColor: "#FFF",
              border: "none",
              borderRadius: "5rem",
              padding: "0.7rem 1rem",
              width: "100%",
              fontSize: "1.6rem",
              lineHeight: "2.2rem",
              fontWeight: "800",
            }}
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              resetAnswer();
              navigate("/mbti/result");
            }}
          >
            아이들 모든 유형 보러가기
          </motion.button>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              width: "100%",
              fontSize: "2rem",
              fontWeight: "800",
            }}
          >
            <motion.button
              style={{
                backgroundColor: "#FFF",
                border: "none",
                borderRadius: "5rem",
                padding: "0.7rem 1rem",
                width: "100%",
                fontSize: "1.6rem",
                lineHeight: "2.2rem",
                fontWeight: "800",
              }}
              whileTap={{ scale: 0.9 }}
              onClick={() => {
                // copy web page link
                navigator.clipboard.writeText(window.location.href);
              }}
            >
              링크 복사하기
            </motion.button>
            <motion.button
              style={{
                backgroundColor: "#FFF",
                border: "none",
                borderRadius: "5rem",
                padding: "0.7rem 1rem",
                width: "100%",
                fontSize: "1.6rem",
                lineHeight: "2.2rem",
                fontWeight: "800",
              }}
              whileTap={{ scale: 0.9 }}
              onClick={() => {
                shareKakao(
                  `https://norit.kr/mbti/result/${type}`,
                  "우리아이와 잘맞는 찐친찾기",
                  `https://norit.kr/imgs/mbti/${type}.png`
                );
              }}
            >
              카카오톡 공유하기
            </motion.button>
          </div>
        </div>

        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100vw",
            textAlign: "center",
          }}
        >
          <motion.button
            style={{
              maxWidth: "400px",
              width: "100%",
              margin: "0 auto",
              background: "#08287D",
              color: "#FFF",
              border: "none",
              padding: "0.5rem",
            }}
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              window.open("https://norit.page.link/zEih");
            }}
          >
            <motion.h1>찐친 바로 만나러 가기</motion.h1>
          </motion.button>
        </div>
      </div>

      <hr
        style={{
          border: "0.5px solid #08287D55",
          width: "90%",
          margin: "auto",
        }}
      ></hr>

      <footer
        style={{
          background: "#C3D3FF",
          fontSize: "12px",
          textAlign: "left",
          color: "#08287D",
          padding: "1rem",
          margin: "1rem 0rem 3rem 0rem",
        }}
      >
        - 어른과 달리 어린이의 성격은 아직 100% 정립되어 있지 않으므로 테스트의
        결과가 아이의 미래성격을 예측 하는 것은 아닙니다. 아이의 성격이
        긍정적으로 성장하고 발전하는데에 자그마한 참고사항으로 접근해주세요.
        <br />
        <br />
        -위 테스트의 결과를 통해 어린이들을 라벨링하거나 특정 집단에 속하게
        만들지 말아주세요. 아이들의 무한한 상상력과 성장을 위해 모든 어린이의
        개인적인 성격과 특징을 인정하고 응원해주세요 :)
      </footer>
    </motion.article>
  );
};

export default Result;
