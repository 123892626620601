import React, { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { STYLE_KEEP_WORD } from "../styles/preset";
import { DESKTOP_MAX_SIZE, MOBILE_MIN_SIZE, BIG_TABLET_MAX_SIZE, SMALL_TABLET_MAX_SIZE } from "../enums";

//images
import bg_image from "@assets/images/img_bg_01.svg";
import img_googleplay from "@assets/images/img_googleplay.svg";
import img_appstore from "@assets/images/img_appstore.png";
import img_naver from "@assets/images/jt-naver blog.svg";
import img_insta from "@assets/images/jt-instagram.svg";
import img_youtube from "@assets/images/jt-youtube.svg";
import child_gif from "@assets/images/child.gif";
import img_home_00 from "@assets/images/img_home_00.png";
import iPhone12_Clay_Shadow from "@assets/images/iPhone12_Clay_Shadow.png";
import { Colors } from "../enums/Colors";



const Styled = {
  wrap: styled.div<{ height: number }>`
    height: calc(${({ height }) => height / 10}rem);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    overflow-y: hidden;
    background: url(${bg_image}) no-repeat center center/cover;
  `,
  content: styled.div`
    text-align: center;
    z-index: 3;
    padding-top: 15rem;
    width: 100%;
    height: 100%;

    @media (min-width: ${SMALL_TABLET_MAX_SIZE}) and (max-width: ${BIG_TABLET_MAX_SIZE}) {
      display: none;
    }
  `,
  content2: styled.div`
    text-align: center;
    z-index: 3;
    padding-top: 15rem;
    width: 100%;
    height: 100%;
    display: none;
    

    @media (min-width: ${SMALL_TABLET_MAX_SIZE}) and (max-width: ${BIG_TABLET_MAX_SIZE}) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
`,
  title: styled.div`
    ${STYLE_KEEP_WORD};
    color: white;
    font-size: 10rem;
    font-weight: 800;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media (min-width: ${SMALL_TABLET_MAX_SIZE}) and (max-width: ${BIG_TABLET_MAX_SIZE}) {
      font-size: 5rem;
    justify-content: flex-end;
    }
  `,
  sub: styled.div`
    ${STYLE_KEEP_WORD};
    color: white;
    font-size: 6rem;
    margin-top: 5.8rem;
    margin-bottom: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media (min-width: ${SMALL_TABLET_MAX_SIZE}) and (max-width: ${BIG_TABLET_MAX_SIZE}) {
      font-size: 3.4rem;
      text-align : right;
      justify-content: flex-end;
    }

    @media (max-width: ${MOBILE_MIN_SIZE}) {
      font-size: 2rem;
    }
  `,
  stores: styled.div`
    position : relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 2rem;
    margin-top: 6.8rem;
    top: -80%;
    z-index: 3;
    @media (min-width: ${SMALL_TABLET_MAX_SIZE}) and (max-width: ${BIG_TABLET_MAX_SIZE}) {
      justify-content: flex-end;
      margin-top: 3rem;
    }
  `,
  store: styled.a<{ width: number; height: number; url: string }>`
    min-width: ${({ width }) => width}rem;
    min-height: ${({ height }) => height}rem;
    background: url(${({ url }) => url}) no-repeat center center/contain;
    margin-right: 2rem;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  `,

};

function FirstContainer({ height }: { height: number }): ReactElement {
  return (
    <>
      <Styled.wrap height={height}>
        <Styled.content>
          <Styled.title
            data-aos="slide-up"
            data-aos-duration="700"
            data-aos-delay="0"
            data-aos-easing="ease-in-out-cubic"
          >
            <motion.div style={{ fontWeight: '800' }}
              initial={{ x: 0 }}
              whileInView={{ x: -176 }}
              transition={{ duration: 0.5, delay: 0.5 }}
            >6세부터</motion.div>
            <motion.div style={{ fontWeight: '800' }}
              initial={{ x: 0 }}
              whileInView={{ x: 240 }}
              transition={{ duration: 0.5, delay: 0.5 }}>10세까지</motion.div>
          </Styled.title>
          <Styled.title
            data-aos="slide-up"
            data-aos-duration="700"
            data-aos-delay="0"
            data-aos-easing="ease-in-out-cubic"
          >
            <motion.div style={{ fontWeight: '800' }}
              initial={{ x: 0 }}
              whileInView={{ x: -245 }}
              transition={{ duration: 0.5, delay: 0.6 }}>알파세대를</motion.div>

            <motion.div style={{ fontWeight: '800' }}
              initial={{ x: 0 }}
              whileInView={{ x: 180 }}
              transition={{ duration: 0.5, delay: 0.6 }}>위한 SNS</motion.div>
          </Styled.title>

          <Styled.sub
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="700"
            data-aos-easing="ease-in-out-cubic"
          >
            <motion.div initial={{ x: 0 }}
              whileInView={{ x: -190 }}
              transition={{ duration: 0.5, delay: 0.6 }}>우리 아이들의 </motion.div>
            <motion.div initial={{ x: 0 }}
              whileInView={{ x: 245 }}
              transition={{ duration: 0.5, delay: 0.6 }}>첫 소셜네트워크</motion.div>
          </Styled.sub>

          <motion.div
            style={{
              // height: '100%',
              width: '100%',
              maxWidth: `calc((${height}px - 20rem) / 20 * 15)`,
              position: 'relative',
              height: '100%',
              margin: '0 auto',
            }}
            initial={{ opacity: 0, top: 200 }}
            whileInView={{ opacity: 1, top: height / 2 * -1 }}
          // transition={{ duration: 0.5, delay: 0.6 }}
          >
            <div style={{

              // height: '100%',
              // margin: '25% 30%',
              borderRadius: '4rem',
              boxSizing: 'border-box',
              position: 'absolute',
              width: `59%`,
              top: '11.2%',
              backgroundColor: 'black',
              left: '21%',
              overflow: 'hidden',
              // height: '86.5%',
              // height: `calc((((${height}px - 20rem) / 20 * 15)*2250)/1442)`,
              display: 'flex',
            }} >
              <img style={{
                width: '100%',
                margin: 'auto',
              }}
                src={child_gif} />
            </div>

            <img style={{
              boxSizing: 'border-box',
              position: 'absolute',
              left: '0%',
              width: `100%`,
            }} src={iPhone12_Clay_Shadow} />
          </motion.div>
          <img style={{
            padding: '2rem',
            borderRadius: '4rem',
            boxSizing: 'border-box',
            position: 'absolute',
            bottom: '0%',
            left: '59%',
            zIndex: -1,
            width: "25rem",
          }} src={img_home_00} />
          <Styled.stores
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="300"
            data-aos-easing="ease-in-out-cubic">
            <button style={{
              backgroundColor: Colors.orange,
              color: Colors.white,
              borderRadius: '1rem',
              padding: '1rem',
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              fontSize: '1.5rem',
              fontWeight: '800',
              cursor: 'pointer',
              zIndex: 2,
            }} onClick={() => {
              window.open("https://apps.apple.com/us/app/%EB%86%80%EC%9E%87-%EC%95%8C%ED%8C%8C%EC%84%B8%EB%8C%80-%EC%A0%84%EC%9A%A9-sns/id6470339582", '_blank');
            }}>
              <img src={img_appstore} style={{ width: "23px" }} />
              App Store
            </button>
            <button style={{
              backgroundColor: Colors.orange,
              color: Colors.white,
              borderRadius: '1rem',
              padding: '1rem',
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              fontSize: '1.5rem',
              fontWeight: '800',
              cursor: 'pointer'
            }} onClick={() => {
              window.open('https://play.google.com/store/apps/details?id=io.noriiit.app&utm_source=noritio&utm_medium=organic&utm_campaign=UA', '_blank');
            }}>
              <img src={img_googleplay} style={{ width: "23px" }} />
              Google Play
            </button>
          </Styled.stores>

          <motion.div style={{
            position: 'absolute',
            left: '5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
            top: '50%',
          }}
          >
            <motion.button style={{
              backgroundColor: Colors.white,
              width: '5rem',
              height: '5rem',
              borderRadius: '5rem',
              boxShadow: '0 0 0.3rem 0 rgba(0, 0, 0, 0.2)',
            }} onClick={() => {
              window.open("https://www.youtube.com/c/danikinder", '_blank');
            }}>
              <img src={img_youtube} />
            </motion.button>
            <motion.button style={{
              backgroundColor: Colors.white,
              width: '5rem',
              height: '5rem',
              borderRadius: '5rem',
              boxShadow: '0 0 0.3rem 0 rgba(0, 0, 0, 0.2)',
            }}
              onClick={() => {
                window.open("https://www.instagram.com/norit.io", '_blank');
              }}>
              <img src={img_insta} />
            </motion.button>
            <motion.button style={{
              backgroundColor: Colors.white,
              width: '5rem',
              height: '5rem',
              borderRadius: '5rem',
              boxShadow: '0 0 0.3rem 0 rgba(0, 0, 0, 0.2)',
            }}
              onClick={() => {
                window.open("https://blog.naver.com/norit-io", '_blank');
              }}>
              <img src={img_naver} />
            </motion.button>
          </motion.div>
        </Styled.content>

        <Styled.content2>
          <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}>
            <Styled.title
              data-aos="slide-up"
              data-aos-duration="700"
              data-aos-delay="0"
              data-aos-easing="ease-in-out-cubic"
            >
              6세부터 10세까지
            </Styled.title>
            <Styled.title
              data-aos="slide-up"
              data-aos-duration="700"
              data-aos-delay="0"
              data-aos-easing="ease-in-out-cubic"
            >
              알파세대를 위한 SNS
            </Styled.title>

            <Styled.sub
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-delay="700"
              data-aos-easing="ease-in-out-cubic"
            >우리 아이들의 첫 소셜네트워크
            </Styled.sub>
            <Styled.stores
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-delay="300"
              data-aos-easing="ease-in-out-cubic">
              <button style={{
                backgroundColor: Colors.orange,
                color: Colors.white,
                borderRadius: '1rem',
                padding: '1rem',
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                fontSize: '1.5rem',
                fontWeight: '800',
                cursor: 'pointer',
                zIndex: 2,
              }} onClick={() => {
                window.open("https://apps.apple.com/us/app/%EB%86%80%EC%9E%87-%EC%95%8C%ED%8C%8C%EC%84%B8%EB%8C%80-%EC%A0%84%EC%9A%A9-sns/id6470339582", '_blank');
              }}>
                <img src={img_appstore} style={{ width: "23px" }} />
                App Store
              </button>
              <button style={{
                backgroundColor: Colors.orange,
                color: Colors.white,
                borderRadius: '1rem',
                padding: '1rem',
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                fontSize: '1.5rem',
                fontWeight: '800',
                cursor: 'pointer'
              }} onClick={() => {
                window.open('https://play.google.com/store/apps/details?id=io.noriiit.app&utm_source=noritio&utm_medium=organic&utm_campaign=UA', '_blank');
              }}>
                <img src={img_googleplay} style={{ width: "23px" }} />
                Google Play
              </button>
            </Styled.stores>
            <div style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-delay="400"
              data-aos-easing="ease-in-out-cubic"
            >
              <img style={{
                padding: '2rem 0rem 2rem 2rem',
                borderRadius: '4rem',
                boxSizing: 'border-box',
                zIndex: -1,
                width: "25rem",
              }} src={img_home_00} />
            </div>

          </div>
          <div
            data-aos="slide-up"
            data-aos-duration="700"
            data-aos-delay="0"
            data-aos-easing="ease-in-out-cubic"

            style={{
              width: '100%',
              maxWidth: `calc((${height}px - 20rem) / 20 * 15)`,
              position: 'relative',
              top: '-14rem',
              height: '100%',
              margin: '0 auto',
            }}
          >
            <div style={{
              borderRadius: '4rem',
              boxSizing: 'border-box',
              position: 'absolute',
              width: `59%`,
              top: '13.2%',
              backgroundColor: 'black',
              left: '21%',
              overflow: 'hidden',
              // height: '86.5%',
              // height: `calc((((${height}px - 20rem) / 20 * 15)*2250)/1442)`,
              display: 'flex',
            }} >
              <img style={{
                width: '100%',
                margin: 'auto',
              }}
                src={child_gif} />
            </div>

            <img style={{
              boxSizing: 'border-box',
              position: 'absolute',
              left: '0%',
              width: `100%`,
            }} src={iPhone12_Clay_Shadow} />


          </div>


          <div style={{
            position: 'absolute',
            left: '5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
            top: '50%',
          }}
          >
            <button style={{
              backgroundColor: Colors.white,
              width: '5rem',
              height: '5rem',
              borderRadius: '5rem',
              boxShadow: '0 0 0.3rem 0 rgba(0, 0, 0, 0.2)',
            }} onClick={() => {
              window.open("https://www.youtube.com/c/danikinder", '_blank');
            }}>
              <img src={img_youtube} />
            </button>
            <button style={{
              backgroundColor: Colors.white,
              width: '5rem',
              height: '5rem',
              borderRadius: '5rem',
              boxShadow: '0 0 0.3rem 0 rgba(0, 0, 0, 0.2)',
            }}
              onClick={() => {
                window.open("https://www.instagram.com/norit.io", '_blank');
              }}>
              <img src={img_insta} />
            </button>
            <button style={{
              backgroundColor: Colors.white,
              width: '5rem',
              height: '5rem',
              borderRadius: '5rem',
              boxShadow: '0 0 0.3rem 0 rgba(0, 0, 0, 0.2)',
            }}
              onClick={() => {
                window.open("https://blog.naver.com/norit-io", '_blank');
              }}>
              <img src={img_naver} />
            </button>
          </div>
        </Styled.content2>
      </Styled.wrap>
    </>
  );
}

export default FirstContainer;
