import { auth, db } from "../firebase"
import { getAuth, signInWithEmailAndPassword, signInWithPopup, signInWithCustomToken, signInWithCredential, GoogleAuthProvider, getIdToken, OAuthProvider } from "firebase/auth";
import { getFirestore, collection, getDocs, doc, setDoc, updateDoc, deleteDoc, Firestore, Timestamp } from 'firebase/firestore/lite';
import request from "../request";
import { LoginFormInputs } from "../../pages/signup/userInputTypes";
import { toast } from "react-toastify";

// firebase auth에 유저 정보 있는 지 체크
const authCheck = async () => {
    const has_credential = await auth.currentUser;

    if (!has_credential) {
        // localStorage.removeItem("norit_token");
        return false;
    }
    return true;
}

// app에 가입되어 있는지 체크
const appUserCheck = async () => {
    try {
        const result = await request.get("/me");
        // 앱 가입 완료 시 ture 리턴
        if (result?.signup_completed) {
            return { result: true, user: result };
        }

    } catch (error: any) {
        const code = error.code;
        console.log(error, code);

        return { result: false, user: null };
    }
    return { result: false, user: null };
}


// app에 가입되어 있지 않을 때
const register = async () => {

    const data = {
        marketingEnabled: false,
        marketingAgreedAt: Timestamp.now(),
        joinPlatform: "web"
    }

    try {
        const res = await request.post("/register", data);

        return res;
    } catch (error: any) {
        // console.log(error);
        const code = error.code;
        // toast.error("앱에 가입하는데 실패했습니다. 잠시 후 다시 시도해주세요.");
        return null;
    }
}

const loginCheck = async () => {

    // firebase auth에 유저 정보 있는 지 체크
    const has_credential = await authCheck();

    if (!has_credential) {
        return { user: null, url: '/', result: false };
    }

    const app_user = await appUserCheck();

    // 앱에 가입되어 있는 지 확인
    // 가입x -> register 후 signup 페이지 경로 리턴 
    if (!app_user.result) {
        const res = await register();

        if (!res) {
            localStorage.removeItem("norit_token");
            return { result: false, user: null, url: '/' };
        }
        return { user: res, url: "/signup", result: true };
    }

    // 가입o -> 메인 페이지 경로 리턴
    if (app_user.user) {

        toast.success("놀잇에 온 걸 환영해요! :)");
        return { user: app_user.user, url: "/", result: true };
    }
    return { user: null, url: "/login", result: false };
}



const NAVER_CLIENT_SCRET = "lpmzuoMpH4";
const NAVER_CLIENT_ID = "W7jRpDq7GsBvIDau7B8a";
const NAVER_CALLBACK_URL = `${process.env.NODE_ENV !== 'production' ? "http://localhost:3000" : "https://norit.io"}/login`;

const naverLogin = async (token: string) => {
    try {
        const { customToken } = await request.get(`/custom-token/naver?accessToken=${token}`);
        const _res = await signInWithCustomToken(auth, customToken);
        const _token = await _res.user.getIdToken();

        localStorage.setItem("norit_token", _token);
        const login_check = await loginCheck();

        return login_check;
    } catch (e) {
        console.log(e);
    }
    return { user: null, url: "/", result: false };

}

const googleLogin = async () => {
    const provider = new GoogleAuthProvider();

    try {

        const res = await signInWithPopup(auth, provider);
        const _token = await res.user.getIdToken();
        localStorage.setItem('norit_token', _token);

        const login_check = await loginCheck();

        return login_check;
    } catch (error: any) {
        // console.log(error);
    }

    return { user: null, url: "/", result: false };
}

const appleLogin = async () => {
    const provider = new OAuthProvider('apple.com');
    const res = await signInWithPopup(auth, provider);
    const _token = await res.user.getIdToken();

    localStorage.setItem('norit_token', _token);

    const login_check = await loginCheck();
    return login_check;
}

const userUpdate = async (user_data: LoginFormInputs) => {
    const uid = auth.currentUser?.uid;

    try {
        const res = await request.patch(`/users/${uid}`, user_data);

        toast.success("회원정보 수정이 완료되었습니다.");
        return { user: res, url: "/", result: true };
    } catch (error: any) {
        toast.error("회원정보 수정에 실패했습니다. 잠시 후 다시 시도해주세요.");
        return { user: null, url: "/", result: false };
    };
}

const logout = async () => {
    await auth.signOut();
    localStorage.removeItem("norit_token");
    toast("로그아웃 되었습니다.");
    return { user: null, url: "/", result: true };
}

const getVideos = async () => {
    const videos = await request.get('/me-videos');

    // console.log("videos ::: ", videos);
    return videos;
}

export {
    naverLogin,
    googleLogin,
    appleLogin,
    userUpdate,
    logout,
    loginCheck,
    getVideos,
    NAVER_CLIENT_ID,
    NAVER_CLIENT_SCRET,
    NAVER_CALLBACK_URL,
};
