import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import { userUpdate, logout as signout } from "../../shared/api/user";
import { LoginFormInputs } from "../signup/userInputTypes";
import { useUserStore } from "../../shared/zustand/user";
import { toast } from "react-toastify";

const sido_data = [
  "",
  "서울특별시",
  "인천광역시",
  "대전광역시",
  "광주광역시",
  "대구광역시",
  "울산광역시",
  "부산광역시",
  "경기도",
  "강원도",
  "충청북도",
  "충청남도",
  "전라북도",
  "전라남도",
  "경상북도",
  "경상남도",
  "제주도"
];

const Styled = {
  wrap: styled.div`
    width: 100vw;
    padding: 2rem 2rem 0rem 2rem;
    box-sizing: border-box;
    `,
  contents: styled.div`
    width: 80vw;
    max-width: 800px;
    margin: 1rem auto;  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.4rem; 
  `,
  button: styled.button`
    width: 100%;
    max-width: 315px;
    margin: 0 auto;
    margin-top: 4rem;
    background-color: #6AA3FF;
    color: white;
    font-size: 1.8rem;
    padding: 1.6rem;
    border-radius: 4rem;
    box-sizing: border-box;
    `,
  rows: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;  
    align-items: center;
    `,
  label: styled.label`
    font-size: 1.4rem;
    color: #999999;
    text-align: left;
  `,
  form: styled.form`
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  `,
  inputWrap: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > * {
      margin-bottom: 1.4rem;
    }
  `,
  input: styled.input`
    width: 100%;
    border: none;
    color: black;
    font-size: 2rem;
    padding-bottom: 0.6rem;
    border-bottom: 2px solid #eaeaea;

    &::placeholder {
      font-size: 2rem;
      color: #e8e8e8;
    }

    &:focus {
      border-bottom: 2px solid #888;
    }
  `,

  select: styled.select`
    width: 100%;
    border: none;
    color: black;
    font-size: 2rem;
    padding-bottom: 0.6rem;
    border-bottom: 2px solid #eaeaea;
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::placeholder {
      font-size: 2rem;
      color: #e8e8e8;
    }

    &:focus {
      border-bottom: 2px solid #888;
    }

    &:focus-visible {
      border : none;
      border-bottom: 2px solid #888;
    }
  `,
  checkboxWrap: styled.div`
    display: flex;
    align-items: center;
    &>p{
      font-size: 1.8rem;
    }
  `,
  checkbox: styled.input`
    width: 2.4rem;
    height: 2.4rem;
    border: 1px solid #888;
    border-radius: 0.4rem;
    margin-right: 1.6rem;
    &:after, &:checked {
      text-align: center;
      content:'✔';
      font-size: 2rem;
      color: #fff;
      position: relative;
        left: 0.1rem;
        top:0;
    }
    &:checked {
      background-color: #6AA3FF;
      border: 1px solid #6AA3FF;
    }
  `,
};

const ModForm = () => {
  const { register, handleSubmit, reset } = useForm<LoginFormInputs>();
  const navigate = useNavigate();
  const { update, sido, phone, name, marketingEnabled, isLogin } = useUserStore(state => state);

  React.useEffect(() => {
    if (!isLogin) {
      toast.info("로그인이 필요합니다.");
      setTimeout(() => {
        navigate('/login');
      }, 1000);
    }
    reset({ sido, phone, name, marketingEnabled });
  }, [sido, phone, name, marketingEnabled]);
  const onSubmit = async (data: LoginFormInputs) => {
    try {
      const res = await userUpdate(data);

      if (res.result) {
        update(res.user);
        // navigate('/');
      }

      // TODO :: 앱에 자체 로그인 생기면 그때 추가,,,
      // await signInWithEmailAndPassword(auth, data.email, data.password);

    } catch (error) {

      toast.error("정보 수정에 실패했습니다. 잠시 후 다시 시도해주세요.");
      // navigate("/");
    }
  };

  return (
    <Styled.wrap>
      <Styled.form onSubmit={handleSubmit(onSubmit)}>
        <Styled.rows>
          <Styled.inputWrap>
            <Styled.label htmlFor="name">이름</Styled.label>
            <Styled.input
              id="name"
              type="text"
              placeholder="이름을 입력해주세요"
              {...register("name", { required: true })}
            />
          </Styled.inputWrap>

          <Styled.inputWrap>
            <Styled.label htmlFor="phone">휴대폰번호</Styled.label>
            <Styled.input
              id="phone"
              type="text"
              placeholder="-를 제외한 핸드폰 번호를 입력해주세요"
              {...register("phone", { required: true })}
            />
          </Styled.inputWrap>

          <Styled.inputWrap>
            <Styled.label htmlFor="sido">지역</Styled.label>
            <Styled.select
              id="sido"
              placeholder="지역을 입력해주세요"
              {...register("sido", { required: true })}
            >
              {sido_data.map((sido, idx) => {
                return (
                  <option key={idx} value={sido}>
                    {sido}
                  </option>
                );
              })
              }
            </Styled.select>

            <Styled.checkboxWrap>
              <Styled.checkbox
                type="checkbox"
                id="marketingEnabled"
                {...register("marketingEnabled", { required: false })}
              /> <p>마케팅 정보 수신 허용</p>
            </Styled.checkboxWrap>
          </Styled.inputWrap>

          <p style={{
            fontSize: '1.4rem',
            color: '#999999',
            textAlign: 'left',
            lineHeight: '2rem',
          }}>
          * 주의사항 <br/>
          - 쿠폰을 사용하실 때 발생할 수 있는 불편사항이나 문제가 있을 경우, 신속한 대응을 위해 가입 시에 이름과 전화번호를 필수로 입력해주시기 바랍니다. 
          <br/>
          - 정보를 기입하신 후 [내 정보 수정하기] 버튼을 눌러야 수정이 완료됩니다.<br/>
          - 이 정보는 개인정보 보호 규정을 준수하여 안전하게 관리됩니다.
          </p>
          <Styled.button type="submit">내 정보 수정하기</Styled.button>
        </Styled.rows>
      </Styled.form>
    </Styled.wrap>
  );
};

export default ModForm;
