import React, {
  createRef,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import FirstContainer from "@containers/FirstContainer";
import SecondContainer from "@containers/SecoundContainer";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "@components/Header";
import ThirdContainer from "@containers/ThirdContainer";
import FourthContainer from "@containers/FourthContainer";
import FifthContainer from "@containers/FifthContainer";
import ReviewsContainer from "@containers/ReviewsContainer";
import PartnersContainer from "@containers/PartnersContainer";
import ContactContainer from "@containers/ContactContainer";
import Footer from "@components/Footer";

import useResize from "@assets/hooks/useResize";
import SixthContainer from "@containers/SixthContainer";

const SPACER_HEIGHT = 76;
const Styled = {
  wrap: styled.div``,
  pager: styled.div`
    position: fixed;
    top: 50%;
    margin: 0 auto;
    right: 60px;
    transform: translate(0, -50%);
    z-index: 99999;
  `,
  pageItemWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,

  pageItem: styled.div<{ active?: boolean; theme?: "white" }>`
    cursor: pointer;
    padding: 12px 0;
    font-size: ${({ active }) => (active ? "2.4rem" : "1.4rem")};
    color: ${({ active, theme }) =>
      active
        ? theme
          ? theme
          : "black"
        : theme === "black"
          ? "rgba(0,0,0, 0.5)"
          : "rgba(255,255,255, 0.5)"};
  `,
  line: styled.div<{ theme?: "white" }>`
    width: 2px;
    background-color: ${({ theme }) => (theme ? theme : "black")};
    height: 60px;
  `,
  spacer: styled.div`
    width: 100%;
    height: ${SPACER_HEIGHT}px;
  `,
};
const TOTAL_PAGE = 8;
AOS.init({
  once: false,
  mirror: true,
});
const DEFAULT_HEIGHT = 768;
function Main(): ReactElement {
  const { windowWidth } = useResize();
  let WIDTH = window.innerWidth;
  const [HEIGHT, setHeight] = useState(window.innerHeight);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(
    function resizeEffect() {
      let height = 0;
      if (!WIDTH || WIDTH === 0) {
        height = 0;
      } else {
        height =
          window.innerHeight < DEFAULT_HEIGHT
            ? DEFAULT_HEIGHT
            : window.innerHeight;
      }
      setHeight(height);
    },
    [windowWidth, WIDTH, setHeight]
  );

  const [pageNum, setPageNum] = useState(0);

  useEffect(() => {
    if (scrollPosition < HEIGHT) {
      setPageNum(0);
    } else if (scrollPosition >= HEIGHT && scrollPosition < HEIGHT * 2) {
      setPageNum(1);
    } else if (scrollPosition >= HEIGHT && scrollPosition < HEIGHT * 3) {
      setPageNum(2);
    } else if (scrollPosition >= HEIGHT && scrollPosition < HEIGHT * 4) {
      setPageNum(3);
    } else if (scrollPosition >= HEIGHT && scrollPosition < HEIGHT * 5) {
      setPageNum(4);
    } else if (scrollPosition >= HEIGHT && scrollPosition < HEIGHT * 6) {
      setPageNum(5);
    } else if (scrollPosition >= HEIGHT && scrollPosition < HEIGHT * 7) {
      setPageNum(6);
    } else if (scrollPosition >= HEIGHT * 7) {
      setPageNum(7);
    }
  }, [scrollPosition]);
  const renderPageItem = useMemo(
    () =>
      Array.from({ length: TOTAL_PAGE }).map((item, itemIndex) => (
        <Styled.pageItemWrapper key={itemIndex}>
          {pageNum !== 0 && pageNum === itemIndex && (
            <Styled.line
              theme={pageNum == 4 ? "white" : "black"}
            />
          )}
          <Styled.pageItem
            theme={pageNum == 4 ? "white" : "black"}
            active={pageNum === itemIndex}
            onClick={() => {
              switch (itemIndex) {
                case 0:
                  window.scrollTo(0, 0);
                  break;
                case 1:
                  window.scrollTo(0, HEIGHT);
                  break;
                case 2:
                  window.scrollTo(0, HEIGHT * 2);
                  break;
                case 3:
                  window.scrollTo(0, HEIGHT * 3);
                  break;
                case 4:
                  window.scrollTo(0, HEIGHT * 4);
                  break;
                case 5:
                  window.scrollTo(0, HEIGHT * 5);
                  break;
                case 6:
                  window.scrollTo(0, HEIGHT * 7);
                  break;
                case 7:
                  window.scrollTo(0, HEIGHT * 8);
                  break;
              }
            }}
          >
            0{itemIndex + 1}
          </Styled.pageItem>
          {pageNum !== TOTAL_PAGE - 1 && pageNum === itemIndex && (
            <Styled.line
              theme={pageNum == 4 ? "white" : "black"}
            />
          )}
        </Styled.pageItemWrapper>
      )),
    [pageNum, scrollPosition]
  );

  return (
    <>
      <Styled.wrap>
        <Styled.pager>{renderPageItem}</Styled.pager>
        <FirstContainer height={HEIGHT + 76} />
        {/* <Styled.spacer /> */}
        <SecondContainer height={HEIGHT + 76} />
        {/* <Styled.spacer /> */}
        <ThirdContainer height={HEIGHT + 76} />
        {/* <Styled.spacer /> */}
        <FourthContainer height={HEIGHT + 76} />
        {/* <Styled.spacer /> */}
        <FifthContainer height={HEIGHT + 76} />
        <SixthContainer height={HEIGHT + 76} />
        {/* <ReviewsContainer height={HEIGHT + 76} /> */}
        <PartnersContainer />
        <ContactContainer />

      </Styled.wrap>
    </>
  );
}

export default Main;
