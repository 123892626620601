import React, { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { MAX_WIDTH_PC } from "../styles/preset";

import { Tab, TabMenu } from "../shared/components/Tabs";
import { Colors } from "../enums/Colors";

import img_04_01_01 from "@assets/images/fourth/img_04_01_01.svg";
import img_04_01_02 from "@assets/images/fourth/img_04_01_02.svg";
import img_04_01_03 from "@assets/images/fourth/img_04_01_03.svg";
import img_04_01_04 from "@assets/images/fourth/img_04_01_04.svg";
import img_04_01_05 from "@assets/images/fourth/img_04_01_05.svg";
import img_04_01_06 from "@assets/images/fourth/img_04_01_06.svg";
import img_04_02_01 from "@assets/images/fourth/img_04_02_01.svg";
import img_04_02_02 from "@assets/images/fourth/img_04_02_02.svg";
import img_04_03_01 from "@assets/images/fourth/img_04_03_01.svg";
import img_04_03_02 from "@assets/images/fourth/img_04_03_02.svg";
import img_04_03_03 from "@assets/images/fourth/img_04_03_03.svg";
import img_04_03_04 from "@assets/images/fourth/img_04_03_04.svg";
import img_04_03_05 from "@assets/images/fourth/img_04_03_05.svg";
import img_04_03_06 from "@assets/images/fourth/img_04_03_06.svg";
import img_04_04_02 from "@assets/images/fourth/img_04_04_02.svg";
import img_04_04_05 from "@assets/images/fourth/img_04_04_05.svg";
import img_star_00 from "@assets/images/fourth/img_star_00.svg";
import img_star_01 from "@assets/images/fourth/img_star_01.svg";
import rectangle_01 from "@assets/images/fourth/img_04_04_02.svg";
import triangle_01 from "@assets/images/fourth/img_04_04_03.svg";
import sparkle_01 from "@assets/images/fourth/sparkle_01.svg";
import sparkle_02 from "@assets/images/fourth/sparkle_02.svg";
import circle_01 from "@assets/images/fourth/img_04_04_01.svg";
import confetti_01 from "@assets/images/fourth/confetti_01.svg";

import confetti_03 from "@assets/images/fourth/confetti_03.svg";
import confetti_04 from "@assets/images/fourth/confetti_04.svg";
import confetti_05 from "@assets/images/fourth/confetti_05.svg";
import confetti_06 from "@assets/images/fourth/confetti_06.svg";
import confetti_07 from "@assets/images/fourth/confetti_07.svg";
import confetti_08 from "@assets/images/fourth/confetti_08.svg";
import confetti_09 from "@assets/images/fourth/confetti_09.svg";
import heart_01 from "@assets/images/fourth/img_04_04_04.svg";

const Styled = {
  wrap: styled.div<{ height: number }>`
    box-sizing: border-box;
    padding: 0rem;
    margin: 0 auto;
    
    height: calc(${({ height }) => (height - 54) / 10}rem);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
  left: styled.div`
    flex-basis: 70rem;
  `,
  right: styled.div`
    margin-left: 8.6rem;
  `,
  logoImg: styled.img`
    position: absolute;
    top: -20px;
    left: -20px;
  `,
  logo: styled.div`
    position: relative;
    font-size: 5rem;
    color: transparent;
    display: inline-block;
  `,
  title: styled.div`
    color: black;
    font-size: 4rem;
    line-height: 5.2rem;
    font-weight: 800;
    text-align: center;
  `,
  sub: styled.div`
    color: black;
    font-size: 2rem;
    margin-top: 3rem;
    margin-bottom: 15px;
    line-height: 3rem;
    text-align: center;
  `,
  childrenImg: styled.img`
    width: 76%;
  `,
  screenWrapper: styled.div`
    position: relative;
    width: 100%;
  `,
  tabContentsTop: styled.div`
    height: 65%;
    width: 100%;
    /* ${MAX_WIDTH_PC}; */
    margin: 0 auto;
    position: relative;
    /* padding: 5rem 0; */
  `,
  tabContentsBottom: styled.div<{ backgroundColor: string, activeButtonColor: string }>`
    position: relative;
    z-index: 1;
    height: 45%;
    /* width: 100%; */
    ${MAX_WIDTH_PC};
    margin:  -5% auto 0 auto;
    box-sizing: border-box;
    padding: 5rem 0;
    box-shadow: 0 -4px 8px 0px ${({ activeButtonColor }) => activeButtonColor + '10'};
    background: ${({ backgroundColor }) => backgroundColor};
    &  b{
      font-weight: 800;
    }
  `,

  desc: styled.div`
    color: #00000044;
    font-size: 2rem;
  `,
};

const images = {
  section1: [
    { src: img_04_03_01, width: 256, position: { top: 27.4, left: 662 }, },
    { src: img_04_03_02, width: 329, position: { top: 170, left: 498 }, },
    { src: img_04_03_03, width: 178, position: { top: 195, left: 1100 }, },
    { src: img_04_03_04, width: 293, position: { top: 89, left: 955 }, },
    { src: img_04_03_05, width: 114, position: { top: 143, left: 1263 }, },
    { src: img_04_03_06, width: 144, position: { top: 333, left: 816 }, },
    { src: confetti_01, width: 110, position: { top: 169, left: 415 }, },
    { src: confetti_04, width: 44, position: { top: 270, left: 445 }, },
    { src: confetti_07, width: 114, position: { top: 65, left: 1131 }, },
    { src: img_star_00, width: 120, position: { top: 59, left: 605 }, },
    // { src: heart_01, width: "15%", position: { top: "60%", left: "60%" }, },
    // { src: confetti_01, width: "15%", position: { top: "60%", left: "60%" }, },
  ],
  section2: [
    { src: sparkle_02, width: 86, position: { top: 371, left: 860 }, },
    { src: sparkle_01, width: 114, position: { top: 90.7, left: 1244 }, },
    { src: img_04_02_01, width: 258, position: { top: 84, left: 527 }, },
    { src: img_04_02_02, width: 267, position: { top: 138, left: 957 }, },
    { src: img_star_00, width: 120, position: { top: 64, left: 1199 }, },
    { src: img_star_01, width: 164, position: { top: 267, left: 438 }, },
    { src: confetti_01, width: 110, position: { top: 91, left: 904 }, },
    { src: confetti_04, width: 44, position: { top: 192, left: 934 }, },
    { src: confetti_08, width: 114, position: { top: 245, left: 784 }, },
  ],
  section3: [
    { src: confetti_06, width: 93, position: { top: 130, left: 792 }, },
    { src: img_04_01_03, width: 292, position: { top: 33, left: 849 }, },
    { src: img_04_01_02, width: 158, position: { top: 88, left: 816 }, },
    { src: img_04_01_04, width: 181, position: { top: 197, left: 658 }, },
    { src: img_04_01_06, width: 259, position: { top: 33, left: 1121 }, },
    { src: img_04_01_05, width: 100, position: { top: 316, left: 974 }, },
    { src: img_04_01_01, width: 250, position: { top: 186, left: 1090 }, },
    { src: img_star_00, width: 120, position: { top: 324, left: 551 }, },
    { src: img_star_01, width: 124, position: { top: 251, left: 891 }, },
    { src: confetti_01, width: 93, position: { top: 329, left: 1376 }, },
    { src: confetti_03, width: 93, position: { top: 94, left: 595 }, },
    { src: confetti_05, width: 40, position: { top: 128, left: 699 }, },
    { src: confetti_05, width: 40, position: { top: 295, left: 1410 }, },
    { src: sparkle_01, width: 114, position: { top: 338, left: 595 }, },
  ],
  section4: [
    { src: img_04_04_02, width: 177, position: { top: 35, left: 718 }, },
    { src: img_04_04_05, width: 177, position: { top: 160, left: 1157 }, },
    { src: confetti_01, width: 110, position: { top: 81, left: 665 }, },
    { src: confetti_04, width: 44, position: { top: 182, left: 694 }, },
    { src: confetti_08, width: 114, position: { top: 312, left: 545 }, },
    { src: confetti_09, width: 114, position: { top: 110, left: 1248 }, },
    { src: rectangle_01, width: 200, position: { top: 247, left: 1030 }, },
    { src: triangle_01, width: 198, position: { top: 157, left: 893 }, },
    { src: heart_01, width: 232, position: { top: 40, left: 994 }, },
    { src: circle_01, width: 240, position: { top: 223, left: 728 }, },

  ]
};

// 이미지 배열에서 left 값을 가로 폭에 맞게 변환하는 함수
const getWidthValue = (_value: number, screenWidth: number) => {
  const baseWidth = 1920; // 이미지 기준 가로 폭
  return `${(_value * screenWidth) / baseWidth}px`;
};

// 이미지 배열에서 left 값을 가로 폭에 맞게 변환하는 함수
const getLeftValue = (_leftValue: number, screenWidth: number) => {
  const baseWidth = 1920; // 이미지 기준 가로 폭
  return `${(_leftValue * screenWidth) / baseWidth}px`;

};

const getTopValue = (_topValue: number, screenHeight: number) => {
  const baseHeight = 448; // 이미지 기준 가로 폭
  return `${((_topValue * screenHeight) / 100 * 50) / baseHeight}px`;

};

function FourthContainer({ height }: { height: number }): ReactElement {
  const tabs = [
    {
      title: <span>아이가 직접  <br />
        선택하는 관심사</span>,
      content: <>
        <Styled.tabContentsTop>
          <div style={{
            // maxWidth: "60rem",
            width: "100%",
            // height: "100%",
            margin: "auto",
            position: "relative",
          }}>
            {images.section1.map((image, index) =>
            (
              <motion.img
                key={'image_section1_' + index}
                src={image.src}
                style={{
                  width: getWidthValue(image.width, window.innerWidth),
                  position: "absolute",
                }}
                initial={{ top: -150, opacity: 0, left: getLeftValue(image.position.left, window.innerWidth) }}
                animate={{ opacity: 1, top: getTopValue(image.position.top, window.innerHeight), left: getLeftValue(image.position.left, window.innerWidth) }}
                transition={{ type: "spring", stiffness: 70, duration: 300, bounce: 0.45, delay: index * 0.01 }}
              />
            )
            )}
          </div>

        </Styled.tabContentsTop>
        <Styled.tabContentsBottom
          backgroundColor="#F5F8ED"
          activeButtonColor={Colors.green}>
          <Styled.title
            data-aos="fade-down"
            data-aos-duration="700"
            data-aos-delay="0"
            data-aos-easing="ease-in-out-cubic"
          >
            아이가 직접 선택하는 흥미관심사
          </Styled.title>
          <Styled.sub
            data-aos="fade-down"
            data-aos-duration="700"
            data-aos-delay="300"
            data-aos-easing="ease-in-out-cubic"
          >
            아이와 함께 놀이하거나 소통할 수 있는 에너지가 부족할 때, <br />
            아이들의 흥미와 관심사를 충족해주기 어려울 때, <br />
            우리 아이에게 덕질메이트를 만나게 해주세요! <br />
            자신과 비슷한 취향을 가진 친구들을 만나 소통하며, 더 많은 친구들과 깊이 있는 탐구와 성장을 경험할 수 있습니다.
            {/* 놀잇은 <b>'놀잇위크'</b>를 통해 참신한 놀이문화와 학습을 소개합니다. <br />
            또한 전문가들의 참여를 통해 아이들이 좋아하는 트렌드를 활용하여 아동의 눈높이에 맞는 참여형 영상을 제공합니다.<br />
            놀잇의 멤버로 활동하면  인기 유튜버와의 매칭, 챌린지, 장남감 도서 추천등
            다양한 혜택을 정기적으로 받을 수 있습니다. */}
          </Styled.sub>
        </Styled.tabContentsBottom >
      </>,
      backgroundColor: "#F5F8ED",
      activeButtonColor: Colors.green,
    },
    {
      title: <span>안전한 디지털<br />
        경험의 첫 시작</span>,
      content: <>
        <Styled.tabContentsTop>
          <div style={{
            // maxWidth: "60rem",
            width: "100%",
            // height: "100%",
            margin: "auto",
            position: "relative",
          }}>
            {images.section2.map((image, index) => (
              <motion.img
                key={'image_section2' + index}
                src={image.src}
                style={{
                  width: getWidthValue(image.width, window.innerWidth),
                  position: "absolute",
                }}
                initial={{ top: -150, opacity: 0, left: getLeftValue(image.position.left, window.innerWidth) }}
                animate={{ opacity: 1, top: getTopValue(image.position.top, window.innerHeight / 100 * 65), left: getLeftValue(image.position.left, window.innerWidth) }}
                transition={{ type: "spring", stiffness: 70, duration: 300, bounce: 0.45, delay: index * 0.01 }}
              />
            )
            )}
          </div>
        </Styled.tabContentsTop>

        <Styled.tabContentsBottom backgroundColor={'#F8F2ED'} activeButtonColor={Colors.orange}>
          <Styled.title
            data-aos="fade-down"
            data-aos-duration="700"
            data-aos-delay="0"
            data-aos-easing="ease-in-out-cubic"
          >
            안전한 디지털 경험의 첫 시작
          </Styled.title>
          <Styled.sub
            data-aos="fade-down"
            data-aos-duration="700"
            data-aos-delay="300"
            data-aos-easing="ease-in-out-cubic"
          >

            쉽게 중독되고, 가이드라인이 부족한 디지털 미디어 환경에서,<br />
            아이들만이 참여하는 안전한 매칭을 지원하고 활동을 모니터링 하며 소셜활동의 적응을 돕습니다.<br />
            또한 영상을 통해 개인정보 보호에 대한 내용, 비대면 환경에서 상대를 존중하는 방법, <br />
            자신감을 갖고 대화하는 방법 등을 자연스럽게 익힐 수 있도록 지원합니다.
          </Styled.sub>
          <Styled.desc>
            특허출원 : 제1-2023-035984
          </Styled.desc>
        </Styled.tabContentsBottom>
      </>,
      backgroundColor: "#F8F2ED",
      activeButtonColor: Colors.orange,
    },
    {
      title: <span>가장 트렌디한 <br />
        놀이문화 경험</span>,
      content: <>
        <Styled.tabContentsTop>
          <div style={{
            // maxWidth: "60rem",
            width: "100%",
            // height: "100%",
            margin: "auto",
            position: "relative",
          }}>
            {images.section3.map((image, index) =>
            (
              <motion.img
                key={'image_section3_' + index}
                src={image.src}
                style={{
                  width: getWidthValue(image.width, window.innerWidth),
                  position: "absolute",
                }}
                initial={{ top: -150, opacity: 0, left: getLeftValue(image.position.left, window.innerWidth) }}
                animate={{ opacity: 1, top: getTopValue(image.position.top, window.innerHeight / 100 * 65), left: getLeftValue(image.position.left, window.innerWidth) }}
                transition={{ type: "spring", stiffness: 70, duration: 300, bounce: 0.45, delay: index * 0.01 }}
              />
            )
            )}
          </div>
        </Styled.tabContentsTop>
        <Styled.tabContentsBottom backgroundColor="#F8EDED"
          activeButtonColor={Colors.red}>

          <Styled.title
            data-aos="fade-down"
            data-aos-duration="700"
            data-aos-delay="0"
            data-aos-easing="ease-in-out-cubic"
          >
            가장 트렌디한 놀이문화 경험
          </Styled.title>
          <Styled.sub
            data-aos="fade-down"
            data-aos-duration="700"
            data-aos-delay="300"
            data-aos-easing="ease-in-out-cubic"
          >
            놀잇은 ‘놀잇위크’를 통해 아이들에게 인기있는 놀이문화와 학습을 제공합니다.<br />
            전문가들의 참여를 통해, 아이들이 가장 좋아하는 캐릭터와 장난감을 활용하여 <br />
            아이들의 눈높이에 맞는 참여형 영상을 제공합니다.<br />
            놀잇 회원만이 실제 인기 유튜버와의 매칭, 챌린지, 장난감, 도서 추천 등 혜택을 받을수 있습니다.
          </Styled.sub>
        </Styled.tabContentsBottom>

      </>,
      backgroundColor: "#F8EDED",
      activeButtonColor: Colors.red,
    },
    {
      title: <span>또래 아동간의<br />
        아동발달 평가</span>,
      content: <>
        <Styled.tabContentsTop>
          <div style={{
            // maxWidth: "60rem",
            width: "100%",
            // height: "100%",
            margin: "auto",
            position: "relative",
          }}>
            {images.section4.map((image, index) => (
              <motion.img
                key={'image_section4_' + index}
                src={image.src}
                style={{
                  width: getWidthValue(image.width, window.innerWidth),
                  position: "absolute",
                }}
                initial={{ top: -150, opacity: 0, left: getLeftValue(image.position.left, window.innerWidth) }}
                animate={{ opacity: 1, top: getTopValue(image.position.top, window.innerHeight / 100 * 65), left: getLeftValue(image.position.left, window.innerWidth) }}
                transition={{ type: "spring", stiffness: 70, duration: 300, bounce: 0.45, delay: index * 0.01 }}
              />
            )
            )}
          </div>
        </Styled.tabContentsTop>
        <Styled.tabContentsBottom backgroundColor="#EDF7F8"
          activeButtonColor={Colors.blue}>
          <Styled.title
            data-aos="fade-down"
            data-aos-duration="700"
            data-aos-delay="0"
            data-aos-easing="ease-in-out-cubic"
          >
            또래 아동간의 아동발달 평가
          </Styled.title>
          <Styled.sub
            data-aos="fade-down"
            data-aos-duration="700"
            data-aos-delay="300"
            data-aos-easing="ease-in-out-cubic"
          >
            놀잇은 아이들에게 20분 동안 영상을 통한 실시간 대화를 제공하여 서로의 얼굴 표정과<br />
            목소리를 보고들으며 소통하는 경험을 할 수 있습니다. 이러한 경험은 현실 세계에서의 <br />
            대인관계와 매우 유사하여 의사소통 능력을 키우고, 자기를 표현하고 사회성을 훈련하는 데 도움을 줄 수 있습니다.<br />
            부모는 놀잇을 통해 아이가 상대와의 대화에서 어떻게 응답하고 상호작용하는지를 직접 관찰할 수 있습니다.<br />
            부모에게는 아이의 발달 상태를 관찰하고 필요한 도움을 파악하는 데이터를 제공합니다.
          </Styled.sub>
        </Styled.tabContentsBottom>
      </>,
      backgroundColor: "#EDF7F8",
      activeButtonColor: Colors.blue,
    },
  ];


  return (
    <Styled.wrap height={height}>

      <TabMenu tabs={tabs} />


      <Styled.screenWrapper>

      </Styled.screenWrapper>

    </Styled.wrap>
  );
}

export default FourthContainer;
