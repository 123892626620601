// token, user 정보 체크
import React, { ReactElement, useState } from "react";

import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { loginCheck, logout } from "../api/user";
import { useUserStore } from "../zustand/user";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import splash_video from "@assets/video/video_splash.mp4";

interface AuthProviderProps {
    children: ReactElement
};

const Styled = {
    loader: styled.div<{ is_loading: boolean }>`
        width: 100vw;
        height: 100vh;
        display: ${({ is_loading }) => is_loading ? "flex" : "none"};
        position: fixed;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        > video {
            width: 100%;
            height: 100%;
        }
    `,
};
const AuthProvider = ({ children }: AuthProviderProps) => {
    const { login, isLogin, appLoginLoading, inAppLoadingProcess } = useUserStore(state => state);
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    const authCheck = async () => {
        if (loading) return;
        try {

            inAppLoadingProcess(true);
            const { user, url, result } = await loginCheck();
            if (result) {
                login(user);
            }
            else {
                if (!isLogin && !result) {
                    logout();
                    navigate("/");
                }
            }

            inAppLoadingProcess(false);
        } catch (error: any) {
            inAppLoadingProcess(false);
        }
    }

    React.useEffect(() => {

        const token = localStorage.getItem('norit_token');

        if (!token) {
            return;
        }
        authCheck();

    }, [loading]);
    
    if (loading || appLoginLoading) {

        return null;
        
        return (
            <Styled.loader is_loading={appLoginLoading} >
                <video loop muted>
                    <source src={splash_video} type="video/mp4" />
                </video>
            </Styled.loader>
        )
    };

    return (
        <>
            {<Styled.loader is_loading={appLoginLoading}  >
                <video autoPlay loop muted>
                    <source src={splash_video} type="video/mp4" />
                </video>
            </Styled.loader>}
            {children}
        </>
    );
}

export default AuthProvider;

interface LoginCheckerProps {
    showNotLogin: boolean;
    children: ReactElement
};
export const LoginChecker = ({ children, showNotLogin = false }: LoginCheckerProps) => {
    const { isLogin } = useUserStore(state => state);

    const _isLogin = showNotLogin ? !isLogin : isLogin;

    // TODO :: 로그인 방법 소셜 -> 핸드폰 인증으로 변경되어 로그인 기능 막아둠
    // 핸드폰 인증 방식으로 로그인 개선 필요 2023-10-27 @ginger.lim
    return null;
    return _isLogin ? (children) : null;
}
