import { motion }  from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import React from "react";

import useAnswerStore, { quiz_list } from "../../shared/zustand/mbti";
import { typeSelect } from "../../shared/typeSelect";

const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };
const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible,
};
const Quiz = () => {
  const navigate = useNavigate();
  const is_mobile = window.innerWidth < 400;
  const [progress, setProgress] = React.useState({ percent: 0, quiz_num: 0 });
  const { addAnswer, answer_count, answer_list } = useAnswerStore();

  return (
    <motion.article
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      style={{
        maxWidth: "400px",
        width: "100%",
        minHeight: "100vh",
        margin: "10rem auto",
        overflowY: "auto",
        boxShadow: is_mobile ? "none" : "0 0 10px rgba(0,0,0,0.2)",
      }}
    >
      <motion.div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
          margin: "5rem auto",
          minHeight: "400px",
          alignItems: "center",
          //   justifyContent: "center",
          gap: "2rem",
        }}
      >
        <div className="progress-div" style={{ width: "100%" }}>
          <div
            className="progress"
            style={{ width: `${progress.percent}%` }}
          ></div>
        </div>

        <motion.div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          <motion.h1
            style={{
              color: "#385B00",
            }}
            variants={itemVariants}
          >
            Q{progress.quiz_num + 1}.
          </motion.h1>
          <motion.p style={{ marginBottom: "1rem" }}>
            {quiz_list[progress.quiz_num].q}
          </motion.p>
        </motion.div>

        <motion.button
          style={{
            width: "100%",
            padding: "0.5rem 1rem",
            borderRadius: "10rem",
            border: "1px solid #81AF39",
            backgroundColor: "#fff",
            fontSize: "1rem",
            minHeight: "5.5rem",
          }}
          whileHover={{
            scale: 0.9,
          }}
          whileTap={{
            scale: 0.9,
          }}
          onClick={() => {
            addAnswer(quiz_list[progress.quiz_num].a[0]);
            if (progress.quiz_num === quiz_list.length - 1) {
              navigate(`/mbti/${typeSelect(answer_count)}`);
            } else {
              setProgress({
                percent: ((progress.quiz_num + 1) / quiz_list.length) * 100,
                quiz_num: progress.quiz_num + 1,
              });
            }
          }}
        >
          {quiz_list[progress.quiz_num].a[0].answer}
        </motion.button>
        <motion.button
          style={{
            width: "100%",
            padding: "0.5rem 1rem",
            borderRadius: "10rem",
            border: "1px solid #81AF39",
            backgroundColor: "#fff",
            fontSize: "1rem",
            minHeight: "5.5rem",
          }}
          whileHover={{
            scale: 0.9,
          }}
          whileTap={{
            scale: 0.9,
          }}
          onClick={() => {
            addAnswer(quiz_list[progress.quiz_num].a[1]);
            if (progress.quiz_num === quiz_list.length - 1) {
              navigate(`/mbti/${typeSelect(answer_count)}`);
            } else {
              setProgress({
                percent: ((progress.quiz_num + 1) * 100) / quiz_list.length,
                quiz_num: progress.quiz_num + 1,
              });
            }
          }}
        >
          {quiz_list[progress.quiz_num].a[1].answer}
        </motion.button>
      </motion.div>
    </motion.article>
  );
};

export default Quiz;
