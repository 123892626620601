import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import { userUpdate } from "../../shared/api/user";
import { LoginFormInputs } from "./userInputTypes";
import { useUserStore } from "../../shared/zustand/user";


const sido_data = [
  "",
  "서울특별시",
  "인천광역시",
  "대전광역시",
  "광주광역시",
  "대구광역시",
  "울산광역시",
  "부산광역시",
  "경기도",
  "강원도",
  "충청북도",
  "충청남도",
  "전라북도",
  "전라남도",
  "경상북도",
  "경상남도",
  "제주도"
];

const Styled = {
  button: styled.button`
    width: 100%;
    max-width: 315px;
    margin: 0 auto;
    background-color: #6AA3FF;
    color: white;
    font-size: 1.8rem;
    padding: 1.6rem;
    border-radius: 4rem;
    box-sizing: border-box;
    `,
  socialBtn: styled.button`
    max-width: 315px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    & > img {
      width: 100%;
    }
  `,
  rows: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;  
    align-items: center;
    `,
  label: styled.label`
    font-size: 1.8rem;
    color: #999999;
    margin-bottom: 1.6rem;
  `,
  form: styled.form`
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  `,
  inputWrap: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > * {
      margin-bottom: 3.2rem;
    }
  `,
  input: styled.input`
    width: 100%;
    border: none;
    color: black;
    font-size: 2.4rem;
    padding-bottom: 0.6rem;
    border-bottom: 2px solid #eaeaea;

    &::placeholder {
      font-size: 2.4rem;
      color: #e8e8e8;
    }

    &:focus {
      border-bottom: 2px solid #888;
    }
  `,

  select: styled.select`
    width: 100%;
    border: none;
    color: black;
    font-size: 2.4rem;
    padding-bottom: 0.6rem;
    border-bottom: 2px solid #eaeaea;
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::placeholder {
      font-size: 2.4rem;
      color: #e8e8e8;
    }

    &:focus {
      border-bottom: 2px solid #888;
    }

    &:focus-visible {
      border : none;
      border-bottom: 2px solid #888;
    }
  `,
  checkboxWrap: styled.div`
    display: flex;
    align-items: center;
    &>p{
      font-size: 1.8rem;
    }
  `,
  checkbox: styled.input`
    width: 2.4rem;
    height: 2.4rem;
    border: 1px solid #888;
    border-radius: 0.4rem;
    margin-right: 1.6rem;
    &:after, &:checked {
      text-align: center;
      content:'✔';
      font-size: 2rem;
      color: #fff;
      position: relative;
        left: 0.1rem;
        top:0;
    }
    &:checked {
      background-color: #6AA3FF;
      border: 1px solid #6AA3FF;
    }
  `,
};

const SignupForm = () => {
  const { register, handleSubmit } = useForm<LoginFormInputs>();
  const navigate = useNavigate();
  const {update} = useUserStore(state => state);

  
  const onSubmit = async (data: LoginFormInputs) => {
    try {

      const res = await userUpdate(data);

      if(res.result){
        update(res.user);
        navigate(res.url);
      }

      // TODO :: 앱에 자체 로그인 생기면 그때 추가,,,
      // await signInWithEmailAndPassword(auth, data.email, data.password);
      
    } catch (error) {
      // console.log(error);
    }
  };

  return (

    <Styled.form onSubmit={handleSubmit(onSubmit)}>
      <Styled.rows>
        <Styled.inputWrap>
          <Styled.label htmlFor="name">name</Styled.label>
          <Styled.input
            id="name"
            type="text"
            placeholder="이름을 입력해주세요"
            {...register("name", { required: true })}
          />
        </Styled.inputWrap>

        <Styled.inputWrap>
          <Styled.label htmlFor="phone">phone</Styled.label>
          <Styled.input
            id="phone"
            type="text"
            placeholder="-를 제외한 핸드폰 번호를 입력해주세요"
            {...register("phone", { required: true })}
          />
        </Styled.inputWrap>

        <Styled.inputWrap>
          <Styled.label htmlFor="sido">지역</Styled.label>
          <Styled.select
            id="sido"
            placeholder="지역을 입력해주세요"
            {...register("sido", { required: true })}
          >
            {sido_data.map((sido, idx) => {
              return (
                <option key={idx} value={sido}>
                  {sido}
                </option>
              );
            })
            }
          </Styled.select>
          
          <Styled.checkboxWrap>
            <Styled.checkbox
              type="checkbox"
              id="marketingEnabled"
              {...register("marketingEnabled", { required: false })} 
            /> <p>마케팅 정보 수신 허용</p>          
          </Styled.checkboxWrap>
          


        </Styled.inputWrap>



        <Styled.button type="submit">회원가입 완료하기</Styled.button>

      </Styled.rows>
    </Styled.form>
  );
};

export default SignupForm;
