import React, { ReactElement, useEffect } from "react";
import styled from "styled-components";
import img_02_02 from "@assets/image/img_02_02@2x.png";
import { motion } from "framer-motion";

import img_bg_03_right from "@assets/images/img_bg_03_right.png";
import img_bg_03_left from "@assets/images/img_bg_03_left.png";
const Styled = {
  wrap: styled.div`
    box-sizing: border-box;
    padding: 0rem;
    background-color: #DEF1C5;
    height: calc(100vh - 8rem);
    /* height: auto; */
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    /* overflow-y: hidden; */
  `,
  title: styled.div`
    color: black;
    font-size: 2.6rem;
    line-height: 3.8rem;
    font-weight: 800;
    padding-top: 6.6rem;
    text-align: center;
  `,
  sub: styled.div`
    color: black;
    font-size: 1.6rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 2.0rem;
    text-align: center;
  `,
  subTitle: styled.div`
   color: black;
   font-size: 1.4rem;
   margin-top: 1rem;
   margin-bottom: 1rem;
   line-height: 2.2rem;
   padding-top: 6.6rem;
   text-align: center;
 `,
  screen: styled.div`
    background: url(${img_02_02}) no-repeat center center/contain;
    width: 65%;
    height: 65%;
    position: absolute;
    z-index: 1;
    bottom: -4.5rem;
  `,

  videoWrapper: styled.div`
    width: 100%;
    margin-top: 2.6rem;
    position: relative;
    height: 40vh;
  `,
};


function MThirdContainer(): ReactElement {
  return (
    <Styled.wrap>
      <Styled.title
        data-aos="fade-down"
        data-aos-duration="700"
        data-aos-delay="0"
        data-aos-easing="ease-in-out-cubic"
      >
        우리 아이는 소셜 활동을 <br />
        어떻게 시작할까요?
      </Styled.title>
      <Styled.sub
        data-aos="fade-down"
        data-aos-duration="700"
        data-aos-delay="0"
        data-aos-easing="ease-in-out-cubic"
      >
        실제 아이들이 놀잇에서 소통하는 모습입니다
      </Styled.sub>
      <Styled.videoWrapper>

        <video style={{
          width: "100vw",
          height: "auto",
          margin: "0 auto",
          position: "absolute",
          zIndex: 1,
          left: 0,
        }} controls>
          <source src="https://norit-public-storage.s3.ap-northeast-2.amazonaws.com/landing/%E1%84%82%E1%85%A9%E1%86%AF%E1%84%8B%E1%85%B5%E1%86%BAWEB_%E1%84%80%E1%85%A1%E1%84%85%E1%85%A9%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A1%E1%86%BC+%E1%84%91%E1%85%A7%E1%86%AB%E1%84%8C%E1%85%B5%E1%86%B8(2).mp4" type="video/mp4" />
        </video>
        <motion.img src={img_bg_03_left} alt="bg" style={{
          position: "absolute",
          width: "50%",
          top: "35vw",
          left: 0,
        }} initial={{ opacity: 0, }}
          whileInView={{ opacity: 1, }}
          transition={{ type: "spring", stiffness: 70, duration: 300, bounce: 0.25 }} />

        <motion.img src={img_bg_03_right} alt="bg" style={{
          width: "50%",
          position: "absolute",
          top: "50vw",
          right: 0
        }}
          initial={{ opacity: 0, }}
          whileInView={{ opacity: 1, }}
          transition={{ type: "spring", stiffness: 70, duration: 300, bounce: 0.25 }} />
      </Styled.videoWrapper>
    </Styled.wrap>
  );
}

export default MThirdContainer;
