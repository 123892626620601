import { Timestamp } from "firebase/firestore/lite";
import { create } from "zustand";


interface UserState {
    id: string;
    phone: string;
    name: string;
    isLogin: boolean;
    fcmToken: string;
    sido: string;
    marketingEnabled: boolean;
    marketingAgreeAt: Timestamp;
    isPayed: boolean;
    isConfirmed: boolean;
    appLoginLoading: boolean;
    login: (user: UserState) => void;
    logout: () => void;
    appRegister: (user: UserState) => void;
    update: (user: UserState) => void;
    inAppLoadingProcess: (inLoginProcess: boolean) => void;
}

export const useUserStore = create<UserState>(set => ({
    id: "",
    phone: "",
    name: "",
    isLogin: false,
    fcmToken: "",
    sido: "",
    marketingEnabled: false,
    marketingAgreeAt: Timestamp.now(),
    isPayed: false,
    isConfirmed: false,
    appLoginLoading: false,
    inAppLoadingProcess: (inLoginProcess : boolean) => {
        set({
            appLoginLoading: inLoginProcess,
        })
    },
    update: (user: UserState) => {
        set({
            isLogin: true,
            id: user.id,
            name: user.name,
            phone: user.phone,
            sido: user.sido,
            marketingEnabled: user.marketingEnabled,
            marketingAgreeAt: user.marketingEnabled? Timestamp.now() : user.marketingAgreeAt,
        });
    },
    appRegister: (user: UserState) => {
        set({
            id: user.id,
            name: user.name,
            phone: user.phone,
            sido: user.sido,
            fcmToken: user.fcmToken,
            isLogin: true,
            marketingEnabled: user.marketingEnabled,
            marketingAgreeAt: user.marketingAgreeAt,
        })
    },
    login: (user: UserState) => {
        set({
            id: user.id,
            name: user.name,
            phone: user.phone,
            sido: user.sido,
            fcmToken: user.fcmToken,
            marketingEnabled: user.marketingEnabled,
            marketingAgreeAt: user.marketingAgreeAt,
            isLogin: true,
        });
    },
    logout: () => {
        
        set({
            id: "",
            phone: "",
            name: "",
            isLogin: false,
            fcmToken: "",
            sido: "",
            marketingEnabled: false,
            marketingAgreeAt: Timestamp.now(),
        });
    },
}));
