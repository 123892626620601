import React from "react";
import { useForm } from "react-hook-form";
import { auth } from "../../shared/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import styled from "styled-components";

import btnNaver from "@assets/image/btn_naver.svg";
import btnGoogle from "@assets/image/btn_google.svg";
import btnApple from "@assets/image/btn_apple.svg";
import { useNavigate } from "react-router-dom";

// import NaverLogin from '@dohyeon/react-naver-login';
import { NAVER_CALLBACK_URL, NAVER_CLIENT_ID, NAVER_CLIENT_SCRET, appleLogin, googleLogin, naverLogin } from "../../shared/api/user";
import { useUserStore } from "../../shared/zustand/user";

// type LoginFormInputs = {
//   email: string;
//   password: string;
// };

const Styled = {
  button: styled.button`
    width: 100%;
    max-width: 315px;
    margin: 0 auto;
    background-color: #6AA3FF;
    color: white;
    font-size: 1.8rem;
    padding: 1.6rem;
    border-radius: 4rem;
    box-sizing: border-box;
    `,
  socialBtn: styled.div`
    max-width: 315px;
    width: 100%;
    margin: 0 auto 2.4rem auto;
    padding: 0;
    & > img {
      width: 100%;
    }
  `,
  rows: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    `,
  label: styled.label`
    font-size: 1.8rem;
    color: #999999;
    margin-bottom: 1.6rem;
  `,
  form: styled.form`
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  `,
  inputWrap: styled.div`
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-bottom: 5.2rem;
    }
  `,
  input: styled.input`
    border: none;
    color: black;
    font-size: 2.4rem;
    padding-bottom: 0.6rem;
    border-bottom: 2px solid #eaeaea;

    &::placeholder {
      font-size: 2.4rem;
      color: #e8e8e8;
    }

    &:focus {
      border-bottom: 2px solid #888;
    }
  `,
};

// TODO ::: 앱에서 이메일/패스워드 로그인 붙이고 여기도 붙여야함

const LoginForm = () => {
  // const { register, handleSubmit } = useForm<LoginFormInputs>();
  const navigate = useNavigate();
  const { login } = useUserStore(state => state);

  // const onSubmit = async (data: LoginFormInputs) => {

  //   try {
  //     await signInWithEmailAndPassword(auth, data.email, data.password);
  //   } catch (error) {
  //     console.log(error);

  //   }
  // };

  const _naverLogin = async (code: string) => {
    const { url, user, result } = await naverLogin(code);
    if (result) {
      login(user);
    }
    window.opener.location.href = `${process.env.NODE_ENV !== 'production' ? "http://localhost:3000" : "https://norit.io"}/mypage/default`;
    window.close();
    // navigate(url);
  }




  React.useEffect(() => {
    // const auth = getAuth();
    // const unregisterAuthObserver = auth.onAuthStateChanged((firebaseUser) => {
    //   if (firebaseUser) {
    //     console.log("user logged in");
    //     firebaseUser.getIdToken().then((token) => {
    //       localStorage.setItem('norit_token', token);

    //     });

    //   } else {
    //     console.log("user logged out");
    //   }
    // });

    const naver = (window as any).naver;
    let naverLogin: any;

    const login = () => {
      naverLogin = new naver.LoginWithNaverId({
        clientId: NAVER_CLIENT_ID, // ClientID
        clientSecret: NAVER_CLIENT_SCRET, // ClientSecret
        callbackUrl: NAVER_CALLBACK_URL, // Callback URL
        isPopup: true, // 팝업 형태로 인증 여부
        loginButton: {
          color: 'green', // 색상
          type: 3, // 버튼 크기
          height: '60' // 버튼 높이
        }, // 로그인 버튼 설정
      })

      naverLogin.init();
    }
    login();
    const location = window.location.href.split('=')[1];

    if (location) {
      _naverLogin(location.split('&')[0]);
    }


    return () => {
      // unregisterAuthObserver();
    };
  }, []);


  return (

    <Styled.form>
      <Styled.rows>
        <Styled.rows>

          <Styled.socialBtn onClick={async () => {
            const { url, result, user } = await appleLogin();
            if (result) {
              login(user);
            }
            navigate('/mypage/default');
          }}>
            <img src={btnApple} />
          </Styled.socialBtn>
          <div id="naverIdLogin" style={{ display: "none" }}></div>
          <Styled.socialBtn onClick={async () => {
            if (document && document.getElementById('naverIdLogin_loginButton') && window !== undefined) {
              const loginBtn = document.getElementById('naverIdLogin_loginButton');
              loginBtn?.click();
              loginBtn?.click();
            }
          }}>
            <img src={btnNaver} />
          </Styled.socialBtn>


          <Styled.socialBtn onClick={async () => {
            const { url, user, result } = await googleLogin();
            if (result) {
              login(user);
            }
            navigate('/mypage/default');

          }}><img src={btnGoogle} /></Styled.socialBtn>
        </Styled.rows>
        {/* <hr />

        <p>아직 아이디가 없나요?</p>

        <Styled.button onClick={() => {
          navigate("/signup");
        }}>회원가입 하러가기</Styled.button> */}

      </Styled.rows>
    </Styled.form>
  );
};

export default LoginForm;
