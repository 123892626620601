import React, { ReactElement, useEffect } from "react";
import styled from "styled-components";
import img_02_03 from "@assets/image/img_02_03@2x.png";
import { motion } from "framer-motion";


import { Tab, TabMenu } from "../../shared/components/MTabs";
import { Colors } from "../../enums/Colors";


import img_04_01_01 from "@assets/images/fourth/img_04_01_01.svg";
import img_04_01_02 from "@assets/images/fourth/img_04_01_02.svg";
import img_04_01_03 from "@assets/images/fourth/img_04_01_03.svg";
import img_04_01_04 from "@assets/images/fourth/img_04_01_04.svg";
import img_04_01_05 from "@assets/images/fourth/img_04_01_05.svg";
import img_04_01_06 from "@assets/images/fourth/img_04_01_06.svg";
import img_04_02_01 from "@assets/images/fourth/img_04_02_01.svg";
import img_04_02_02 from "@assets/images/fourth/img_04_02_02.svg";
import img_04_03_01 from "@assets/images/fourth/img_04_03_01.svg";
import img_04_03_02 from "@assets/images/fourth/img_04_03_02.svg";
import img_04_03_03 from "@assets/images/fourth/img_04_03_03.svg";
import img_04_03_04 from "@assets/images/fourth/img_04_03_04.svg";
import img_04_03_05 from "@assets/images/fourth/img_04_03_05.svg";
import img_04_03_06 from "@assets/images/fourth/img_04_03_06.svg";
import img_04_04_02 from "@assets/images/fourth/img_04_04_02.svg";
import img_04_04_05 from "@assets/images/fourth/img_04_04_05.svg";
import img_star_00 from "@assets/images/fourth/img_star_00.svg";
import img_star_01 from "@assets/images/fourth/img_star_01.svg";
import rectangle_01 from "@assets/images/fourth/img_04_04_02.svg";
import triangle_01 from "@assets/images/fourth/img_04_04_03.svg";
import sparkle_01 from "@assets/images/fourth/sparkle_01.svg";
import sparkle_02 from "@assets/images/fourth/sparkle_02.svg";
import circle_01 from "@assets/images/fourth/img_04_04_01.svg";
import confetti_01 from "@assets/images/fourth/confetti_01.svg";

import confetti_03 from "@assets/images/fourth/confetti_03.svg";
import confetti_04 from "@assets/images/fourth/confetti_04.svg";
import confetti_05 from "@assets/images/fourth/confetti_05.svg";
import confetti_06 from "@assets/images/fourth/confetti_06.svg";
import confetti_07 from "@assets/images/fourth/confetti_07.svg";
import confetti_08 from "@assets/images/fourth/confetti_08.svg";
import confetti_09 from "@assets/images/fourth/confetti_09.svg";
import heart_01 from "@assets/images/fourth/img_04_04_04.svg";
import _ from "lodash";

const Styled = {
  wrap: styled.div`
    box-sizing: border-box;
    padding: 0rem 0rem 0rem 0rem;
    /* padding: 0 4rem; */
    background-color: white;
    height: calc(100vh - 3rem);
    display: flex;
    align-items: center;
    flex-direction: column;
    /* overflow: hidden; */
    position: relative;
  `,
  title: styled.div`
    color: black;
    font-size: 2.6rem;
    line-height: 3.8rem;
    font-weight: 800;
    padding-top: 2.6rem;
    text-align: center;
    position: relative;
  `,
  sub: styled.div`
    color: black;
    font-size: 1.6rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 2.6rem;
    text-align: center;
    padding: 0 2rem;
    & b {
      font-weight: 800;
    }
  `,
  screen: styled.div`
    background: url(${img_02_03}) no-repeat center center/contain;
    width: 65%;
    height: 65%;
    z-index: 1;
    position: absolute;
    bottom: -4.5rem;
  `,

  tabContentsTop: styled.div`
    height: 55%;
    width: 100%;
    /* padding: 5rem 0; */
  `,
  tabContentsBottom: styled.div<{ backgroundColor: string, activeButtonColor: string }>`
    position: relative;
    z-index: 1;
    height: 50%;
    width: 100%;
    margin-top: -10%;
    box-sizing: border-box;
    padding: 2.3rem 0 5rem 0;
    box-shadow: 0 -4px 8px 0px ${({ activeButtonColor }) => activeButtonColor + '10'};
    background: ${({ backgroundColor }) => backgroundColor};
  `,

  desc: styled.div`
    color: #00000044;
    font-size: 1.2rem;
  `,
};


const images = {
  section1: [
    { src: confetti_06, width: "8.3rem", position: { top: "12rem", left: "69.2rem" }, },
    { src: img_04_01_03, width: "30rem", position: { top: "4.7rem", left: "76.7rem" }, },
    { src: img_04_01_02, width: "15rem", position: { top: "8.8rem", left: "71.6rem" }, },
    { src: img_04_01_04, width: "18.5rem", position: { top: "17.6rem", left: "54.2rem" }, },
    { src: img_04_01_06, width: "25.9rem", position: { top: "5.59rem", left: "104.3rem" }, },
    { src: img_04_01_05, width: "10rem", position: { top: "31.6rem", left: "87.4rem" }, },
    { src: img_04_01_01, width: "25rem", position: { top: "18.6rem", left: "99rem" }, },
    { src: img_star_00, width: "11rem", position: { top: "32.4rem", left: "45.1rem" }, },
    { src: img_star_01, width: "11rem", position: { top: "25.1rem", left: "79.1rem" }, },
    { src: confetti_01, width: "8.3rem", position: { top: "32.9rem", left: "127.6rem" }, },
    { src: confetti_03, width: "8.3rem", position: { top: "9.4rem", left: "49.5rem" }, },
    { src: confetti_05, width: "3rem", position: { top: "12.8rem", left: "59.9rem" }, },
    { src: confetti_05, width: "3rem", position: { top: "29.5rem", left: "131rem" }, },
    { src: sparkle_01, width: "9.4rem", position: { top: "33.8rem", left: "49.5rem" }, },
  ],
  section2: [
    { src: sparkle_02, width: "7.6rem", position: { top: "37.1rem", left: "86rem" }, },
    { src: sparkle_01, width: "7.6rem", position: { top: "9rem", left: "124.4rem" }, },
    { src: img_04_02_01, width: "25.8rem", position: { top: "8.4rem", left: "52.7rem" }, },
    { src: img_04_02_02, width: "26.7rem", position: { top: "13.8rem", left: "95.7rem" }, },
    { src: img_star_00, width: "10rem", position: { top: "6.4rem", left: "119.9rem" }, },
    { src: img_star_01, width: "15rem", position: { top: "26.7rem", left: "43.8rem" }, },
    { src: confetti_01, width: "10rem", position: { top: "9rem", left: "90.4rem" }, },
    { src: confetti_04, width: "3rem", position: { top: "19.2rem", left: "93.3rem" }, },
    { src: confetti_08, width: "10rem", position: { top: "24.5rem", left: "78.4rem" }, },
  ],
  section3: [
    { src: img_04_03_01, width: "25.6rem", position: { top: "2.7rem", left: "66.2rem" }, },
    { src: img_04_03_02, width: "32.9rem", position: { top: "17rem", left: "49.7rem" }, },
    { src: img_04_03_03, width: "17.8rem", position: { top: "19.5rem", left: "110rem" }, },
    { src: img_04_03_04, width: "29.3rem", position: { top: "8.9rem", left: "95.5rem" }, },
    { src: img_04_03_05, width: "10.2rem", position: { top: "14.3rem", left: "126.3rem" }, },
    { src: img_04_03_06, width: "14.4rem", position: { top: "33.3rem", left: "81.6rem" }, },
    { src: confetti_01, width: "10rem", position: { top: "16.9rem", left: "41.5rem" }, },
    { src: confetti_04, width: "3.4rem", position: { top: "27rem", left: "44.5rem" }, },
    { src: confetti_07, width: "10.2rem", position: { top: "6.5rem", left: "113.1rem" }, },
    { src: img_star_00, width: "11rem", position: { top: "5.9rem", left: "60.4rem" }, },
    // { src: heart_01, width: "15%", position: { top: "60%", left: "60%" }, },
    // { src: confetti_01, width: "15%", position: { top: "60%", left: "60%" }, },
  ],
  section4: [
    { src: img_04_04_02, width: "17.7rem", position: { top: "3.5rem", left: "71.8rem" }, },
    { src: img_04_04_05, width: "17.7rem", position: { top: "16rem", left: "115.7rem" }, },
    { src: confetti_01, width: "10rem", position: { top: "8.1rem", left: "66.5rem" }, },
    { src: confetti_04, width: "3.4rem", position: { top: "18.2rem", left: "69.4rem" }, },
    { src: confetti_08, width: "10.2rem", position: { top: "31.2rem", left: "54.5rem" }, },
    { src: confetti_09, width: "10.2rem", position: { top: "11rem", left: "124.8rem" }, },
    { src: rectangle_01, width: "20rem", position: { top: "24.7rem", left: "103rem" }, },
    { src: triangle_01, width: "19.8rem", position: { top: "15.7rem", left: "89.3rem" }, },
    { src: heart_01, width: "23.2rem", position: { top: "4rem", left: "99.4rem" }, },
    { src: circle_01, width: "20.6rem", position: { top: "18.3rem", left: "68.7rem" }, },

  ]
};

// 이미지 배열에서 left 값을 가로 폭에 맞게 변환하는 함수
const getLeftValue = (_leftValue: string, screenWidth: number) => {
  const leftValue = Number(_leftValue.replace("rem", ""));
  const baseWidth = 88; // 이미지 기준 가로 폭
  const convertedValue = (leftValue * screenWidth) / baseWidth;
  // return `calc(${convertedValue}px)`;
  return `calc(${convertedValue}px - 55%)`;
};

const getTopValue = (_topValue: string, screenHeight: number) => {
  const topValue = Number(_topValue.replace("rem", ""));
  const baseHeight = 100;
  const convertedValue = (topValue * screenHeight) / baseHeight;
  // return `calc(${convertedValue}px)`;
  return `calc(${convertedValue}px + 50%)`;
};

function MFourthContainer(): ReactElement {
  const tabs = [
    {
      title: <span> 아이가 직접 <br />
        선택하는 관심사</span>,
      content: <>
        <Styled.tabContentsTop>

          <div style={{
            // maxWidth: "60rem",
            width: "100%",
            // height: "100%",
            margin: "auto",
            position: "relative",
            padding: "0rem 3.4rem",
          }}>
            <Styled.title
              data-aos="fade-down"
              data-aos-duration="700"
              data-aos-delay="0"
              data-aos-easing="ease-in-out-cubic"
            >
              아이가 직접 선택하는 흥미관심사
            </Styled.title>
            {images.section3.map((image, index) =>
            (
              <motion.img
                key={'image_section3_' + index}
                src={image.src}
                style={{
                  width: `calc(${image.width}* 0.7)`,
                  position: "absolute",
                }}
                initial={{ top: -50, opacity: 0, left: getLeftValue(image.position.left, window.innerWidth) }}
                animate={{ opacity: 1, top: getTopValue(image.position.top, window.innerHeight), left: getLeftValue(image.position.left, window.innerWidth) }}
                transition={{ type: "spring", stiffness: 70, duration: 300, bounce: 0.45, delay: index * 0.01 }}
              />
            )
            )}
          </div>
        </Styled.tabContentsTop>
        <Styled.tabContentsBottom backgroundColor="#F5F8ED"
          activeButtonColor={Colors.green}>

          <Styled.sub
          // data-aos="fade-down"
          // data-aos-duration="700"
          // data-aos-delay="300"
          // data-aos-easing="ease-in-out-cubic"
          >
            아이와 함께 놀이하거나 소통할 수 있는 에너지가 부족할 때, 아이들의 흥미와 관심사를 충족해주기 어려울 때, 
            우리 아이에게 덕질메이트를 만나게 해주세요! 자신과 비슷한 취향을 가진 친구들을 만나 소통하며, 
            더 많은 친구들과 깊이 있는 탐구와 성장을 경험할 수 있습니다.
            
          </Styled.sub>
        </Styled.tabContentsBottom>

      </>,
      backgroundColor: "#F5F8ED",
      activeButtonColor: Colors.green,
    },
    {
      title: <span>안전한 디지털<br />
        경험의 첫 시작</span>,
      content: <>
        <Styled.tabContentsTop>

          <div style={{
            // maxWidth: "60rem",
            width: "100%",
            // height: "100%",
            margin: "auto",
            position: "relative",
            padding: "0rem 3.4rem",
          }}>
            <Styled.title
              data-aos="fade-down"
              data-aos-duration="700"
              data-aos-delay="0"
              data-aos-easing="ease-in-out-cubic"
            >
              안전한 디지털 경험의 첫 시작 
            </Styled.title>
            {images.section2.map((image, index) => (
              <motion.img
                key={'image_section2' + index}
                src={image.src}
                style={{
                  width: `calc(${image.width}* 0.7)`,
                  position: "absolute",
                }}
                initial={{ top: -50, opacity: 0, left: getLeftValue(image.position.left, window.innerWidth) }}
                animate={{ opacity: 1, top: getTopValue(image.position.top, window.innerHeight), left: getLeftValue(image.position.left, window.innerWidth) }}
                transition={{ type: "spring", stiffness: 70, duration: 300, bounce: 0.45, delay: index * 0.01 }}
              />
            )
            )}
          </div>
        </Styled.tabContentsTop>

        <Styled.tabContentsBottom backgroundColor={'#F8F2ED'} activeButtonColor={Colors.orange}>

          <Styled.sub
          // data-aos="fade-down"
          // data-aos-duration="700"
          // data-aos-delay="300"
          // data-aos-easing="ease-in-out-cubic"
          >
            쉽게 중독되고, 가이드라인이 부족한 디지털 미디어 환경에서, 
            아이들만이 참여하는 안전한 매칭을 지원하고 활동을 모니터링 하며 소셜활동의 적응을 돕습니다. 
            또한 영상을 통해 개인정보 보호에 대한 내용, 비대면 환경에서 상대를 존중하는 방법, 
            자신감을 갖고 대화하는 방법 등을 자연스럽게 익힐 수 있도록 지원합니다.
          </Styled.sub>
          <Styled.desc>
            특허출원 : 제1-2023-035984
          </Styled.desc>
        </Styled.tabContentsBottom>
      </>,
      backgroundColor: "#F8F2ED",
      activeButtonColor: Colors.orange,
    },

    {
      title: <span>가장 트렌디한 <br />
        놀이문화 경험</span>,
      content: <>
        <Styled.tabContentsTop>
          <div style={{
            // maxWidth: "60rem",
            width: "100%",
            // height: "100%",
            margin: "auto",
            position: "relative",
            padding: "0rem 3.4rem",
          }}>
            <Styled.title
              data-aos="fade-down"
              data-aos-duration="700"
              data-aos-delay="0"
              data-aos-easing="ease-in-out-cubic"
            >
              가장 트렌디한 놀이문화 경험
            </Styled.title>
            {images.section1.map((image, index) =>
            (
              <motion.img
                key={'image_section1_' + index}
                src={image.src}
                style={{
                  width: `calc(${image.width}* 0.7)`,
                  position: "absolute",
                }}
                initial={{ top: -50, opacity: 0, left: getLeftValue(image.position.left, window.innerWidth) }}
                animate={{ opacity: 1, top: getTopValue(image.position.top, window.innerHeight), left: getLeftValue(image.position.left, window.innerWidth) }}
                transition={{ type: "spring", stiffness: 70, duration: 300, bounce: 0.45, delay: index * 0.01 }}
              />
            )
            )}
          </div>

        </Styled.tabContentsTop>
        <Styled.tabContentsBottom
          backgroundColor="#F8EDED"
          activeButtonColor={Colors.red}>

          <Styled.sub
          // data-aos="fade-down"
          // data-aos-duration="700"
          // data-aos-delay="300"
          // data-aos-easing="ease-in-out-cubic"
          >
            놀잇은 ‘놀잇위크’를 통해 아이들에게 인기있는 놀이문화와 학습을 제공합니다. 
            전문가들의 참여를 통해, 아이들이 가장 좋아하는 캐릭터와 장난감을 활용하여 아이들의 눈높이에 맞는 참여형 영상을 제공합니다. 
            놀잇 회원만이 실제 인기 유튜버와의 매칭, 챌린지, 장난감, 도서 추천 등 혜택을 받을수 있습니다.
          </Styled.sub>
        </Styled.tabContentsBottom >
      </>,
      backgroundColor: "#F8EDED",
      activeButtonColor: Colors.red,
    },
    {
      title: <span>또래 아동간의 <br />
         아동발달 평가</span>,
      content: <>
        <Styled.tabContentsTop>

          <div style={{
            // maxWidth: "60rem",
            width: "100%",
            // height: "100%",
            margin: "auto",
            position: "relative",
            padding: "0rem 3.4rem",
          }}>
            <Styled.title
              data-aos="fade-down"
              data-aos-duration="700"
              data-aos-delay="0"
              data-aos-easing="ease-in-out-cubic"
            >
              또래 아동간의 아동발달 평가
            </Styled.title>
            {images.section4.map((image, index) => (
              <motion.img
                key={'image_section4_' + index}
                src={image.src}
                style={{
                  width: `calc(${image.width}* 0.7)`,
                  position: "absolute",
                }}
                initial={{ top: -50, opacity: 0, left: getLeftValue(image.position.left, window.innerWidth) }}
                animate={{ opacity: 1, top: getTopValue(image.position.top, window.innerHeight), left: getLeftValue(image.position.left, window.innerWidth) }}
                transition={{ type: "spring", stiffness: 70, duration: 300, bounce: 0.45, delay: index * 0.01 }}
              />
            )
            )}
          </div>
        </Styled.tabContentsTop>
        <Styled.tabContentsBottom backgroundColor="#EDF7F8"
          activeButtonColor={Colors.blue}>

          <Styled.sub
          // data-aos="fade-down"
          // data-aos-duration="700"
          // data-aos-delay="300"
          // data-aos-easing="ease-in-out-cubic"
          >
            놀잇은 아이들에게 20분 동안 영상을 통한 실시간 대화를 제공하여 
            서로의 얼굴 표정과 목소리를 보고들으며 소통하는 경험을 할 수 있습니다. 
            이러한 경험은 현실 세계에서의 대인관계와 매우 유사하여 의사소통 능력을 키우고, 
            자기를 표현하고 사회성을 훈련하는 데 도움을 줄 수 있습니다. 
            부모는 놀잇을 통해 아이가 상대와의 대화에서 어떻게 응답하고 상호작용하는지를 직접 관찰할 수 있습니다. 
            부모에게는 아이의 발달 상태를 관찰하고 필요한 도움을 파악하는 데이터를 제공합니다. 
          </Styled.sub>
        </Styled.tabContentsBottom>
      </>,
      backgroundColor: "#EDF7F8",
      activeButtonColor: Colors.blue,
    },
  ];

  return (
    <Styled.wrap>

      <TabMenu tabs={tabs} />


      {/* <Styled.title
        data-aos="fade-down"
        data-aos-duration="700"
        data-aos-delay="0"
        data-aos-easing="ease-in-out-cubic"
      >
        아이들만의 소셜네트워크는 <br />
        달라야합니다
      </Styled.title>
      <Styled.sub
        data-aos="fade-down"
        data-aos-duration="700"
        data-aos-delay="700"
        data-aos-easing="ease-in-out-cubic"
      >
        우리 아이들이 안전하게 이용할 수 있도록
        <br />
        실시간 아이 인증을 실행하고 있습니다.
        <br /><br />
        안심하고<br />
        온라인 키즈카페 놀잇에서<br />
        친구와 놀게 해주세요<br />
      </Styled.sub>
      <Styled.screen
        data-aos="zoom-in-up"
        data-aos-duration="700"
        data-aos-delay="1000"
        data-aos-easing="ease-in-out-cubic"
      /> */}
    </Styled.wrap>
  );
}

export default MFourthContainer;
