import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Colors } from "../../enums/Colors";
import { MAX_WIDTH_PC } from "../../styles/preset";

interface TabProps {
    title: React.ReactNode;
    content: React.ReactNode;
    backgroundColor: string;
    activeButtonColor: string;
}

interface TabMenuProps {
    tabs: TabProps[];
    titleComponent?: React.ComponentType<any>;
}

const Styled = {
    wrap: styled.div<{ height: number }>`
    width: 100%;
    box-sizing: border-box;
    padding: 0 4rem;
    margin: 0 auto;
    
    height: calc(${({ height }) => (height - 54) / 10}rem);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,

    inner: styled.div`
    width: 100%;
    ${MAX_WIDTH_PC};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
    title: styled.div`
    color: black;
    font-size: 1.5rem;
    line-height: 6.2rem;
    font-weight: bold;
  `,
    sub: styled.div`
    color: black;
    font-size: 2.4rem;
    margin-top: 18px;
    margin-bottom: 38px;
    line-height: 3.6rem;
    text-align: center;
  `,
    screenWrapper: styled.div`
    position: relative;
    width: 100%;
  `,
};

const StyledTabMenu = styled.div<{ background: string }>`
    &{
    width: 100%;
    height: 100%;
    /* display: flex; */
    /* flex-direction: column;
    align-items: center;
    justify-content: space-between; */
    background-color: ${({ background }) => background};
    padding: 2rem 0rem;
    box-sizing: border-box;
    
    }
  .tab-menu-item {
    background-color: #ccc;
    color: ${Colors.black};
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
  }

  .tab-menu-item.active {
    background-color: ${Colors.red};
    color: ${Colors.white};
  }

  .tab-content {
    // Styles for the tab content
  }
`;

const Tab: React.FC<TabProps> = ({ content }) => {
    return <div className="tab">{content}</div>;
};

const TabMenu: React.FC<TabMenuProps> = ({ tabs, titleComponent: TitleComponent }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [isMouseOver, setIsMouseOver] = useState(false);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        const startTimer = () => {
            intervalId = setInterval(() => {
                setActiveTabIndex((prevIndex) => (prevIndex + 1) % tabs.length);
            }, 3000);
        };

        const stopTimer = () => {
            clearInterval(intervalId);
        };

        if (!isMouseOver) {
            startTimer();
        }

        return () => {
            stopTimer();
        };
    }, [isMouseOver, tabs.length]);

    return (
        <StyledTabMenu background={
            tabs[activeTabIndex].backgroundColor
        }>
            <div style={{
                paddingTop: "4rem",
                display: "flex",
                // display: "-webkit-box",
                alignItems: "center",
                gap: "0.5rem",
                overflowX: "auto",
                whiteSpace: "nowrap",
                justifyContent: "space-between",
            }}>
                {tabs.map((tab, index) => (
                    <motion.div
                        className={`tab-menu-item ${index === activeTabIndex ? "active" : ""}`}
                        key={'active-tab-' + index}
                        onMouseEnter={() => setIsMouseOver(true)}
                        onMouseLeave={() => setIsMouseOver(false)}
                        onClick={() => setActiveTabIndex(index)}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        style={{
                            backgroundColor: index === activeTabIndex ? tab.activeButtonColor : "#ccc",
                            boxShadow: index === activeTabIndex ? "0 0 10px rgba(0, 0, 0, 0.2)" : "none",
                            borderRadius: "10rem",
                            padding: "0.6rem 2rem",
                            border: index === activeTabIndex ? "0.4rem solid #fff" : "0.4rem solid #ccc",
                            fontSize: "1.6rem",
                            fontWeight: index === activeTabIndex ? 800 : 400,
                            lineHeight: "2.2rem",
                            textAlign: "center",
                            boxSizing: "border-box",
                            color: "#fff",
                        }}
                    >
                        {TitleComponent ? <TitleComponent>{tab.title}</TitleComponent> : tab.title}
                    </motion.div>
                ))}
            </div>
            <div
                style={{
                    background: tabs[activeTabIndex].backgroundColor,
                    height: "calc(90%)",
                    width: "100%",
                }}
            >
                {tabs[activeTabIndex].content}
            </div>
        </StyledTabMenu>
    );
};

export { Tab, TabMenu };
