import React, { useState } from "react";
import styled from "styled-components";
import img_header_norit_logo from "@assets/image/img_header_norit_logo.png";
import MFirstContainer from "@containers/mobile/MFirstContainer";
import MSecoundContainer from "@containers/mobile/MSecoundContainer";
import AOS from "aos";
import MThirdContainer from "@containers/mobile/MThirdContainer";
import MFourthContainer from "@containers/mobile/MFourthContainer";
import MFifthContainer from "@containers/mobile/MFifthContainer";
import MReviewsContainer from "@containers/mobile/MReviewsContainer";
import MPartnersContainer from "@containers/mobile/MPartnersContainer";
import MContactContainer from "@containers/mobile/MContactContainer";
import Footer from "@components/Footer";
import img_download from "@assets/image/img_download@2x.png";
import ico_menu from "@assets/image/ico_menu@2x.png";
import ico_close from "@assets/image/ico_close@2x.png";
import { isiOS } from "../utils/agentHelper";
import MHeader from "@components/MHeader";
import MSixthContainer from "@containers/mobile/MSixthContainer";
import PartnersContainer from "@containers/PartnersContainer";

const Styled = {
  wrap: styled.div`
    width: 100vw;
    overflow-x: hidden;
  `,

  headerWrapper: styled.div`
    background: white;
    position: fixed;
    z-index: 9999;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.08) 0 4px 24px;
  `,
  header: styled.div`
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1.8rem 0 3rem;
  `,
  menuWrapper: styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 2rem;
  `,
  menu: styled.a`
    text-align: center;
    font-size: 2.4rem;
    padding: 2rem 0;
  `,
  logo: styled.div`
    background: url(${img_header_norit_logo}) no-repeat center center/contain;
    justify-items: flex-start;
    min-width: 4.2rem;
    min-height: 3.6rem;
  `,
  download: styled.a`
    box-sizing: border-box;
    padding: 0.8rem 1.2rem;
    border-radius: 10px;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #ff862e;
    display: flex;
    align-items: center;
  `,
  right: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
  downloadIcon: styled.div`
    margin-right: 0.6rem;
    min-width: 2.4rem;
    min-height: 2.4rem;
    background: url(${img_download}) no-repeat center center/contain;
  `,
  menuIcon: styled.div`
    margin-left: 1.4rem;
    min-width: 3rem;
    min-height: 3rem;
    background: url(${ico_menu}) no-repeat center center/contain;
  `,
  closeIcon: styled.div`
    margin-left: 1.4rem;
    min-width: 3rem;
    min-height: 3rem;
    background: url(${ico_close}) no-repeat center center/contain;
  `,
};

function MMain() {
  const [open, setOpen] = useState(false);
  AOS.init({
    once: false,
    mirror: true,
  });
  return (
    <Styled.wrap>
      {/* <MHeader/> */}
      <MFirstContainer />
      <MSecoundContainer />
      <MThirdContainer />
      <MFourthContainer />
      <MFifthContainer />
      <MSixthContainer />
      <MPartnersContainer />
      <MContactContainer />

    </Styled.wrap>
  );
}
export default MMain;
