import React from "react";
import LoginForm from "./LoginForm";
import styled from "styled-components";
const Styled = {
  wrap: styled.div`
    min-height: 100vh;
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    `,
  paper: styled.div`
    margin: 0rem auto 0rem auto;
    height: 100%;
  `,
  contents: styled.div`
    width: 80vw;
    height: 100vh;
    max-width: 600px;
    margin: 0 auto;  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.4rem; 
    padding-top: 12rem;
    /* border: 1px solid #e5e5e5; */
    /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); */
  `,
  pageItemWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  pageItem: styled.div<{ active?: boolean; theme?: "white" }>`
    cursor: pointer;
    padding: 12px 0;
    font-size: ${({ active }) => (active ? "2.4rem" : "1.4rem")};
    color: ${({ active, theme }) =>
      active
        ? theme
          ? theme
          : "black"
        : theme === "black"
          ? "rgba(0,0,0, 0.5)"
          : "rgba(255,255,255, 0.5)"};
  `,
  line: styled.div<{ theme?: "white" }>`
    width: 2px;
    background-color: ${({ theme }) => (theme ? theme : "black")};
    height: 60px;
  `,
}
const Login = () => {
  return (
    <Styled.wrap>
      <Styled.paper>
        <Styled.contents>
          <div style={{
            // border: "1px solid #ccc",
            padding: "2rem",
            borderRadius: "5px",
            height: "100%",
          }}>
            <h1 style={{
              marginBottom: "2.6rem",
            }}>로그인</h1>

            <LoginForm />
          </div>
        </Styled.contents>
      </Styled.paper>
    </Styled.wrap>
  );
};

export default Login;
