import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface CountUpProps {
  count: number;
  duration: number;
  decimalPlaces?: number;
}

const CountUp: React.FC<CountUpProps> = ({ count, duration, decimalPlaces = 0 }) => {
  const [currentCount, setCurrentCount] = useState(0);
  const countRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const increment = Math.ceil(count / (duration / 10));
    let tempCount = currentCount;
    let timer: NodeJS.Timeout;

    if (tempCount < count) {
      timer = setInterval(() => {
        tempCount += increment;
        tempCount = Math.min(tempCount, count);
        setCurrentCount(tempCount);
      }, 10);
    }

    return () => clearInterval(timer);
  }, [count, duration, currentCount]);

  useEffect(() => {
    const isInView = (): boolean => {
      if (countRef.current) {
        const rect = countRef.current.getBoundingClientRect();
        return rect.top >= 0 && rect.bottom <= window.innerHeight;
      }
      return false;
    };

    const handleScroll = (): void => {
      if (!isInView()) {
        setCurrentCount(0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const formattedCount = currentCount.toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
  const is_mobile = window.innerWidth < 400;

  if (is_mobile) {

    return (
      <AnimatePresence>
        <motion.span
          ref={countRef}
          style={{
            display: 'inline-block',
            textAlign: 'left',
            fontSize: '4rem',
            fontWeight: 800,
            fontFamily: 'Montserrat',
            minWidth: '5rem',
            position: 'relative',
            zIndex: 1,
          }}
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.5 }}
        >
          {formattedCount}
        </motion.span>
      </AnimatePresence>
    );
  }
  return (
    <AnimatePresence>
      <motion.span
        ref={countRef}
        style={{
          display: 'inline-block',
          textAlign: 'left',
          fontSize: '6rem',
          fontWeight: 800,
          fontFamily: 'Montserrat',
          minWidth: '5rem',
          position: 'relative',
          zIndex: 1,
        }}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.5 }}
        transition={{ duration: 0.5 }}
      >
        {formattedCount}
      </motion.span>
    </AnimatePresence>
  );
};

export default CountUp;
