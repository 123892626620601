import { create } from "zustand";

interface MbtiType {
  [key: string]: any;
  type: string;
  title: string;
  sub_title: string;
  characteristic: string[];
  careful: string[];
  visible: boolean;
  soulmate: string;
  synergy: string;
  soulmate_desc: string;
  synergy_desc: string;
}

export const mbti_types: MbtiType[] = [
  {
    type: "EFNP",
    title: "인기많은 핵인싸형",
    sub_title: "내가 없으면 너네 무슨 재미로 노니?",
    characteristic: [
      "낙천적이고 사교성이 밝아요.",
      "어느 장소에서든 스트레스 받지 않고 잘지낼 수 있어요.",
      "상상력이 풍부하고 친구들과 감정 교류를 좋아한답니다.",
      "놀이를 할 때도 주인공부터 감초역할까지 주변사람 모두를 즐겁게 하며 기쁨을 느낀답니다.",
      "창의적이고 상상력을 발휘할 수 있는 놀이를 좋아해요.",
    ],
    careful: [
      "워낙 낙천적인 성격때문에 반복 놀이, 청소와 같은 체계적인 활동을 좋아하지 않을 수 있어요.",
      "의사결정이 필요할 때 선택을 잘 하지 못 하는 경우가 많아 친구들을 힘들게 하는 경우가 있을 수도 있답니다",
    ],
    visible: false,
    soulmate: "IFSJ",
    synergy: "ITSJ",
    soulmate_desc:
      "핵인싸형인 아이는 친구관계에 열정적이고 상상력이 뛰어난 친구랍니다! 뛰어난 친화력으로 상대적으로 조용한 우리아이와 쉽게 친해질 수 있고 아이의 생각과 감정을 잘 공감해줄 수 있는 최고의 소울메이트랍니다!",
    synergy_desc:
      "핵인싸형인 아이는 친구관계에 열정적이고 상상력이 뛰어난 친구랍니다! 낙천적이고 사교적이지만 상상력이 풍부한 성향인 핵인싸형 친구는 상대적으로 조용한 우리 아이의 생각을 이해하고 함께 표출해줄 수 있어 최고의 시너지를 낼 수 있을 거에요!",
  },
  {
    type: "EFSJ",
    title: "인기많은 핵인싸형",
    sub_title: "내가 없으면 너네 무슨 재미로 노니?",
    characteristic: [
      "낙천적이고 사교성이 밝아요.",
      "어느 장소에서든 스트레스 받지 않고 잘지낼 수 있어요.",
      "상상력이 풍부하고 친구들과 감정 교류를 좋아한답니다.",
      "놀이를 할 때도 주인공부터 감초역할까지 주변사람 모두를 즐겁게 하며 기쁨을 느낀답니다.",
      "창의적이고 상상력을 발휘할 수 있는 놀이를 좋아해요.",
    ],
    careful: [
      "워낙 낙천적인 성격때문에 반복 놀이, 청소와 같은 체계적인 활동을 좋아하지 않을 수 있어요.",
      "의사결정이 필요할 때 선택을 잘 하지 못 하는 경우가 많아 친구들을 힘들게 하는 경우가 있을 수도 있답니다",
    ],
    visible: true,
    soulmate: "IFSJ",
    synergy: "ITSJ",
    soulmate_desc:
      "핵인싸형인 아이는 친구관계에 열정적이고 상상력이 뛰어난 친구랍니다! 뛰어난 친화력으로 상대적으로 조용한 우리아이와 쉽게 친해질 수 있고 아이의 생각과 감정을 잘 공감해줄 수 있는 최고의 소울메이트랍니다!",
    synergy_desc:
      "핵인싸형인 아이는 친구관계에 열정적이고 상상력이 뛰어난 친구랍니다! 낙천적이고 사교적이지만 상상력이 풍부한 성향인 핵인싸형 친구는 상대적으로 조용한 우리 아이의 생각을 이해하고 함께 표출해줄 수 있어 최고의 시너지를 낼 수 있을 거에요!",
  },
  {
    type: "ETSJ",
    title: "모범생 리더형",
    sub_title: "바른생활 어린이의 표본!",
    characteristic: [
      "친구들과 함께 활동하고 화합하는걸 좋아하는 리더십 있는 성향이에요!",
      "단체생활에 익숙하고 질서와 도덕성을 중요시 생각하는 성향이 있어요.",
      "어떠한 목표가 주어졌을 때 여러사람과 함께 올바른 방법으로 일을 해결하는 것을 좋아해요.",
      "협동 놀이와 도전적인 놀이를 좋아해요.",
    ],
    careful: [
      "친구들이 무질서한 모습을 보이거나 원하지 않는(정직하지 않은) 방향으로 일을 해결하려 할 때 스트레스를 받을 수 있어요.",
      "본인이 원하는 방향으로 놀이가 이루어 지지 않을 시 친구들을 나무라거나 놀이에서 이탈해버릴 수도 있어요.",
      "창의적인 놀이활동을 싫어할 수 있어요.",
    ],
    visible: true,
    soulmate: "IFSJ",
    synergy: "ITSJ",
    soulmate_desc:
      "리더형의 아이는 화합과 공동체를 중요하게 생각하는 성향을 가졌답니다! 뿐만 아니라, 놀이 속에서 역할을 분담하고 도전하는 일을 즐긴답니다.우리 아이와는 하나의 팀처럼 놀이할 수 있는 소울메이트랍니다.",
    synergy_desc:
      "모범적이고 질서와 화합을 중요시 하는 리더형 친구는 우리 아이가 화합, 협력 능력을 기르는데에 큰 도움을 줄 수 있는 친구에요! 뿐만 아니라, 협동을 통한 도전을 좋아하는 성향인 리더형 아이는 우리 아이의 도전욕구를 더욱 끌어올려 최고의 시너지를 낼 수 있을 거에요!",
  },
  {
    type: "ETNP",
    title: "호기심 가득 토론왕형",
    sub_title: "그래서 놀이는 언제 시작할거야?",
    characteristic: [
      "풍부한 호기심을 바탕으로 도전을 즐기며 친구들과 교류하는걸 아주 좋아하는 성향이에요!",
      "상상을 현실로 만들 수 있는 잠재력을 가지고 있기때문에 풍부한 경험이 중요해요.",
      "순수하고 이성적인 질문을 좋아해요.",
      "높은 자신감을 가졌기 때문에 여러 환경에서 즐겁게 놀 수 있답니다.",
      "창의적이고 도전적인 놀이를 좋아해요.",
    ],
    careful: [
      "아이의 열정적인 질문은 과하게 표출될 시 친구들을 불편하게 만들 수 있어요.",
      "감정 교류, 공감보다는 사실에 집중하는 성향이 있기 때문에 갈등을 불러 일으킬 수도 있답니다.",
      "이해와 협력 능력이 부족할 경우 사회성이 부족해보일 수 있어요.",
    ],
    visible: true,
    soulmate: "ITNP",
    synergy: "ETSJ",
    soulmate_desc:
      "토론왕형인 아이는 호기심이 풍부하고 질문을 통해 배우기를 좋아한답니다! 항상 탐구하고 상상하는 우리 아이에게 다양한 질문에 대한 해답을 함께 이야기하고 풀어가며 자연스럽게 학습할 수 있는 최고의 소울메이트에요!",
    synergy_desc:
      "토론왕형 아이는 도전적이고 유머러스한 성향을 가진 친구랍니다. 물음을 제기하고 도전해나가는 토론왕형 아이와 공동체 속에서 화합과 기여를 통해 도전하는 우리 아이는 서로에게서 배울 점이 가득한 친구관계랍니다.",
  },
  {
    type: "ITNP",
    title: "조용한 사색가형",
    sub_title: "멍 때리는거 아니에요, 생각중이랍니다.",
    characteristic: [
      "논리적이면서도 창의성이 넘치는 성향이에요.",
      "생각을 멈추지 않아요.",
      "혼자만의 놀이나 시간을 즐기는 모습을 자주 관찰할 수 있습니다.",
      "상대적으로 독특한 취미나 놀이를 좋아해요",
      "소수의 친구들과 깊은 관계를 오래동안 유지할 수 있어요",
      "학습적인 놀이를 통해 배우는 것을 좋아해요",
    ],
    careful: [
      "혼자만의 탐구를 좋아하는 성향 때문에 친구들과의 놀이에  참여하지 못하는 것처럼 보일 수 있어요.",
      "가끔 혼자 멍때리는 것처럼 보일 수도 있어요.",
      "친구들과는 조금 다른 취미, 놀이를 즐길 수 있기 때문에 많은 친구와의 교류가 없어 보일 수도 있어요.",
    ],
    visible: true,
    soulmate: "ETNP",
    synergy: "EFSJ",
    soulmate_desc:
      "탐구자형 아이는 호기심이 풍부하고 지적탐구에 진심이랍니다! 우리 아이의 호기심과 질문에 대해 함께 이야기하며 같은 취미를 함께 즐길 수 있는 아주 건강한 소울메이트에요!",
    synergy_desc:
      "탐구자형 친구는 논리적이고 비판적 사고를 하는 성향을 가지고 있답니다. 전반적으로 조용한 두 아이지만, 안정적이고 평온함을 추구하는 우리 아이와 달리 탐구자형 아이는 뛰어난 창의성과 새로운 사실 탐구에 진심이랍니다. 그렇기 때문에 함께 놀이, 공부를 할 때 서로에게 긍정적인 시너지를 낼 수 있답니다.",
  },
  {
    type: "IFSJ",
    title: "놀이 수호자형",
    sub_title: "내사전에 놀이에서 낙오되는 친구는 없다.",
    characteristic: [
      "꼼꼼하고 섬세한 성향이에요.",
      "안정적이고 평온함을 추구한답니다.",
      "친구들과의 놀이 과정에서 갈등이 생기는걸 싫어해요.",
      "매우 도덕적이고 겸손한 성향을 가지고 있기때문에 모범생이라는 이야기를 자주 듣기도 해요.",
      "규칙이 있고 뚜렷한 목적이 있는 놀이를 좋아해요.",
    ],
    careful: [
      "안정적인 상황을 추구하다보니 갑작스러운 변화에 스트레스를 받을 수 있어요.",
      "놀이 중 갑자기 규칙이 바뀐다거나 계획하지 않은 상황을 싫어한답니다.",
    ],
    visible: true,
    soulmate: "EFSJ",
    synergy: "ITNP",
    soulmate_desc:
      "수호자형인 아이는 상대방과 소통을 잘하고 꼼꼼한 성향을 가지고 있어요. 우리 아이의 넘치는 에너지와 감정교류를 조용히 잘 받아주고 옆에서 언니처럼 조용히 챙겨줄 수 있는 최고의 소울메이트랍니다!",
    synergy_desc:
      "수호자형의 아이는 갈등해결의 전문가에요! 내 뜻과 맞지 않고 방향이 조금 틀려도 소통을 통해 화합을 이끌어 낸답니다. 우리 아이의 강한 리더십에서 수호자형 친구의 소통을 통한 해결 능력을 보고 배운다면 최고의 시너지를 낼 수 있을 거에요!",
  },
  {
    type: "IFNP",
    title: "그 역할 내가할게! 평화주의자형",
    sub_title: "놀면서 싸울일이니?",
    characteristic: [
      "갈등을 싫어하고 솔직한 성향을 가졌어요.",
      "학교, 학원, 친구관계 등 공동체에 기여하는 것을 좋아해요.",
      "기여에 따른 성취를 이뤄냈을 때 아주 기뻐한답니다.",
      "먼저 앞에 나서서 무언가를 지시하거나 하지는 않지만 옆에서 묵묵히 친구들을 도와주고 끈기있게 도와주는 모습을 관찰할 수 있어요.",
      "협동놀이를 좋아해요.",
    ],
    careful: [
      "기여한 부분에 대해 인정받지 못할 때 스트레스를 받을 수 있어요.",
      "과한 기대에 대해 부담을 느끼는 경우가 있어요.",
      "혼자서 문제를 해결해야할 때 결정을 잘 못내리는 경향이 있어요.",
    ],
    visible: true,
    soulmate: "ETSJ",
    synergy: "ETNP",
    soulmate_desc:
      "책임자형인 아이는 솔직하고 체계적인 활동을 좋아하는 친구에요. 특히, 팀 역할에 기여하고 기대에 맞는 성취를 이뤄낼 때 큰 기쁨을 얻는 친구랍니다. 정직한 공동체 리더인 우리 아이와 협동 놀이에서 완벽한 서포터로서 서로 윈윈할 수 있는 관계랍니다.",
    synergy_desc:
      "책임자형인 아이는 솔직하고 체계적인 활동을 좋아하는 아이에요. 갈등을 싫어하는 특성 상 핵인싸형인 우리 아이의 창의성 넘치는 놀이 스타일에 맞춰주며 상대적으로 체계적인 활동을 싫어하는 우리 아이에게 체계적인 놀이와 규칙을 알려줄 수 있기때문에 최고의 시너지를 낼 수 있을 거에요!",
  },
  {
    type: "ITSJ",
    title: "그 역할 내가할게! 평화주의자형",
    sub_title: "놀면서 싸울일이니?",
    characteristic: [
      "갈등을 싫어하고 솔직한 성향을 가졌어요.",
      "학교, 학원, 친구관계 등 공동체에 기여하는 것을 좋아해요.",
      "기여에 따른 성취를 이뤄냈을 때 아주 기뻐한답니다.",
      "먼저 앞에 나서서 무언가를 지시하거나 하지는 않지만 옆에서 묵묵히 친구들을 도와주고 끈기있게 도와주는 모습을 관찰할 수 있어요.",
      "협동놀이를 좋아해요.",
    ],
    visible: false,
    careful: [
      "기여한 부분에 대해 인정받지 못할 때 스트레스를 받을 수 있어요.",
      "과한 기대에 대해 부담을 느끼는 경우가 있어요.",
      "혼자서 문제를 해결해야할 때 결정을 잘 못내리는 경향이 있어요.",
    ],
    soulmate: "ETSJ",
    synergy: "ETNP",
    soulmate_desc:
      "책임자형인 아이는 솔직하고 체계적인 활동을 좋아하는 친구에요. 특히, 팀 역할에 기여하고 기대에 맞는 성취를 이뤄낼 때 큰 기쁨을 얻는 친구랍니다. 정직한 공동체 리더인 우리 아이와 협동 놀이에서 완벽한 서포터로서 서로 윈윈할 수 있는 관계랍니다.",
    synergy_desc:
      "책임자형인 아이는 솔직하고 체계적인 활동을 좋아하는 아이에요. 갈등을 싫어하는 특성 상 핵인싸형인 우리 아이의 창의성 넘치는 놀이 스타일에 맞춰주며 상대적으로 체계적인 활동을 싫어하는 우리 아이에게 체계적인 놀이와 규칙을 알려줄 수 있기때문에 최고의 시너지를 낼 수 있을 거에요!",
  },
];

export const quiz_list = [
  {
    q: "우리 아이가 집에 친구를 데리고 왔다",
    a: [
      { answer: "어머, 또 새로운 친구네?", type: "E" },
      { answer: "매일 놀러오는 철수구나?", type: "I" },
    ],
  },
  {
    q: "낯선 장소에 함께 갔다",
    a: [
      {
        answer: "제발 좀 가만히 있어…! 여기 저기 구경다닌다",
        type: "E",
      },
      {
        answer: "좀 가서 놀다와주겠니…? 엄마(아빠) 옆에 껌딱지처럼 붙어 있는다",
        type: "I",
      },
    ],
  },
  {
    q: "학교 또는 유치원에서 전화가 왔다",
    a: [
      { answer: "OO이는 활발하고 적극적이에요", type: "E" },
      { answer: "OO이는 차분하고 얌전해요", type: "I" },
    ],
  },
  {
    q: "집 앞 놀이터에 함께 갔다",
    a: [
      {
        answer: '"안녕 여기서 뭐해?" 친구에게 먼저 말을 건다',
        type: "E",
      },
      { answer: "친구가 말을 걸기 전까지 혼자 논다", type: "I" },
    ],
  },
  {
    q: "우리 아이는 춤추고 노래하는 걸",
    a: [
      {
        answer: "좋아한다! 엄마(아빠), 나 좀 촬영해줘봐~",
        type: "E",
      },
      { answer: "좋아하지 않는다! 세상 낯가림!", type: "I" },
    ],
  },

  {
    q: "어른이 아이를 칭찬하고 관심을 줄 때, 아이의 반응은?",
    a: [
      { answer: "쭈뼛쭈뼛 어색해한다", type: "T" },
      {
        answer: "저 이것도 할 수 있다구요? 할술 더뜨기! 기분 좋아한다",
        type: "F",
      },
    ],
  },
  {
    q: "아이와 작년에 다녀온 여행에 대해 이야기한다",
    a: [
      {
        answer:
          "그 때 바다 근처 텐트에서 삼겹살 먹었었잖아! 맛있었는데~ 구체적이고 정확하게 이야기한다",
        type: "T",
      },
      {
        answer:
          "아빠 엄마랑 텐트에서 잔게 너무 좋았어! 좋았거나 싫었던 상황이나 감정에 대해 이야기한다",
        type: "F",
      },
    ],
  },
  {
    q: "만화 속 주인공이 악당을 물리쳤다!",
    a: [
      { answer: "통쾌해한다!", type: "T" },
      { answer: "악당이지만 불쌍해한다", type: "F" },
    ],
  },
  {
    q: "친한 친구가 갑자기 이사나 전학을 가게 되었다, 아이의 반응은?",
    a: [
      {
        answer: "잘가~ 너희 엄마 전화번호 뭐야? 영상통화로 만나자!",
        type: "T",
      },
      { answer: "우리 헤어지는거야…? 뿌엥~ 속상해한다", type: "F" },
    ],
  },
  {
    q: "장난감 가게에서 하나만 고르라고 할 때, 아이의 반응은? ",
    a: [
      {
        answer: "신중히 하나를 고르거나, 협상을 시도한다",
        type: "T",
      },
      {
        answer: "고민 끝에 하나를 고르지만, 더 사고 싶어한다",
        type: "F",
      },
    ],
  },
  {
    q: "아이의 평소 모습은? ",
    a: [
      { answer: "숙제 먼저하고 놀아야지…!", type: "SJ" },
      { answer: "숙제…? 일단 놀아야지!!", type: "NP" },
    ],
  },
  {
    q: "아이가 방학계획표를 작성했다 ",
    a: [
      { answer: "계획표를 잘 따르려고 노력한다.", type: "SJ" },
      {
        answer: "계획표 왜 쓴거야? 지키는 걸 본 적이 없다",
        type: "NP",
      },
    ],
  },
  {
    q: "아이의 방에 들어갔다",
    a: [
      { answer: "말하지 않아도 잘 정리되어있다.", type: "SJ" },
      {
        answer: "어제 가지고 논 장난감이 그대로 바닥에 있다.",
        type: "NP",
      },
    ],
  },
  {
    q: "만일 지니가 나타나 3가지 소원을 들어준다면? 아이의 반응은?",
    a: [
      {
        answer:
          "내가 좋아하는 장난감 몽땅 내꺼, 키즈카페 가고싶어, 유튜브 100시간 보기 와 같이 구체적인 바람을 이야기 한다.",
        type: "SJ",
      },
      {
        answer:
          "나는야 디즈니 공주가 되고 싶어, 하늘을 나는 슈퍼히어로가 되고 싶어. (소원은 이루어 지지 못하는걸 비는게 아니야….)",
        type: "NP",
      },
    ],
  },
  {
    q: "아이가 처음 무언가를 배우기 시작했다",
    a: [
      {
        answer: "원하는 결과가 나올 때 까지 꾸준하게 해낸다.",
        type: "SJ",
      },
      { answer: "금방 실증 내고 다른걸 하고 싶어한다.", type: "NP" },
    ],
  },
  {
    q: "아이가 색칠놀이를 하고 싶다고 한다",
    a: [
      {
        answer:
          "엄마가 그림 그려줘, 내가 색칠할께 그려진 그림에 색칠하는 것을 좋아한다",
        type: "SJ",
      },
      {
        answer: "내가 그리고 색칠해야지~ 직접 그리고 색칠하는 것을 좋아한다",
        type: "NP",
      },
    ],
  },
  {
    q: "우리 아이의 관심사는",
    a: [
      {
        answer: "곤충, 공룡, 태양계, 자동차 등 실제하는 것을 좋아한다",
        type: "SJ",
      },
      {
        answer: "연포켓몬, 티니핑 등 상상의 캐릭터를 좋아한다",
        type: "NP",
      },
    ],
  },
];

interface answerState {
  answer_list: { answer: string; type: string }[];
  answer_count: { [key: string]: number };
  addAnswer: (answer: { answer: string; type: string }) => void;
  resetAnswer: () => void;

};

const useAnswerStore = create<answerState>((set) => ({
  answer_list: [],
  answer_count: { E: 0, I: 0, T: 0, F: 0, SJ: 0, NP: 0 },
  addAnswer: (answer) =>
    set((state) => ({
      answer_list: [...state.answer_list, answer],
      answer_count: {
        ...state.answer_count,
        [answer.type]: state.answer_count[answer.type] + 1,
      },
    })),
  resetAnswer: () =>
    set({
      answer_list: [],
      answer_count: { E: 0, I: 0, T: 0, F: 0, SJ: 0, NP: 0 },
    }),
}));

export default useAnswerStore;
