import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore/lite';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAKY7a7Ig5KnP8LnXcusku9FvJXrG512sw",
  authDomain: "norit-ee7d8.firebaseapp.com",
  projectId: "norit-ee7d8",
  storageBucket: "norit-ee7d8.appspot.com",
  messagingSenderId: "495103163080",
  appId: "1:495103163080:web:2e52144332b3dd127583fd",
  measurementId: "G-FN79GHFP15"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { app, auth, db, analytics };
