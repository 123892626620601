import React, { useEffect, useState } from "react";
import "normalize.css";
import "@assets/fonts/font.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import GlobalStyle from "./styles/global";
import { N_SMALL_TABLET_MAX_SIZE } from "./enums";

import useResize from "@assets/hooks/useResize";

import Main from "./pages/Main";
import MMain from "./pages/MMain";
import Login from "./pages/login/Login";
import MHeader from "@components/MHeader";
import Header from "@components/Header";
import Footer from "@components/Footer";
import Signup from "./pages/signup/Signup";
import AuthProvider from "./shared/provider/AuthProvider";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Mypage from "./pages/mypage/Mypage";
import Quiz from "./pages/mbti/Quiz";
import Result from "./pages/mbti/Result";
import AllTypes from "./pages/mbti/AllTypes";
import MBTIMain from "./pages/mbti/Main";

import { auth } from "./shared/firebase";


function App() {
  const [showFloatingBanner, setShowFloatingBanner] = useState(false);
  useResize();
  const isMobile = window.innerWidth < N_SMALL_TABLET_MAX_SIZE;

  React.useEffect(() => {
    if (localStorage.getItem("floatingBannerClose") === "true") {
      setShowFloatingBanner(false);
      return;
    }
    
    setShowFloatingBanner(true);
  }, []);
  return (
    <BrowserRouter>

      <ToastContainer position="top-right"
        autoClose={3000} // 자동 off 시간
        hideProgressBar={false} // 진행시간바 숨김
        closeOnClick // 클릭으로 알람 닫기
        rtl={false} // 알림 좌우 반전
        pauseOnFocusLoss // 화면을 벗어나면 알람 정지
        draggable // 드래그 가능
        pauseOnHover // 마우스를 올리면 알람 정지
        theme="light"
      />
      <AuthProvider>
        <div className="App">
          <GlobalStyle />
          {isMobile ? <MHeader /> : <Header />}
          <Routes>
            <Route path="/" element={isMobile ? <MMain /> : <Main />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/mypage/:type" element={<Mypage />} />
            <Route path="/mbti" element={<MBTIMain />} />
            <Route path="/mbti/quiz" element={<Quiz />} />
            <Route path="/mbti/:type" element={<Result />} />
            <Route path="/mbti/result" element={<AllTypes />} />
          </Routes>
          <Footer />
        </div>
      </AuthProvider>
      


    </BrowserRouter>

  );
}

export default App;
