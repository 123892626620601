import React, { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";


import img_norit_logo from "@assets/image/img_norit_logo@2x.png";
import img_02_01_01 from "@assets/image/img_02_01_01@2x.png";
import img_illust_01 from "@assets/image/img_illust_01@2x.png";
import bg_02_line from "@assets/images/bgm1.png";
import bg_02_board from "@assets/images/bgm2.png";

import star_0 from "@assets/images/star_0.svg";
import star_1 from "@assets/images/star_1.svg";
import union from "@assets/images/union.svg";
import percent_blue from "@assets/images/percent_blue.svg";
import percent_green from "@assets/images/percent_green.svg";
import CountUp from "../../shared/components/Countup";

const Styled = {
  sperator: styled.div`
    height: 90vh;
    width: 100%;
  `,
  wrap: styled.div`
    box-sizing: border-box;
    padding: 2rem 2rem 0rem 2rem;
    // background color 점점 진해지는 효과
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 1) 30%,
      rgba(255, 255, 255, 1) 100%
    );
    /* background-color: white; */
    height: calc(100vh);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    margin-top: -20%;
    border-radius: 3rem 3rem 0 0;
    border: 0.5rem solid white;
  `,
  title: styled.div`
    color: black;
    font-size: 2.6rem;
    line-height: 3.8rem;
    font-weight: 800;
    padding: 7rem 0rem 2rem;
    text-align: left;
    position: relative;
  `,
  sub: styled.div`
    color: black;
    font-size: 1.6rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 2.0rem;
    text-align: center;
    & > b {
      font-weight: 800;
    }
  `,
  subTitle: styled.div`
  color: black;
  font-size: 1.4rem;
  margin-top: 1rem;
  padding-top: 2rem;
  margin-bottom: 1rem;
  line-height: 2.2rem;
  text-align: center;
  & > b {
    font-weight: 800;
  }
`,
  desc: styled.div`
  color: #00000044;
  font-size: 1.2rem;
`,
};
function MSecoundContainer(): ReactElement {
  return (
    <>
      <Styled.wrap>

        <Styled.title
          data-aos="fade-down"
          data-aos-duration="700"
          data-aos-delay="0"
          data-aos-easing="ease-in-out-cubic"
        >
          이미 많은 부모님과 아이들이 <br />
          만족하고 있습니다
        </Styled.title>
        <div style={{
          width: '100%',
          textAlign: 'left',
        }}>
          <motion.div>
            <p style={{
              color: 'black',
              fontSize: '2rem',
              lineHeight: '2.4rem',
              marginTop: '2rem',
              marginBottom: '1rem',
            }}>아이들이 만나 <b style={{
              fontWeight: '800',
            }}>‘놀잇’</b> 한 수</p>

            <div style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <CountUp count={10000} duration={600} decimalPlaces={0} />
              <motion.img
                style={{
                  width: '4.2rem',
                }}
                initial={{ opacity: 0, y: -120 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 1.2, type: 'spring', stiffness: 120 }}
                src={union} />
            </div>
          </motion.div>

          <div>
            <p style={{
              color: 'black',

              fontSize: '2rem',
              lineHeight: '2.4rem',
              marginTop: '2rem',
              marginBottom: '1rem',
            }}>부모 이용 만족도</p>
            <div style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <CountUp count={97} duration={600} decimalPlaces={0} />
              <motion.img
                style={{
                  width: '4.2rem',
                }}
                initial={{ opacity: 0, y: -120 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.6, type: 'spring', stiffness: 120 }}
                src={percent_blue} />
            </div>
          </div>

          <div>
            <p style={{
              color: 'black',

              fontSize: '2rem',
              lineHeight: '2.4rem',
              marginTop: '2rem',
              marginBottom: '1rem',
            }}>아이 이용 만족도</p>
            <div style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <CountUp count={98} duration={600} decimalPlaces={0} />
              <motion.img
                style={{
                  width: '4.2rem',
                }}
                initial={{ opacity: 0, y: -120 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.6, type: 'spring', stiffness: 120 }}
                src={percent_green} />
            </div>
          </div>



          <div style={{ position: 'relative' }}>
            <p style={{
              color: 'black',
              fontSize: '2rem',
              lineHeight: '2.4rem',
              marginTop: '2rem',
              marginBottom: '1rem',
            }}>순추천 지수</p>
            <div style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}>

              <motion.img
                style={{
                  position: 'absolute',
                  zIndex: 0,
                }}
                initial={{ opacity: 0, y: -120, x: -30 }}
                whileInView={{ opacity: 1, y: 10, x: -30 }}
                transition={{ duration: 0.6, delay: 0.3, type: 'spring', stiffness: 120 }}
                src={star_0} />
              <CountUp count={9.6} duration={600} decimalPlaces={1} />
              <motion.img
                style={{
                  position: 'absolute',
                  zIndex: 0,
                }}
                initial={{ opacity: 0, y: -100, x: 40 }}
                whileInView={{ opacity: 1, y: -15, x: 40 }}
                transition={{ duration: 0.6, delay: 0.5, type: 'spring', stiffness: 120 }}
                src={star_1} />
              <Styled.desc>
                10점 만점
              </Styled.desc>
            </div>

          </div>
          <div style={{
            marginTop: '3rem',
          }}>
            <Styled.desc>
              23년 7월 기준
            </Styled.desc>
          </div>
        </div>
        <motion.div
          // initial={{ width: 0 }}
          // whileInView={{ width: '100vw' }}
          // transition={{ duration: 1.5 }}
          style={{
            position: 'absolute',
            top: '10vh',
            left: 0,
            zIndex: 0,
            overflow: 'hidden',
            bottom: 0
          }}
        >
          <div
            style={{
              width: '100vw',
              height: '100vh',
              backgroundImage: `url(${bg_02_line}), url(${bg_02_board})`,
              backgroundRepeat: 'no-repeat, no-repeat',
              backgroundPosition: '100% -27%, 100% 10%',
              backgroundSize: '98% 97%, 100% 100%',
            }}>
          </div>
        </motion.div>
      </Styled.wrap>
    </>
  );
}

export default MSecoundContainer;
