import { css } from "styled-components";
import { DESKTOP_MAX_SIZE } from "../enums";

export const MAX_WIDTH_PC = css`
  max-width: ${DESKTOP_MAX_SIZE};
`;
export const STYLE_KEEP_WORD = css`
  word-break: keep-all;
  word-wrap: break-word;
`;
