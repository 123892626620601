import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import {
  BIG_TABLET_MAX_SIZE,
  MOBILE_MIN_SIZE,
  SMALL_TABLET_MAX_SIZE,
} from "../enums";

const GlobalStyle = createGlobalStyle`
  ${reset};
  @font-face {
    font-family: 'NanumBarunpen';
    font-weight: 400;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/NanumBarunpen/NanumBarunpen-Regular.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/NanumBarunpen/NanumBarunpen-Regular.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.jsdelivr.net/gh/webfontworld/NanumBarunpen/NanumBarunpen-Regular.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/webfontworld/NanumBarunpen/NanumBarunpen-Regular.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/webfontworld/NanumBarunpen/NanumBarunpen-Regular.ttf') format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: 'NanumBarunpen';
    font-weight: 700;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/NanumBarunpen/NanumBarunpen-Bold.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/NanumBarunpen/NanumBarunpen-Bold.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.jsdelivr.net/gh/webfontworld/NanumBarunpen/NanumBarunpen-Bold.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/webfontworld/NanumBarunpen/NanumBarunpen-Bold.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/webfontworld/NanumBarunpen/NanumBarunpen-Bold.ttf') format("truetype");
    font-display: swap;
  }
  /* 폰트 */
  /* notosanskr / Nanum gothic Coding / Black Han Sans  */
  @import url('//fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
  @import url('//fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&display=swap');
  @import url('//fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');

  /* NanumGothic /  nanumgothic / Nanum Gothic / nanum gothic */
  @import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');

  * {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-family: 'Nanum Gothic', sans-serif;
    /* font-family: NanumBarunpen, sans-serif; */
    ::-webkit-scrollbar {
      display: none;
    }
  }

  body, talbe, th, td, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6,
  pre, form, fieldset, textarea, blockquote, span, * {
    font-family: 'Nanum Gothic', sans-serif;
    /* font-family: NanumBarunpen, sans-serif; */
    font-weight: 400;
  }


  input, textarea {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  html, body {
    min-height: 100%;
    min-height: 100vh;
    scroll-behavior: smooth;
  }

  html {
    height: 100vh;
    font-size:10px;


    @media (min-width: ${SMALL_TABLET_MAX_SIZE}) and (max-width: ${BIG_TABLET_MAX_SIZE}) {
      font-size: 8px;
    }

    @media (min-width: ${SMALL_TABLET_MAX_SIZE}) and (max-width: ${BIG_TABLET_MAX_SIZE}) {
      font-size: 8px;
    }
    
  }

  body {
    min-width: 320px;
    width: 100%;
    height: 100%;
    background-color: white;
    font-family: NanumBarunpen, sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  input {
    border-radius: 0;
  }

  input[type="search"] {
    -webkit-appearance: none;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    vertical-align: top;
  }

  input, textarea {
    margin: 0;
    padding: 0;
    background: transparent;
    outline: none;

    &::-webkit-input-placeholder {
      line-height: normal;
    }
  }

  input[type="search"] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }

  button {
    background: none;
    border: none;
    outline: 0;
    color: inherit;
  }
  
  #root {
    min-height: 100vh;
  }

  // react-virtualized package outline reset
  .ReactVirtualized__Grid {
    outline: none;
  }

  h1 {
    font-size: 3rem;
    font-weight: 700;
  }

  h2 {
    font-size: 2rem;
    font-weight: 700;
  }

  p{
    font-size: 1.6rem;
  }
`;

export default GlobalStyle;
