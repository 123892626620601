import React from "react";
import SignupForm from "./SignupForm";
import styled from "styled-components";

const Styled = {
  wrap: styled.div`
    min-height: 100vh;
    width: 100vw;
    padding: 12rem 0rem 5rem 0rem;
    box-sizing: border-box;
    `,
  pager: styled.div`
    margin: 0 auto;
  `,
  contents: styled.div`
    width: 80vw;
    max-width: 800px;
    margin: 0 auto;  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.4rem; 
  `,
  pageItemWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  pageItem: styled.div<{ active?: boolean; theme?: "white" }>`
    cursor: pointer;
    padding: 12px 0;
    font-size: ${({ active }) => (active ? "2.4rem" : "1.4rem")};
    color: ${({ active, theme }) =>
      active
        ? theme
          ? theme
          : "black"
        : theme === "black"
          ? "rgba(0,0,0, 0.5)"
          : "rgba(255,255,255, 0.5)"};
  `,
  line: styled.div<{ theme?: "white" }>`
    width: 2px;
    background-color: ${({ theme }) => (theme ? theme : "black")};
    height: 60px;
  `,
}
const Signup = () => {
  return (
    <Styled.wrap>
      <Styled.contents>
        <h1>회원가입</h1>
        <SignupForm />
      </Styled.contents>
    </Styled.wrap>
  );
};

export default Signup;
