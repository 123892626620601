import React, { ReactElement, useEffect } from "react";
import styled from "styled-components";

import img_06_01 from "@assets/images/img_06_01.svg";
import img_star_00 from "@assets/images/img_star_00.svg";

import { motion } from "framer-motion";
import { Colors } from "../../enums/Colors";

const reviews = [
  { store: "App Store", review: "1. 저녁 준비시간을 만들어줘요 2. 처음보는 친구랑 처음에는 어색할 수 있어도 계속 하다보면 다음에 누구 만날까 다음에는 무엇으로 같이 놀까 내 장난감 무엇을 소개할까 하며 아이의 설렘을 만들어줘요. 3. 아쉬워도 또 다른친구랑 할 수 있다는 걸 알려주니 만남과 헤어짐을 배울 수 있어서 좋아요. 4.익숙해지거나 또래를 만나면 지역의 문화도 자연스럽게 이야기 할 수 있게되어 학습적으로도 재미있게 배울 수 있는 기회가 생겨요. 5. 사회성 발달에 도움을 주어 좋아요", rate: 5 },
  { store: "Google Play", review: "소문듣고시작했는데 잘했다는 생각이 드네요^^ 사회성, 공감능력, 언어표현이 자라는게 느껴지네요! 코로나로 사회성 발달이 걱정되었는데 놀잇을 통해 기를 수 있겠어요~ 더불어 엄마아빠는 잠깐의 휴식을 취할 수 있으니 일석이조네요~^^ 네살 둘째가 더 좋아하고 적극적인건 안비밀입니다ㅎㅎㅎㅎ", rate: 5 },
  {
    store: "Google Play",
    review: `나름 적극적인 아이지만 처음만난 친구와는 어색하게 이야기하고 조용해서 계속 사용할까 고민도 했어요! 하지만 점점 말주변이 늘어나고 놀이를 제시하기도 하고 깔깔거리며 재밌어 하더라구요!! 엄마가 더 기다려지는 놀잇..(그만큼 엄마에게 여유가생긴답니다!) 앞으로 놀잇 더 사랑해야겠어요~ 모두 놀잇하세요❤🧡`,
    rate: 5
  },
  { store: "Google Play", review: "진짜 강추해요~ 어른들 눈에는 낯선 아이들과 놀 수 있나 싶은데 역시 애들은 다르네요. 처음보는 친구와도 언니동생오빠 할 거 없이 너~~~무 잘놀아요. 아이들끼리 서로 매너도 배우고 특히 곧 새학기되면 환경이 바뀌어 낯가리는 아이들도 있을텐데 사회성도 기르고 처음만나는 친구에게 다가가는 법도 배울 수 있을 거 같아요! 만화나 유튜브 게임 보여줄 시간에 친구들과 노는게 아이들 정신건강에 훨씬 더 좋은 거 같아요. 정말 강추합니다! ㅋㅋ고학년도 해보고 싶어서 동생 부러워 하네요ㅋㅋ", rate: 5 },


];

const Styled = {
  wrap: styled.div`
    box-sizing: border-box;
    padding: 3rem 4rem;
    background-color: #fff;
    /* height: calc(100vh - 8rem); */
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: hidden;
    position: relative;
  `,
  title: styled.div`
    color: black;
    font-size: 2.6rem;
    line-height: 3.8rem;
    font-weight: 800;
    padding: 2.6rem 0rem;
    text-align: center;
    position: relative;
  `,
  sub: styled.div`
    color: black;
    font-size: 1.6rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 2.0rem;
    text-align: center;
    padding: 0 2rem;
  `,
  Contents: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 60%;
    height: 100%;
    margin: 2rem auto;
  `,
  Content: styled.div`
    background-size: contain;
    width: calc(30vw);
    padding-top: calc(30vw * 1.0435);
    position: relative;
  `,
  ContentInner: styled.div`
    position: absolute;
    top: 2rem;
    left: 2rem;
    right: 2rem;
    bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  `,
  Heading: styled.h3`
    font-size: 2.4rem;
    font-weight: bold;
  `,
};

function MSixthContainer(): ReactElement {

  return (
    <Styled.wrap>
      <Styled.title
        data-aos="fade-down"
        data-aos-duration="700"
        data-aos-delay="0"
        data-aos-easing="ease-in-out-cubic"
      >
        평가 및 리뷰
        <motion.img src={img_06_01} alt=""
          initial={{ opacity: 0, y: 100, rotate: 360, x: -100 }}
          whileInView={{ opacity: 1, y: 0, rotate: 0, x: 0 }}
          transition={{ duration: 1, ease: "easeOut", delay: 0.5 }}
          style={{
            width: "10rem",
            marginLeft: "-5rem",
            marginTop: "-5rem",
            position: "absolute",
            zIndex: -1,
          }} />
      </Styled.title>


      <div className="contents" style={{
width: "100%", height: "auto", margin: "2rem auto"
      }}>
        {reviews.map((review, index) => (
          <>
            <div
              key={'review-wrapper' + index} style={{
                // border: "1px solid #e5e5e5",
                // borderRadius: "1rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",

              }}
              data-aos="fade-down"
              data-aos-duration="700"
              data-aos-easing="ease-in-out-cubic">
              <div style={{

                padding: "1rem",
                textAlign: "left",
                lineHeight: "2.4rem",
                fontSize: "1.6rem",
              }}
              >
                {review.review}
              </div>
              <div style={{
                // borderTop: "1px solid #e5e5e5",
                padding: "2rem 1rem 3rem 1rem",
                display: "flex",
                justifyContent: "space-between",

              }}>
                <div>{Array.from({ length: 5 }).map((r) => (
                  <img src={img_star_00} alt="" style={{ width: "1.5rem", height: "1.5rem" }} />
                ))}
                </div>
                <div>{review.store}</div>
              </div>
            </div>
          </>
        ))}
      </div>
    </Styled.wrap>
  );
}

export default MSixthContainer;
