import React from "react";
import styled from "styled-components";
import Slider from "react-slick";

import img_04_01 from "@assets/image/img_04_01@2x.png";

import logo01 from "@assets/images/logo01.png";
import logo02 from "@assets/images/logo02.png";
import logo03 from "@assets/images/logo03.svg";
import logo04 from "@assets/images/logo04.svg";
import logo05 from "@assets/images/logo05.svg";
import logo06 from "@assets/images/logo06.png";
import logo07 from "@assets/images/logo07.png";
import logo08 from "@assets/images/logo08.png";
import logo09 from "@assets/images/logo09.svg";

const Styled = {
  wrap: styled.div`
    background-color: white;
    width: 100vw;
    height: 100%;
    position: relative;
  `,
  titleWrapper: styled.div`
    position: relative;
  `,
  content: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
  `,

  title: styled.div`
    color: black;
    font-size: 2.6rem;
    line-height: 3.8rem;
    font-weight: 800;
    padding-top: 2.6rem;
    text-align: center;
    `,
  sub: styled.div`
    color: black;
    font-size: 1.6rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 2.0rem;
    text-align: center;
  `,

  img01: styled.div`
    position: absolute;
    background: url(${img_04_01}) no-repeat center center/contain;
    width: 17rem;
    height: 8rem;
    top: 0rem;
    right: -9rem;
    z-index: 0;
  `,
  Wrapper: styled.div`
    width: 90%;
    margin: 4rem auto 0rem auto;
    padding-bottom : 9.8rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4rem 0.2rem;
    align-items: center;
    justify-items: center;
    background-color: white;
  `,
  logos: styled.img`
    width: auto;
    height: auto;
    max-width: 10rem;
    max-height: 8rem;
  `,
};

function MPartnersContainer() {
  return (
    <Styled.wrap>
      <Styled.content>
        <Styled.titleWrapper>
        <Styled.img01
            data-aos="zoom-in-down"
            data-aos-duration="700"
            data-aos-delay="300"
            data-aos-easing="ease-in-out-cubic"
          />
          <Styled.title
            data-aos="fade-in"
            data-aos-duration="700"
            data-aos-delay="0"
            data-aos-easing="ease-in-out-cubic"
          >
            놀잇을 응원하는<br />
            든든한 파트너
          </Styled.title>
          
        </Styled.titleWrapper>
      </Styled.content>
      <Styled.Wrapper>
        <Styled.logos src={logo01} />
        <Styled.logos src={logo02} />
        <Styled.logos src={logo03} />
        <Styled.logos src={logo04} />
        <Styled.logos src={logo05} />
        <Styled.logos src={logo06} />
        <Styled.logos src={logo07} />
        <Styled.logos src={logo08} />
        <Styled.logos src={logo09} />
      </Styled.Wrapper>
    </Styled.wrap>
  );
}

export default MPartnersContainer;
