import React from "react";
import styled from "styled-components";
import ico_footer_youtube from "@assets/image/ico_footer_youtube@2x.png";
import ico_footer_insta from "@assets/image/ico_footer_insta@2x.png";
import ico_footer_blog from "@assets/image/ico_footer_blog@2x.png";
import ico_footer_kakao from "@assets/image/ico_footer_kakao@2x.png";
import { DESKTOP_MAX_SIZE, N_SMALL_TABLET_MAX_SIZE } from "../enums";
import img_header_norit_logo from "@assets/image/img_header_norit_logo.png";
import footer1 from "@assets/images/footer1.png";
const Styled = {
  wrap: styled.div`
    background-color: #3c3d41;
    box-sizing: border-box;
    margin: 0 auto;
    /* padding-top: 3.6rem; */
    padding-bottom: 5.5rem;
    position: relative;
    width: 100%;
  `,
  snsWrapper: styled.div`
    margin-bottom: 2.2rem;
    /* border-bottom: 1px solid #ffffff33; */
    padding-top: 3.6rem;
    padding-bottom: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  sns: styled.a<{ url: string, width: string }>`
    background: url(${({ url }) => url}) no-repeat center center/contain;
    margin-right: 2.4rem;
    min-width: 5rem;
    width: ${({ width }) => width ? width : '5rem'};
    min-height: 5rem;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  `,
  text: styled.div`
    padding: 1rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #ffffff99;
    text-align: left;
  `,
};
function Footer() {
  return (

    <Styled.wrap>
      <div style={{
        backgroundColor: '#2B2737',
        width: '100%',

      }}>
        <div style={{
          maxWidth: DESKTOP_MAX_SIZE,
          margin: '0 auto',
          padding: '1rem 0',
          color: '#ffffff99',
          textAlign: 'left',
        }}>
          <a href={'https://norit.notion.site/99e6de15f3be44a9a1ce8657a3645d76?pvs=4'} target="_blank">개인정보처리방침</a>
        </div>
      </div>
      <div style={{
        maxWidth: DESKTOP_MAX_SIZE,
        margin: '0 auto',
        width: '100%',
      }}>
        <div style={{
          display: 'flex',
          padding: '3.6rem 2rem ',
          width: '100%',
          flexDirection: window.innerWidth < N_SMALL_TABLET_MAX_SIZE ? 'column' : 'row',
          justifyContent: window.innerWidth < N_SMALL_TABLET_MAX_SIZE ? 'flex-start' : 'space-between',
          alignItems: 'flex-start',
        }}>
          <div style={{
            textAlign: "left"
          }}>

            <img style={{
              maxWidth: '8.4rem',
              margin: '1rem',
            }}
              src={img_header_norit_logo} />
            <Styled.text>
              (주)놀잇<br />
              사업자번호 : 423-81-02739<br />
              통신판매업 : 제 2022-서울강남-04547 호<br />
              대표자 : 최다은 <br />
              주소 : 서울특별시 강남구 선릉로 551, 5층<br />
              {/* 문의전화 : 032-215-1517<br /> */}
              대표메일 : info@norit.io
            </Styled.text>
            <Styled.text>
              ©{new Date().getFullYear()}. Norit. All rights reserved.
            </Styled.text>
          </div>
          <Styled.snsWrapper>
            <Styled.sns
              width="16rem"
              url={footer1}
              target="_blank"
              href="https://www.youtube.com/c/danikinder"
            />
            <Styled.sns
              width="6.4rem"
              url={ico_footer_insta}
              target="_blank"
              href="https://www.instagram.com/norit.io"
            />
            <Styled.sns
              width="6.4rem"
              url={ico_footer_blog}
              target="_blank"
              href="https://blog.naver.com/norit-io"
            />
            {/* <Styled.sns url={ico_footer_kakao} target="_blank" href="" /> */}
          </Styled.snsWrapper>
        </div>

      </div>
    </Styled.wrap>
  );
}

export default Footer;
