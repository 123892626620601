import React, { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { MAX_WIDTH_PC } from "../styles/preset";

import { motion } from "framer-motion";

import img_bg_03_right from "@assets/images/img_bg_03_right.png";
import img_bg_03_left from "@assets/images/img_bg_03_left.png";

const Styled = {
  wrap: styled.div<{ height: number }>`
    /* ${MAX_WIDTH_PC}; */
    padding:  16rem;
    box-sizing: border-box;
    margin: 0 auto;
    height: calc(${({ height }) => (height - 80) / 10}rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #DEF1C5;
    
  `,
  innerWrap: styled.div`
  ${MAX_WIDTH_PC};
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`,
  left: styled.div`
    flex-basis: 70rem;
  `,
  right: styled.div`
    margin-left: 8.6rem;
  `,

  title: styled.div`
    color: black;
    font-size: 4.8rem;
    line-height: 6.2rem;
    font-weight: 800;
  `,
  sub: styled.div`
    color: black;
    font-size: 2.4rem;
    margin-top: 1.8rem;
    margin-bottom: 3.8rem;
    line-height: 3.6rem;
  `,
  videoWrapper: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 2rem;
 `,
  childrenImg: styled.img`
    width: 76%;
  `,
  screenWrapper: styled.div`
    position: relative;
    width: 100%;
  `,
  phoneImg: styled.img`
    position: inherit;
    width: 50%;
    z-index: 1;
  `,
  bgImg1: styled.img`
    width: 10%;
    position: absolute;
    left: 50%;
    top: 64px;
    z-index: 0;
  `,
};
function ThirdContainer({ height }: { height: number }): ReactElement {
  return (
    <Styled.wrap height={height}>
      <Styled.innerWrap>
        <Styled.title
          data-aos="fade-down"
          data-aos-duration="700"
          data-aos-delay="0"
          data-aos-easing="ease-in-out-cubic"
        >
          우리 아이는 소셜 활동을 어떻게 시작할까요?
        </Styled.title>
        <Styled.sub
          data-aos="fade-down"
          data-aos-duration="700"
          data-aos-delay="0"
          data-aos-easing="ease-in-out-cubic"
        >
          실제 아이들이 놀잇에서 소통하는 모습입니다
        </Styled.sub>

        <Styled.videoWrapper>
          <motion.img src={img_bg_03_left} alt="bg" style={{
            height: "50%",
            position: "absolute",
          }} initial={{ opacity: 0, x: 0, y: 100 }}
            whileInView={{ opacity: 1, x: `calc(-33vw)`, y: 0 }}
            transition={{ type: "spring", stiffness: 70, duration: 300, bounce: 0.25 }} />

          <motion.img src={img_bg_03_right} alt="bg" style={{
            height: "60%",
            position: "absolute",

          }} initial={{ opacity: 0, x: 0, y: 120 }}
            whileInView={{ opacity: 1, x: `calc(33vw)`, y: 0 }} transition={{ type: "spring", stiffness: 70, duration: 300, bounce: 0.25 }} />

          <div style={{
            width: '100%',
            position: 'relative',
            zIndex: 2,
          }}>
            <video style={{
              maxWidth: "60vw",
              width: "fit-content",
              height: "100%",
            }} controls>
              <source src="https://norit-public-storage.s3.ap-northeast-2.amazonaws.com/landing/%E1%84%82%E1%85%A9%E1%86%AF%E1%84%8B%E1%85%B5%E1%86%BAWEB_%E1%84%80%E1%85%A1%E1%84%85%E1%85%A9%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A1%E1%86%BC+%E1%84%91%E1%85%A7%E1%86%AB%E1%84%8C%E1%85%B5%E1%86%B8(2).mp4" type="video/mp4" />
            </video>
          </div>

        </Styled.videoWrapper>

      </Styled.innerWrap>
    </Styled.wrap>
  );
}

export default ThirdContainer;
