import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import { userUpdate, logout as signout } from "../../shared/api/user";
import { LoginFormInputs } from "../signup/userInputTypes";
import { useUserStore } from "../../shared/zustand/user";
import { toast } from "react-toastify";

import { applyCoupon } from "../../shared/api/coupon";
import Close from "@mui/icons-material/Close";
import { N_SMALL_TABLET_MAX_SIZE } from "../../enums";

const Styled = {
  wrap: styled.div`
    /* width: 100vw; */
    width: 100%;
    padding: 2rem 0rem;
    box-sizing: border-box;
    `,
  contents: styled.div`
    width: 80vw;
    max-width: 800px;
    margin: 1rem auto;  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.4rem; 
  `,
  button: styled.button`
    width: 100%;
    max-width: 315px;
    margin: 0 auto;
    background-color: #B3DB2A;
    color: white;
    font-size: 1.8rem;
    padding: 1.6rem;
    border-radius: 4rem;
    box-sizing: border-box;
    cursor: pointer;
    `,
  rows: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;  
    align-items: center;
    `,
  label: styled.label`
    font-size: 1.8rem;
    color: #999999;
    margin-bottom: 1.6rem;
    text-align: left;
  `,
  form: styled.form`
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  `,
  inputWrap: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > * {
      margin-bottom: 3.2rem;
    }
  `,
  input: styled.input`
    width: 100%;
    border: none;
    color: black;
    font-size: 2.4rem;
    padding-bottom: 0.6rem;
    border-bottom: 2px solid #eaeaea;

    &::placeholder {
      font-size: 2.4rem;
      color: #e8e8e8;
    }

    &:focus {
      border-bottom: 2px solid #888;
    }
  `,
};


export type CouponInput = {
  coupon: string;
};

const ApplyCoupon = (props: any) => {
  const { register, handleSubmit, reset } = useForm<CouponInput>();
  const navigate = useNavigate();
  const { update, id, isLogin } = useUserStore(state => state);
  const isMobile = window.innerWidth < N_SMALL_TABLET_MAX_SIZE;

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!isLogin) {
      toast.info("로그인이 필요합니다.");
      setTimeout(() => {
        navigate('/login');
      }, 1000);
    }

  }, []);
  const onSubmit = async (data: CouponInput) => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const res = await applyCoupon(data);

      if (res.result) {
        toast.success("쿠폰 적용이 완료되었습니다. 앱에서 확인하세요!");
        navigate('/');
      }
    } catch (error) {
      window.alert("지금은 이용할 수 없습니다. 카카오톡으로 문의해주세요.");
      // toast.error("지금은 이용할 수 없습니다. 카카오톡으로 문의해주세요.");
      // navigate("/");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Styled.wrap onClick={(e) => {
      e.stopPropagation();
      // e.preventDefault();
    }}>
      <div style={{
        width: "100%",
        textAlign: "right",
        position: "relative",
        top: "-3rem",
        right: isMobile ? "-3rem" : "1rem",
        color: "#333",
      }}>
        <Close onClick={() => {
          props.closePopup(false);
        }} style={{
          width: "5rem",
          height: "5rem",
          cursor: "pointer",
        }} />
      </div>

      <h2 style={{
        marginTop: "-3rem",
      }}>쿠폰 사용하기</h2>

      <Styled.form onSubmit={handleSubmit(onSubmit)}>

        <Styled.rows>
          <Styled.inputWrap>
            <Styled.label htmlFor="coupon"> </Styled.label>
            <Styled.input
              id="coupon"
              type="text"
              placeholder="쿠폰번호를 입력해주세요"
              disabled={isLoading}
              {...register("coupon", { required: true })}
            />
          </Styled.inputWrap>

          <Styled.button type="submit" disabled={isLoading}>쿠폰 적용하기</Styled.button>
        </Styled.rows>
      </Styled.form>
    </Styled.wrap>
  );
};

export default ApplyCoupon;
