import React from "react";
import styled from "styled-components";
import ModForm from "./ModForm";
import MyVideos from "./MyVideos";
import { useNavigate, useParams } from "react-router-dom";
import { useUserStore } from "../../shared/zustand/user";
import { logout as signout } from "../../shared/api/user";
import UseCoupon from "./ApplyCoupon";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";

const Styled = {
  wrap: styled.div`
    min-height: 100vh;
    width: 100vw;
    padding: 12rem 0rem 5rem 0rem;
    box-sizing: border-box;
  `,
  button: styled.button`
    width: 100%;
    max-width: 315px;
    margin: 0 auto;
    background-color: #6AA3FF;
    color: white;
    font-size: 1.8rem;
    padding: 1.6rem;
    border-radius: 4rem;
    box-sizing: border-box;
  `,
  paper: styled.div`
    margin: 0rem auto 0rem auto;
    height: 100%;
  `,
  contents: styled.div`
    width: 80vw;
    max-width: 800px;
    margin: 0 auto;  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.4rem; 
  `,
  logout: styled.button`
   width: 100%;
    max-width: 315px;
    margin: auto;
    background-color: #FF5A5A;
    color: white;
    font-size: 1.8rem;
    padding: 1.6rem;
    border-radius: 4rem;
    box-sizing: border-box;
  `,
  coupon: styled.button`
   width: 100%;
    max-width: 315px;
    margin: auto;
    background-color: #B3DB2A;
    color: white;
    font-size: 1.8rem;
    padding: 1.6rem;
    border-radius: 4rem;
    box-sizing: border-box;
  `,
  dimmed: styled.div<{ isShow: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: ${({ isShow }: any) => (isShow ? "flex" : "none")};
  `,
  popup: styled.div`
    margin: auto;
    padding: 2rem 0rem 5rem 0rem;
    max-width: 800px;
    width: 100%;
    background-color: white;
    border-radius: 1rem;
  `,
  desc: styled.p`
    font-size: 1.2rem;  
    color: #999999;
    text-align: left;
  `,
}

interface MypageProps {
  type: string;
}

const Mypage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { type } = params;
  const { logout } = useUserStore(state => state);
  const [isShowPopup, setIsShowPopup] = React.useState(false);
  const { update, sido, phone, name, marketingEnabled, isLogin } = useUserStore(state => state);

  return (
    <Styled.wrap>
      <Styled.contents>
        <h1>마이페이지</h1>
        {type === "mod" && (
          <Styled.contents>
            <ModForm />
          </Styled.contents>
        )}
        {type === "default" && (
          <Styled.contents>
            <ModForm />
          </Styled.contents>
        )}

        {isShowPopup && (
          <Styled.dimmed isShow={isShowPopup} onClick={() => {
            setIsShowPopup(false);
          }}>
            <Styled.popup>
              <Styled.contents>
                <UseCoupon closePopup={setIsShowPopup}/>
              </Styled.contents>
              <button
                style={{
                  width: "100%",
                  maxWidth: "315px",
                  margin: "auto",
                  backgroundColor: "#bbb",
                  color: "white",
                  fontSize: "1.8rem",
                  padding: "1.6rem",
                  borderRadius: "4rem",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
                type="button"
                onClick={() => setIsShowPopup(false)}
              >닫기</button>
              <Styled.contents>

              </Styled.contents>
            </Styled.popup>
          </Styled.dimmed>
        )}


        <Styled.coupon type="button" onClick={() => {
          if(name === "" || phone === "") {
            toast.warning("회원정보를 먼저 입력해주세요! 수정 버튼을 누르셔야 수정이 완료됩니다.");
            return;
          }
          setIsShowPopup(true);
        }}>쿠폰적용하기</Styled.coupon>
        <Styled.logout type="button" onClick={async () => {
          logout();
          await signout();
          navigate('/');
        }}>로그아웃</Styled.logout>

      </Styled.contents>
    </Styled.wrap>
  );
};

export default Mypage;
