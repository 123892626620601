import React, { ReactElement } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Colors } from "../enums/Colors";
import img_05_01 from "@assets/images/img_05_01.png";
import img_05_02 from "@assets/images/img_05_02.png";
import img_05_03 from "@assets/images/img_05_03.png";
import btn_05_open from "@assets/images/btn_05_open.svg";
import btn_05_close from "@assets/images/btn_05_close.svg";

const Styled = {
  Wrap: styled.div<{ height: number }>`
    padding: 2rem 4rem;
    background-color: #000;
    height: calc(${({ height }) => (height - 54) / 10}rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 8rem;
  `,
  Title: styled.div`
    color: #fff;
    font-size: 4rem;
    line-height: 5.2rem;
    font-weight: 800;
  `,
  Contents: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 60%;
    height: 100%;
    margin: 2rem auto;
  `,
  Content: styled.div`
    background-size: contain;
    width: calc(30vw);
    padding-top: calc(30vw * 1.0435);
    position: relative;
  `,
  ContentInner: styled.div`
    position: absolute;
    top: 2rem;
    left: 2rem;
    right: 2rem;
    bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  `,
  Heading: styled.h3`
    font-size: 2.4rem;
    font-weight: bold;
  `,
};

function FifthContainer({ height }: { height: number }): ReactElement {
  const [flippedIndex, setFlippedIndex] = React.useState(-1); // 초기값으로 -1을 설정하여 플립된 요소가 없음을 나타냄

  const handleClick = (index: number) => {
    setFlippedIndex(index === flippedIndex ? -1 : index); // 클릭한 요소의 인덱스와 현재 플립된 요소의 인덱스를 비교하여 같으면 -1, 다르면 클릭한 인덱스를 설정하여 플립 효과를 토글합니다.
  };

  return (
    <Styled.Wrap height={height}>
      <Styled.Title
        data-aos="fade-down"
        data-aos-duration="700"
        data-aos-delay="0"
        data-aos-easing="ease-in-out-cubic"
      >
        놀잇은 아이가 소통하는 모든 순간을 지킵니다
      </Styled.Title>

      <div className="contents" style={{
        display: "flex", justifyContent: "center",
        alignItems: "center", gap: "2rem", width: "60%", height: "auto", margin: "2rem auto"
      }}>
        <motion.div
          whileHover={{ scale: 1.1 }}
          onClick={() => handleClick(0)}
          style={{
            backgroundImage: `url(${img_05_01})`, backgroundSize: "contain", backgroundRepeat: "no-repeat",
            width: "20vw", height: "calc(20vw*1.0045)", textAlign: "left", padding: "2rem 2rem", boxSizing: "border-box", maxWidth: "460px", maxHeight: "480px"
          }}>
          {flippedIndex === 0 ? (
            <div style={{
              width: "100%", height: "100%",
              paddingBottom: "2rem",
              display: "flex", flexDirection: "column",
              justifyContent: "space-between", alignItems: "flex-start",
            }}>
              <p style={{
                fontSize: "1.6rem",
                lineHeight: "2.4rem",
                padding: '1rem',
              }}>최초 아이 프로필 등록 시 <br />
                진행되는 튜토리얼 영상을 <br />
                통해 놀잇팀에서 엄격하게 <br />
                아이 인증을 진행합니다.</p>
              <img style={{
                width: "5.6rem",
                height: "5.6rem",
              }} src={btn_05_close} alt="" />
            </div>
          ) : (<>
            <h3 style={{
              fontSize: "2.4rem", fontWeight: "bold",
              lineHeight: "3.2rem",
              padding: '1rem',
            }}>
              실시간<br />아이 인증</h3>
            <img style={{
              width: "5.6rem",
              height: "5.6rem",
            }} src={btn_05_open} alt="" />
          </>)}

        </motion.div>
        <motion.div
          whileHover={{ scale: 1.1 }}
          onClick={() => handleClick(1)}

          style={{
            backgroundImage: `url(${img_05_02})`, backgroundSize: "contain", backgroundRepeat: "no-repeat",
            width: "20vw", height: "calc(20vw*1.0045)", textAlign: "left", padding: "2rem 2rem", boxSizing: "border-box", maxWidth: "460px", maxHeight: "480px"
          }}>
          {flippedIndex === 1 ? (
            <div style={{
              width: "100%", height: "100%",
              paddingBottom: "2rem",
              display: "flex", flexDirection: "column",
              justifyContent: "space-between", alignItems: "flex-start",
            }}>
              <p style={{
                  fontSize: "1.6rem",
                  lineHeight: "2.4rem",
                padding: '1rem',
              }}>아이들의 안전한 놀이 환경을 위해 도움선생님들이 항상 대기하고 있습니다. 아이들이 해결할 수 없는 어른의 개입이 필요한 순간 언제나 아이들을 도와줄 준비를 하고 있어요.</p>
              <img style={{
                width: "5.6rem",
                height: "5.6rem",
              }} src={btn_05_close} alt="" />
            </div>
          ) : (<>
            <h3 style={{
              fontSize: "2.4rem", fontWeight: "bold",
              lineHeight: "3.2rem",
              padding: '1rem',
            }}>도움선생님<br />상시 대기</h3>
            <img style={{
              width: "5.6rem",
              height: "5.6rem",
            }} src={btn_05_open} alt="" />
          </>)}
        </motion.div>
        <motion.div
          whileHover={{ scale: 1.1 }}
          onClick={() => handleClick(2)}
          style={{
            backgroundImage: `url(${img_05_03})`, backgroundSize: "contain", backgroundRepeat: "no-repeat",
            width: "20vw", height: "calc(20vw*1.0045)", textAlign: "left", padding: "2rem 2rem", boxSizing: "border-box", maxWidth: "460px", maxHeight: "480px"
          }}>
          {flippedIndex === 2 ? (
            <div style={{
              width: "100%", height: "100%",
              paddingBottom: "2rem",
              display: "flex", flexDirection: "column",
              justifyContent: "space-between", alignItems: "flex-start",
            }}>
              <p style={{
                 fontSize: "1.6rem",
                 lineHeight: "2.4rem",
                padding: '1rem',
              }}>놀잇은 아동・청소년 개인정보보호 가이드라인을 준수하며, 아이들이 자신의 개인정보를 잘 지킬 수 있도록 다양한 교육활동을 진행하고 있습니다.</p>
              <img style={{
                width: "5.6rem",
                height: "5.6rem",
              }} src={btn_05_close} alt="" />
            </div>
          ) : (
            <> <h3 style={{
              fontSize: "2.4rem", fontWeight: "bold",
              lineHeight: "3.2rem",
              padding: '1rem',
            }}>아이 정보<br />보호</h3>
              <img style={{
                width: "5.6rem",
                height: "5.6rem",
              }} src={btn_05_open} alt="" /></>
          )}

        </motion.div>
      </div >
    </Styled.Wrap >
  );
}

export default FifthContainer;