import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import img_04_01 from "@assets/image/img_04_01@2x.png";
import logo01 from "@assets/images/logo01.png";
import logo02 from "@assets/images/logo02.png";
import logo03 from "@assets/images/logo03.svg";
import logo04 from "@assets/images/logo04.svg";
import logo05 from "@assets/images/logo05.svg";
import logo06 from "@assets/images/logo06.png";
import logo07 from "@assets/images/logo07.png";
import logo08 from "@assets/images/logo08.png";
import logo09 from "@assets/images/logo09.svg";

import { MAX_WIDTH_PC } from "../styles/preset";

const Styled = {
  wrap: styled.div``,
  titleWrapper: styled.div`
    position: relative;
  `,
  content: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    /* background-color: white; */
    margin-top: 19rem;
  `,
  title: styled.div`
    color: black;
    font-size: 4rem;
    line-height: 5.2rem;
    font-weight: 800;
  `,
  sub: styled.div`
    color: black;
    font-size: 2rem;
    margin-top: 3rem;
    margin-bottom: 38px;
    line-height: 3rem;
  `,
  Wrapper: styled.div`
    width: 45%;
    margin: 10rem auto;
    padding-bottom : 9.8rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem 1rem;
    align-items: center;
    justify-items: center;
  `,
  logos: styled.img`
    width: auto;
    height: auto;
    max-width: 12rem;
    max-height: 8rem;
  `,
  sliderWrapper: styled.div`
    ${MAX_WIDTH_PC};
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;
    padding-bottom: 9.8rem;
  `,
  item: styled.img`
    width: 2.4rem;
  `,
  guide: styled.div<{ position: "right" | "left" }>`
    position: absolute;
    ${({ position }) => position}: 0;
    top: 0;
    width: 463px;
    height: 100%;
    z-index: 1;
    background: linear-gradient(
      to ${({ position }) => position},
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  `,
  img01: styled.div`
    position: absolute;
    background: url(${img_04_01}) no-repeat center center/contain;
    width: 24.2rem;
    height: 16.4rem;
    top: -12rem;
    right: -10rem;
    z-index: -1;
  `,
};
const sliderSettings = {
  infinite: true,
  slidesToShow: 7,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: "linear",
  arrows: false,
  dots: false,
};
function PartnersContainer() {
  return (
    <Styled.wrap>
      <Styled.content>
        <Styled.titleWrapper>
          <Styled.title
            data-aos="fade-in"
            data-aos-duration="700"
            data-aos-delay="0"
            data-aos-easing="ease-in-out-cubic"
          >
            놀잇을 응원하는<br />
            든든한 파트너
          </Styled.title>
          <Styled.img01
            data-aos="zoom-in-down"
            data-aos-duration="700"
            data-aos-delay="300"
            data-aos-easing="ease-in-out-cubic"
          />
        </Styled.titleWrapper>
      </Styled.content>
      <Styled.Wrapper>
        <Styled.logos src={logo01} />
        <Styled.logos src={logo02} />
        <Styled.logos src={logo03} />
        <Styled.logos src={logo04} />
        <Styled.logos src={logo05} />
        <Styled.logos src={logo06} />
        <Styled.logos src={logo07} />
        <Styled.logos src={logo08} />
        <Styled.logos src={logo09} />
      </Styled.Wrapper>

      {/* <Styled.sliderWrapper>
        <Styled.guide position={"left"} />
        <Slider {...sliderSettings}>
          <Styled.item src={img_partners_01} />
          <Styled.item src={img_partners_02} />
          <Styled.item src={img_partners_03} />
          <Styled.item src={img_partners_04} />
          <Styled.item src={img_partners_05} />
          <Styled.item src={img_partners_06} />
          <Styled.item src={img_partners_07} />
          <Styled.item src={img_partners_01} />
          <Styled.item src={img_partners_02} />
          <Styled.item src={img_partners_03} />
          <Styled.item src={img_partners_04} />
          <Styled.item src={img_partners_05} />
          <Styled.item src={img_partners_06} />
          <Styled.item src={img_partners_07} />
        </Slider>
        <Styled.guide position={"right"} />
      </Styled.sliderWrapper> */}
    </Styled.wrap>
  );
}

export default PartnersContainer;
