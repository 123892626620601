export const typeSelect = (answer_count: any) => {
  const type_array = Object.keys(answer_count);

  const final_type = type_array.reduce((acc, cur, idx) => {
    if (idx % 2 === 0) {
      return acc;
    } else {
      return answer_count[cur] > answer_count[type_array[idx - 1]]
        ? acc + cur
        : acc + type_array[idx - 1];
    }
  }, "");

  return final_type;
};
