import React, { useState } from "react";
import styled from "styled-components";
import img_04_02 from "@assets/image/img_04_02@2x.png";

const Styled = {
  wrap: styled.div`
    background-color: #fafafa;
    position: relative;
  `,
  content: styled.div`
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    padding: 5rem 3.8rem 14.2rem 3.8rem;
    background-color: #fafafa;
  `,
  title: styled.div`
    color: black;
    font-size: 2.6rem;
    line-height: 3.8rem;
    font-weight: 800;
    text-align: center;
    position: relative;
  `,
  formWrapper: styled.div`
    box-sizing: border-box;
    width: 100%;
    margin-top: 3rem;
    background: #ffffff;
    box-shadow: 0 0 6px #0000001a;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 3.4rem 2rem;
  `,
  label: styled.div`
    font-size: 1.2rem;
    color: #999999;
    margin-bottom: 1.6rem;
  `,
  form: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  `,
  input: styled.input`
    border: none;
    color: black;
    font-size: 1.4rem;
    padding-bottom: 0.6rem;
    border-bottom: 2px solid #eaeaea;

    &::placeholder {
      font-size: 1.4rem;
      color: #e8e8e8;
    }
  `,
  textarea: styled.textarea`
    border: none;
    color: black;
    font-size: 1.4rem;
    resize: none;

    &::placeholder {
      font-size: 1.4rem;
      color: #e8e8e8;
    }
  `,
  button: styled.a`
    font-size: 1.8rem;
    position: absolute;
    right: 0;
    background-color: #6e87ff;
    padding: 1.7rem 0;
    color: white;
    text-align: center;
    bottom: 0;
    width: 100%;
    border-radius: 0 0 20px 20px;
    cursor: pointer;
  `,
  img_04_02: styled.div`
    position: absolute;
    width: 100%;
    min-width: 0.9rem;
    min-height: 11rem;
    right: 0.3rem;
    bottom: 3rem;
    background: url(${img_04_02}) no-repeat center center/contain;
  `,
};

function MContactContainer() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [body, setBody] = useState("");
  return (
    <Styled.wrap>
      <Styled.content>
        <Styled.title
          data-aos="fade-up"
          data-aos-duration="700"
          data-aos-delay="0"
          data-aos-easing="ease-in-out-cubic"
        >
          제휴문의
        </Styled.title>
        <Styled.formWrapper
          data-aos="fade-up"
          data-aos-duration="700"
          data-aos-delay="700"
          data-aos-easing="ease-in-out-cubic"
        >
          <Styled.form>
            <Styled.label>이름 (Name)</Styled.label>
            <Styled.input
              onChange={(e) => setName(e.target.value)}
              placeholder="이름을 입력해주세요"
            />
          </Styled.form>
          <Styled.form>
            <Styled.label>이메일 주소 (E-mail)</Styled.label>
            <Styled.input
              onChange={(e) => setEmail(e.target.value)}
              placeholder="이메일을 입력해주세요"
            />
          </Styled.form>
          <Styled.label>내용 (Message)</Styled.label>
          <Styled.textarea
            onChange={(e) => setBody(e.target.value)}
            rows={12}
            placeholder="내용을 입력해주세요"
          />
          <Styled.button
            href={`mailto:help@norit.io?subject=놀잇 제휴문의: 작성자 ${name}&body=${body}`}
          >
            슈웅 보내기
          </Styled.button>
        </Styled.formWrapper>
        <Styled.img_04_02
          data-aos="fade-right"
          data-aos-duration="700"
          data-aos-delay="0"
          data-aos-easing="ease-in-out-cubic"
        />
      </Styled.content>

    </Styled.wrap>
  );
}

export default MContactContainer;
