import React, { useMemo } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

//images
import bg_image from "@assets/images/img_bg_01.svg";
import img_googleplay from "@assets/images/img_googleplay.svg";
import img_appstore from "@assets/images/img_appstore.png";
import child_gif from "@assets/images/child.gif";
import img_header_norit_logo from "@assets/images/img_home_00.png";
import iPhone12_Clay_Shadow from "@assets/images/iPhone12_Clay_Shadow.png";
import { Colors } from "../../enums/Colors";

const Styled = {
  hero: styled.div`
    /* height: calc(${window.innerHeight / 10}rem); */
    min-height: 120vh;
    /* height: calc(${window.innerHeight/10}rem); */
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: 800;
    line-height: 3.8rem;
    flex-direction: column;
    color: white;
    z-index: 0;
    width: 100%;
    padding-top: 13vh;
    
    overflow: hidden;
    box-sizing: border-box;
    background: url(${bg_image}) no-repeat center center/cover;
  `,
  wrap: styled.div<{ height: number }>`
  height: calc(${({ height }) => height / 10}rem);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  z-index: 2;
  /* overflow-y: hidden; */
  overflow: hidden;
  box-sizing: border-box;
  background: url(${bg_image}) no-repeat center center/cover;

`,
  stores: styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 1rem;
    margin: 1rem 0rem 2rem 0rem;
  `,
};

function MFirstContainer() {

  return (
    <>
      <Styled.hero>
        <div
          data-aos="zoom-in-down"
          data-aos-duration="700"
          data-aos-delay="0"
          data-aos-easing="ease-in-out-cubic"
          style={{
            textAlign: "center",
            width: "100%",
            height: "100%",
            padding: "10vh 0rem 5vh 0rem",
          }}
        >
          <div style={{
            textAlign: "center",
            fontWeight: "800",
          }}>
            6세부터 10세까지<br />
            알파세대를 위한 SNS
          </div>

          <div style={{
            fontSize: "2rem",
          }}>
            우리 아이들의 첫 소셜네트워크
          </div>

          <Styled.stores
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="700"
            data-aos-easing="ease-in-out-cubic">
            <button style={{
              backgroundColor: Colors.orange,
              color: Colors.white,
              borderRadius: '1rem',
              padding: '1rem',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              fontSize: '1.3rem',
              fontWeight: 'bold',
              cursor: 'pointer'
            }} onClick={() => {
              window.open("https://apps.apple.com/us/app/%EB%86%80%EC%9E%87-%EC%95%8C%ED%8C%8C%EC%84%B8%EB%8C%80-%EC%A0%84%EC%9A%A9-sns/id6470339582", '_blank');
            }}>
              <img src={img_appstore} style={{width: "23px"}} />
              App Store
            </button>
            <button style={{
              backgroundColor: Colors.orange,
              color: Colors.white,
              borderRadius: '1rem',
              padding: '1rem',
              display: 'flex',
              alignItems: 'center',
              // gap: '0.5rem',
              fontSize: '1.3rem',
              fontWeight: 'bold',
              cursor: 'pointer'
            }} onClick={() => {
              window.open('https://play.google.com/store/apps/details?id=io.noriiit.app&utm_source=noritio&utm_medium=organic&utm_campaign=UA', '_blank');
            }}>
              <img src={img_googleplay} style={{width: "23px"}} />
              Google Play
            </button>
          </Styled.stores>
          <img style={{
            width: "25vw",
            marginBottom: "1vh",
            position: "absolute",
            right: "10%",
            top: "0%",
            zIndex: -1,
          }} src={img_header_norit_logo} />
          <motion.div
            style={{
              height: `${(2250 * window.innerWidth)/1442}px`,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '5vh',
            }}
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1 }}
          >
            <div style={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              boxSizing: 'border-box',
              position: 'relative',
            }}>
              <div style={{
                borderRadius: '4.5rem',
                boxSizing: 'border-box',
                position: 'absolute',
                width: `59%`,
                top: '10.2%',
                backgroundColor: 'black',
                left: '20.2%',
                overflow: 'hidden',
                height: '80%',
                display: 'flex',
              }} >
                <img style={{
                  width: '100%',
                  margin: 'auto',
                }}
                  src={child_gif} />
              </div>

              <img style={{
                boxSizing: 'border-box',
                position: 'absolute',
                left: '0%',
                top: '0%',
                width: `100%`,
              }} src={iPhone12_Clay_Shadow} />
            </div>
          </motion.div>

        </div>
      </Styled.hero>
    </>
  );
}
export default MFirstContainer;
