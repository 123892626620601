import React, { useState } from "react";
import styled from "styled-components";
import { MAX_WIDTH_PC } from "../styles/preset";
import img_04_02 from "@assets/image/img_04_02@2x.png";

const Styled = {
  wrap: styled.div`
    background-color: #fafafa;
  `,
  content: styled.div`
    ${MAX_WIDTH_PC};
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    padding: 8rem 0 14rem 0;
    position: relative;
    z-index: 1;
  `,
  title: styled.div`
    color: black;
    font-size: 4rem;
    line-height: 5.2rem;
    font-weight: 800;
    margin-bottom: 6rem;
  `,
  formWrapper: styled.div`
    background: #ffffff;
    box-shadow: 0 0 6px #0000001a;
    border-radius: 20px;
    display: flex;
    min-width: 84.2rem;
  `,
  label: styled.div`
    font-size: 1.8rem;
    color: #999999;
    margin-bottom: 1.6rem;
  `,
  left: styled.div`
    flex: 1;
    margin: 8rem 0 0 5rem;
    border-right: 2px solid #eaeaea;
    padding-right: 5rem;
    padding-bottom: 16rem;
  `,
  right: styled.div`
    flex: 1;
    padding: 80px 50px 20px 50px;
    position: relative;
  `,
  form: styled.div`
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-bottom: 5.2rem;
    }
  `,
  input: styled.input`
    border: none;
    color: black;
    font-size: 2.4rem;
    padding-bottom: 0.6rem;
    border-bottom: 2px solid #eaeaea;

    &::placeholder {
      font-size: 2.4rem;
      color: #e8e8e8;
    }
  `,
  textarea: styled.textarea`
    border: none;
    color: black;
    font-size: 2.4rem;
    resize: none;

    &::placeholder {
      font-size: 2.4rem;
      color: #e8e8e8;
    }
  `,
  button: styled.a`
    font-size: 2.4rem;
    position: absolute;
    right: 0;
    background-color: #6e87ff;
    padding: 2.2rem 0;
    color: white;
    text-align: center;
    bottom: 0;
    width: 100%;
    border-radius: 0 0 20px 0;
    cursor: pointer;
  `,

  img_04_02: styled.div`
    position: relative;
    width: 100%;
    min-width: 10rem;
    min-height: 20rem;
    right: 15rem;
    top: -25rem;
    background: url(${img_04_02}) no-repeat right center/contain;
  `,
};

function ContactContainer() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [body, setBody] = useState("");
  return (
    <Styled.wrap>
      <Styled.content>
        <Styled.title
          data-aos="fade-up"
          data-aos-duration="700"
          data-aos-delay="0"
          data-aos-easing="ease-in-out-cubic"
        >
          제휴문의
        </Styled.title>
        <Styled.formWrapper
          data-aos="fade-up"
          data-aos-duration="700"
          data-aos-delay="700"
          data-aos-easing="ease-in-out-cubic"
        >
          <Styled.left>
            <Styled.form>
              <Styled.label>이름 (Name)</Styled.label>
              <Styled.input
                onChange={(e) => setName(e.target.value)}
                placeholder="이름을 입력해주세요"
              />
            </Styled.form>
            <Styled.form>
              <Styled.label>이메일 주소 (E-mail)</Styled.label>
              <Styled.input
                onChange={(e) => setEmail(e.target.value)}
                placeholder="이메일을 입력해주세요"
              />
            </Styled.form>
          </Styled.left>
          <Styled.right>
            <Styled.label>내용 (Message)</Styled.label>
            <Styled.textarea
              onChange={(e) => setBody(e.target.value)}
              rows={8}
              placeholder="내용을 입력해주세요"
            />
            <Styled.button
              href={`mailto:help@norit.io?subject=놀잇 제휴문의: 작성자 ${name}&body=${body}`}
            >
              슈웅 보내기
            </Styled.button>
          </Styled.right>
        </Styled.formWrapper>
      </Styled.content>
      <Styled.img_04_02
        data-aos="fade-up-right"
        data-aos-duration="700"
        data-aos-delay="0"
        data-aos-easing="ease-in-out-cubic"
      />
    </Styled.wrap>
  );
}

export default ContactContainer;
